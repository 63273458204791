/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'contactus';

const initialState = entityAdapter.getInitialState({
    contactUsLoadingStatus: LoadingStatus.NOT_LOADED,
    updateContactUsLoadingStatus: LoadingStatus.NOT_LOADED,
    contactUsList: [],
    setPaymentStatus: null,
    contactUsError: null,
    updateContactUsModeError: null
});

/**
 * All Contact Us Action
 */

export const allContactUsAction = createAsyncThunk(`${SLICE_NAME}/allContactUsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/contact-us',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Update Contact Us Action
 */

export const updateContactUsAction = createAsyncThunk(`${SLICE_NAME}/updateContactUsAction`, async (params, thunkAPI) => {
    let reqbody = {
        id: params.id,
        email: params.email,
        contact_number: params.contact
    };
    try {
        const result = await axiosRequest({
            url: '/update-contact-us',
            method: 'POST',
            data: reqbody
        });
        console.log(result);
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const contactUsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetPostersState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All ContactUs action
            .addCase(allContactUsAction.pending, (state, action) => {
                state.contactUsError = null;
                state.contactUsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allContactUsAction.fulfilled, (state, action) => {
                state.contactUsLoadingStatus = LoadingStatus.LOADED;
                state.contactUsList = action.payload?.data ?? [];
                state.setPaymentStatus = action.payload?.data[0]?.status ?? [];
            })
            .addCase(allContactUsAction.rejected, (state, action) => {
                state.contactUsLoadingStatus = LoadingStatus.FAILED;
                state.contactUsError = action.payload || action.error.message;
            })

            // All updatePaymentMode action
            .addCase(updateContactUsAction.pending, (state, action) => {
                state.updateContactUsModeError = null;
                state.updateContactUsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updateContactUsAction.fulfilled, (state, action) => {
                state.updateContactUsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Contact Us Details updated Successfully.');
            })
            .addCase(updateContactUsAction.rejected, (state, action) => {
                state.updateContactUsLoadingStatus = LoadingStatus.FAILED;
                state.updateContactUsModeError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const contactUsReducer = contactUsSlice.reducer;

export const contactUsActions = contactUsSlice.actions;
