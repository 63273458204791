export const capitalizeFirstLetter = (string) => {
    return string[0]?.toUpperCase() + string?.slice(1);
};

export const generateRandom = () => {
    var length = 8,
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
        retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
};

export const getBadge = (status) => {
    switch (status) {
        case 'active':
            return 'success';
        case 'inactive':
            return 'secondary';
        case 'no':
            return 'success';
        case 'yes':
            return 'secondary';
        case 'pending':
            return 'warning';
        case 'deleted':
            return 'danger';
        default:
            return 'primary';
    }
};

export const randColor = () => {
    return (
        '#' +
        Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, '0')
            .toUpperCase()
    );
};
