// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}
.editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
    height: 600px;
}
.toolbar-class {
    border: 1px solid #ccc;
    /* position: sticky; */
}

.addRows {
    background: transparent;
    color: #ffa100;
    font-size: 28px;
    line-height: 1;
    border: 2px solid #ffa100;
    padding-block: 5px;
}

.addRows:hover {
    background: #ffa100;
    color: #fff;
}

.addRows[disabled] {
    color: #ffa100;
    opacity: 0.75;
    cursor: not-allowed;
}

.sqp_form label {
    background: #ffa100;
    padding: 10px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.sqp_form .css-1a7pcy6-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0 !important;

}

.sqp_form fieldset {
    border: 0 ;
    border-bottom: 1px solid #cccccc3d;
}

.sqp_form fieldset:focus {
    all: unset;
}

.list li {
    padding: 0;
    border-bottom: 1px solid #cccccc3d;
}

.list .value {
    padding: 14px;
    color: #aaaaaa;
    width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/pages/e_commerce_management/product_management/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;;AAE/B;;AAEA;IACI,UAAU;IACV,kCAAkC;AACtC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,cAAc;IACd,UAAU;AACd","sourcesContent":[".wrapper-class {\n    padding: 1rem;\n    border: 1px solid #ccc;\n}\n.editor-class {\n    background-color: lightgray;\n    padding: 1rem;\n    border: 1px solid #ccc;\n    height: 600px;\n}\n.toolbar-class {\n    border: 1px solid #ccc;\n    /* position: sticky; */\n}\n\n.addRows {\n    background: transparent;\n    color: #ffa100;\n    font-size: 28px;\n    line-height: 1;\n    border: 2px solid #ffa100;\n    padding-block: 5px;\n}\n\n.addRows:hover {\n    background: #ffa100;\n    color: #fff;\n}\n\n.addRows[disabled] {\n    color: #ffa100;\n    opacity: 0.75;\n    cursor: not-allowed;\n}\n\n.sqp_form label {\n    background: #ffa100;\n    padding: 10px;\n    margin-bottom: 5px;\n    color: #fff;\n    font-weight: bold;\n    text-transform: uppercase;\n    text-align: center;\n}\n\n.sqp_form .css-1a7pcy6-MuiInputBase-root-MuiOutlinedInput-root {\n    border-radius: 0 !important;\n\n}\n\n.sqp_form fieldset {\n    border: 0 ;\n    border-bottom: 1px solid #cccccc3d;\n}\n\n.sqp_form fieldset:focus {\n    all: unset;\n}\n\n.list li {\n    padding: 0;\n    border-bottom: 1px solid #cccccc3d;\n}\n\n.list .value {\n    padding: 14px;\n    color: #aaaaaa;\n    width: 30%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
