/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import ImageEdit from 'pages/e_commerce_management/product_management/ImageEdit';
import ImageList from 'pages/e_commerce_management/product_management/ImageList';
import ImageView from 'pages/e_commerce_management/product_management/ImageView';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout/index';
import MinimalLayout from 'layout/MinimalLayout/index';
import MultipleImages from 'pages/e_commerce_management/product_management/multiple_images/MultipleImages';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));

// User Management
const UserManagement = Loadable(lazy(() => import('pages/user_management/users')));
const UserAddForm = Loadable(lazy(() => import('pages/user_management/users/UserAddForm')));
const UserEditForm = Loadable(lazy(() => import('pages/user_management/users/UserEditForm')));
const DeletedUsers = Loadable(lazy(() => import('pages/user_management/DeletedUsers')));
const Certificate = Loadable(lazy(() => import('pages/user_management/Certificate')));
const SubscribedUsers = Loadable(lazy(() => import('pages/user_management/SubscribedUsers')));
const LiveClassSubscriptionUser = Loadable(lazy(() => import('pages/user_management/LiveClassSubscriptionUser')));

// Promotional Posters
const PromotioanlPosters = Loadable(lazy(() => import('pages/promotional_posters')));
const AddPromotionalPosters = Loadable(lazy(() => import('pages/promotional_posters/AddPromotionalPosters')));
const EditPromotionalPosters = Loadable(lazy(() => import('pages/promotional_posters/EditPromotionalPosters')));

// Event Gallery
const EventGallery = Loadable(lazy(() => import('pages/event_gallery')));
const AddEventGallery = Loadable(lazy(() => import('pages/event_gallery/AddEventGallery')));
const EditEventGallery = Loadable(lazy(() => import('pages/event_gallery/EditEventGallery')));

// Reason
const Reason = Loadable(lazy(() => import('pages/reason')));
const AddReason = Loadable(lazy(() => import('pages/reason/AddReason')));
const EditReason = Loadable(lazy(() => import('pages/reason/EditReason')));

//Email Management
const EmailManagement = Loadable(lazy(() => import('pages/email_management')));

//Subscription Management
const Subscription = Loadable(lazy(() => import('pages/SubscriptionManagement/Subscription')));
const AddSubscription = Loadable(lazy(() => import('pages/SubscriptionManagement/Subscription/AddSubscription')));
const EditSubscription = Loadable(lazy(() => import('pages/SubscriptionManagement/Subscription/EditSubscription')));

const LiveClassSubscription = Loadable(lazy(() => import('pages/SubscriptionManagement/LiveClassSubscription')));
const AddLiveClassSubscription = Loadable(
    lazy(() => import('pages/SubscriptionManagement/LiveClassSubscription/AddLiveClassSubscription'))
);
const EditLiveClassSubscription = Loadable(
    lazy(() => import('pages/SubscriptionManagement/LiveClassSubscription/EditLiveClassSubscription'))
);

// Static Management
const StaticView = Loadable(lazy(() => import('pages/static_pages/StaticView')));

// Meet team
const MeetTeam = Loadable(lazy(() => import('pages/general_settings/meet_team')));
const AddMeetTeam = Loadable(lazy(() => import('pages/general_settings/meet_team/AddMeetTeam')));
const EditMeetTeam = Loadable(lazy(() => import('pages/general_settings/meet_team/EditMeetTeam')));

// Payment Mode
const PaymentMode = Loadable(lazy(() => import('pages/general_settings/payment_mode')));

// Payment Mode
const ContactUs = Loadable(lazy(() => import('pages/general_settings/contact_us')));
const EditContactUs = Loadable(lazy(() => import('pages/general_settings/contact_us/editContactUs')));

// Success Stories
const SuccessStories = Loadable(lazy(() => import('pages/general_settings/success_stories')));
const AddSuccessStories = Loadable(lazy(() => import('pages/general_settings/success_stories/AddSuccessStories')));
const EditSuccessStories = Loadable(lazy(() => import('pages/general_settings/success_stories/EditSuccessStories')));

// Walkthrough
const WalkthroughScreen = Loadable(lazy(() => import('pages/general_settings/walkthrough')));
const AddWalkThrough = Loadable(lazy(() => import('pages/general_settings/walkthrough/AddWalkThrough')));
const EditWalkthrough = Loadable(lazy(() => import('pages/general_settings/walkthrough/EditWalkthrough')));

// Notifications
const NotificationManagement = Loadable(lazy(() => import('pages/notification_management')));
const AddNotifications = Loadable(lazy(() => import('pages/notification_management/AddNotifications')));
const EditNotifications = Loadable(lazy(() => import('pages/notification_management/EditNotifications')));

// Success Stories
const ReviewRating = Loadable(lazy(() => import('pages/review_rating')));
const EditReviewRating = Loadable(lazy(() => import('pages/review_rating/EditReviewRating')));

// Premium Review Reting
const PreReviewRating = Loadable(lazy(() => import('pages/premium_feachure/premium_review_rating')));
const PreEditReviewRating = Loadable(lazy(() => import('pages/premium_feachure/premium_review_rating/EditReviewRating')));
const PreViewReviewRating = Loadable(lazy(() => import('pages/premium_feachure/premium_review_rating/ViewReviewRating')));

// Premium Review Reting
const LiveReviewRating = Loadable(lazy(() => import('pages/premium_feachure/premium_review_rating/live_review_rating')));
const LiveEditReviewRating = Loadable(
    lazy(() => import('pages/premium_feachure/premium_review_rating/live_review_rating/EditReviewReting'))
);
const LiveViewReviewRating = Loadable(
    lazy(() => import('pages/premium_feachure/premium_review_rating/live_review_rating/ViewReviewRating'))
);

// Course Management Lavel

const Lavel = Loadable(lazy(() => import('pages/course_management/level/index')));
const AddLavel = Loadable(lazy(() => import('pages/course_management/level/AddLavel')));
const EditLavel = Loadable(lazy(() => import('pages/course_management/level/EditLavel')));

// Course Management
const CourseManagement = Loadable(lazy(() => import('pages/course_management')));
const AddCourseManagement = Loadable(lazy(() => import('pages/course_management/AddCourseManagement')));
const EditCourseManagement = Loadable(lazy(() => import('pages/course_management/EditCourseManagement')));
const CourseDetail = Loadable(lazy(() => import('pages/course_management/course_detail')));
const AddCourseDetail = Loadable(lazy(() => import('pages/course_management/course_detail/AddCourseDetail')));
const EditCourseDetail = Loadable(lazy(() => import('pages/course_management/course_detail/EditCourseDetail')));

// E-commerce management
//Product Management
const ProductManagement = Loadable(lazy(() => import('pages/e_commerce_management/product_management')));
const AddProduct = Loadable(lazy(() => import('pages/e_commerce_management/product_management/AddProduct')));
const EditProduct = Loadable(lazy(() => import('pages/e_commerce_management/product_management/EditProduct')));
const ImageUpload = Loadable(lazy(() => import('pages/e_commerce_management/product_management/ImageUpload')));

//Size Chart
const SizeChart = Loadable(lazy(() => import('pages/e_commerce_management/size_chart')));
const AddSize = Loadable(lazy(() => import('pages/e_commerce_management/size_chart/AddSize')));
const EditSize = Loadable(lazy(() => import('pages/e_commerce_management/size_chart/EditSize')));

//Product Deliver Section
const ProductDeliver = Loadable(lazy(() => import('pages/e_commerce_management/product_deliver')));
const AddProductDeliver = Loadable(lazy(() => import('pages/e_commerce_management/product_deliver/AddProductDeliver')));
const EditProductDeliver = Loadable(lazy(() => import('pages/e_commerce_management/product_deliver/EditProductDeliver')));

//Cutomer Experinces Management
const CustomerExperinces = Loadable(lazy(() => import('pages/customer_experinces')));
const AddCustomerExperinces = Loadable(lazy(() => import('pages/customer_experinces/AddCustomerExperinces')));
const EditCustomerExperinces = Loadable(lazy(() => import('pages/customer_experinces/EditCustomerExperinces')));

//Delivery Charges Management
const DeliveyCharges = Loadable(lazy(() => import('pages/e_commerce_management/delivery_charge')));
const EditDeliveyCharges = Loadable(lazy(() => import('pages/e_commerce_management/delivery_charge/EditDeliveryCharge')));

// Order History
const OrderHistory = Loadable(lazy(() => import('pages/e_commerce_management/order_history')));
const ViewOrderHistory = Loadable(lazy(() => import('pages/e_commerce_management/order_history/ViewHistory')));

const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// Premium Feachure --------------------
// Popular Courses ---------------------
const PopularCourses = Loadable(lazy(() => import('pages/premium_feachure/popular_courses')));
const AddPopularCourses = Loadable(lazy(() => import('pages/premium_feachure/popular_courses/AddPopularCourses')));
const EditPopularCourses = Loadable(lazy(() => import('pages/premium_feachure/popular_courses/EditPopularCourses')));

//Course Upload Video ---------------------
const CourseUploadVideo = Loadable(lazy(() => import('pages/premium_feachure/popular_courses/upload_video')));
const AddCourseUploadVideo = Loadable(lazy(() => import('pages/premium_feachure/popular_courses/upload_video/AddUploadVideo')));
const EditCourseUploadVideo = Loadable(lazy(() => import('pages/premium_feachure/popular_courses/upload_video/EditUploadVideo')));

// Live Courses ---------------------
const LiveCourses = Loadable(lazy(() => import('pages/premium_feachure/live_courses')));
const AddLiveCourses = Loadable(lazy(() => import('pages/premium_feachure/live_courses/AddLiveCourses')));
const EditLiveCourses = Loadable(lazy(() => import('pages/premium_feachure/live_courses/EditLiveCourses')));
//Live Course Content ---------------------
const LiveCourseContent = Loadable(lazy(() => import('pages/premium_feachure/live_courses/course_content')));
const AddLiveCourseContent = Loadable(lazy(() => import('pages/premium_feachure/live_courses/course_content/AddCourseContent')));
const EditLiveCourseContent = Loadable(lazy(() => import('pages/premium_feachure/live_courses/course_content/EditCourseContent')));

// Recorded Courses ---------------------
const RecordedCourses = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses')));
const AddRecordedCourses = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/AddRecordedCourse')));
const EditRecordedCourses = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/EditRecordedCourse')));
const UploadPdf = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/UploadPdf')));

// Recorded Courses ---------------------
const YogaEBooks = Loadable(lazy(() => import('pages/premium_feachure/e-books/index')));
const AddYogaEBook = Loadable(lazy(() => import('pages/premium_feachure/e-books/AddE-Book')));
const EditYogaEBook = Loadable(lazy(() => import('pages/premium_feachure/e-books/EditE-Book')));

// Record Course Content ---------------------
const CourseContent = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/course_content')));
const AddCourseContent = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/course_content/AddCourseContent')));
const EditCourseContent = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/course_content/EditCourseContent')));

// Record Static Course Content ---------------------
const RecordStaticContent = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/what_learn/index')));
const AddRecordStaticContent = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/what_learn/AddContent')));
const EditRecordStaticContent = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/what_learn/EditContent')));

// Record Static Course Content ---------------------
const RecordCourseComprise = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/course_comprises/index')));
const AddRecordCourseComprise = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/course_comprises/AddCourseComprise')));
const EditRecordCourseComprise = Loadable(
    lazy(() => import('pages/premium_feachure/recorded_courses/course_comprises/EditCourseComprise'))
);

// Live  Course comprises ---------------------
const LiveCourseComprise = Loadable(lazy(() => import('pages/premium_feachure/live_courses/course_comprise/index')));
const AddLiveCourseComprise = Loadable(lazy(() => import('pages/premium_feachure/live_courses/course_comprise/AddCourseComprise')));
const EditLiveCourseComprise = Loadable(lazy(() => import('pages/premium_feachure/live_courses/course_comprise/EditCourseComprise')));

// Live  Course Eligibility ---------------------
const LiveEligibility = Loadable(lazy(() => import('pages/premium_feachure/live_courses/eligibility_requirement/index')));
const AddLiveEligibility = Loadable(lazy(() => import('pages/premium_feachure/live_courses/eligibility_requirement/AddLiveEligibility')));
const EditLiveEligibility = Loadable(lazy(() => import('pages/premium_feachure/live_courses/eligibility_requirement/EditLiveEligibility')));

// Record  Course Eligibility ---------------------
const RecordEligibility = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/eligibility_requirement/index')));
const AddRecordEligibility = Loadable(
    lazy(() => import('pages/premium_feachure/recorded_courses/eligibility_requirement/AddRecordEligibility'))
);
const EditRecordEligibility = Loadable(
    lazy(() => import('pages/premium_feachure/recorded_courses/eligibility_requirement/EditRecordEligibility'))
);

// Record Static Course Content ---------------------
const LiveStaticContent = Loadable(lazy(() => import('pages/premium_feachure/live_courses/what_learn/index')));
const AddLiveStaticContent = Loadable(lazy(() => import('pages/premium_feachure/live_courses/what_learn/AddStaticContent')));
const EditLiveStaticContent = Loadable(lazy(() => import('pages/premium_feachure/live_courses/what_learn/EditStaticContent')));

// Upload Video ---------------------
const UploadVideo = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/upload_video')));
const AddUploadVideo = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/upload_video/AddUploadVideo')));
const EditUploadVideo = Loadable(lazy(() => import('pages/premium_feachure/recorded_courses/upload_video/EditUploadVideo')));

// Static Content ---------------------
const StaticContent = Loadable(lazy(() => import('pages/premium_feachure/static_content')));
const AddStaticContent = Loadable(lazy(() => import('pages/premium_feachure/static_content/AddStaticContent')));
const EditStaticContent = Loadable(lazy(() => import('pages/premium_feachure/static_content/EditStaticContent')));

// Static Content ---------------------
const CourseComprise = Loadable(lazy(() => import('pages/premium_feachure/course_comprise')));
const AddCourseComprise = Loadable(lazy(() => import('pages/premium_feachure/course_comprise/AddCourseComprise')));
const EditCourseComprise = Loadable(lazy(() => import('pages/premium_feachure/course_comprise/EditCourseComprise')));

// Splase Screen ---------------------
const SplashScreen = Loadable(lazy(() => import('pages/premium_feachure/splace_screen')));
const AddSplashScreen = Loadable(lazy(() => import('pages/premium_feachure/splace_screen/AddSplashScreen')));
const EditSplashScreen = Loadable(lazy(() => import('pages/premium_feachure/splace_screen/EditSplashScreen')));

// Eligibility ---------------------
const Eligibility = Loadable(lazy(() => import('pages/premium_feachure/eligibility_req')));
const AddEligibility = Loadable(lazy(() => import('pages/premium_feachure/eligibility_req/AddEligibility')));
const EditEligibility = Loadable(lazy(() => import('pages/premium_feachure/eligibility_req/EditEligibility')));

// Teacher ---------------------
const Teachers = Loadable(lazy(() => import('pages/premium_feachure/teachers')));
const AddTeacher = Loadable(lazy(() => import('pages/premium_feachure/teachers/AddTeacher')));
const EditTeacher = Loadable(lazy(() => import('pages/premium_feachure/teachers/EditTeacher')));
const UploadImages = Loadable(lazy(() => import('pages/premium_feachure/teachers/UploadImages')));

// Teacher Rating -----------------------

const Rating = Loadable(lazy(() => import('pages/premium_feachure/teachers/teacher_rating')));
const AddTeacherRating = Loadable(lazy(() => import('pages/premium_feachure/teachers/teacher_rating/AddTeacherRating')));
const EditTeacherRating = Loadable(lazy(() => import('pages/premium_feachure/teachers/teacher_rating/EditTeacherRating')));

const TeacherSchedule = Loadable(lazy(() => import('pages/premium_feachure/teachers/schedule_time')));
const AddTeacherSchedule = Loadable(lazy(() => import('pages/premium_feachure/teachers/schedule_time/AddSchedule')));

const CourseHistory = Loadable(lazy(() => import('pages/premium_feachure/course-history/index')));
const ViewCourseHistory = Loadable(lazy(() => import('pages/premium_feachure/course-history/ViewCourseHistory')));

const BookingHistory = Loadable(lazy(() => import('pages/premium_feachure/booking-history/index')));
const ViewBookingHistory = Loadable(lazy(() => import('pages/premium_feachure/booking-history/ViewBooking')));

// Website

// Upload Video ---------------------
const ListAboutUs = Loadable(lazy(() => import('pages/website/aboutus/index')));
const AddAboutUs = Loadable(lazy(() => import('pages/website/aboutus/Add')));
const EditAboutUs = Loadable(lazy(() => import('pages/website/aboutus/Edit')));


const routes = (isLoggedIn) => [
    {
        path: '/',
        element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
        children: [
            { path: '/', element: <Navigate to="/dashboard" /> },

            // Dashboard
            { path: '/dashboard', element: <DashboardDefault /> },

            // User pages Routes
            { path: '/user', element: <UserManagement /> },
            { path: '/user/create', element: <UserAddForm /> },
            { path: '/user/:id/edit', element: <UserEditForm /> },
            { path: '/deleted_users', element: <DeletedUsers /> },
            { path: '/certificate', element: <Certificate /> },
            { path: '/live_class/subscribed_user', element: <LiveClassSubscriptionUser /> },
            { path: '/subscribed_user', element: <SubscribedUsers /> },

            //For Level
            { path: '/level', element: <Lavel /> },
            { path: '/level/create', element: <AddLavel /> },
            { path: '/level-edit/:id', element: <EditLavel /> },

            //Course management
            { path: '/course_management', element: <CourseManagement /> },
            { path: '/course_management/create', element: <AddCourseManagement /> },
            { path: '/course_management/:id/edit', element: <EditCourseManagement /> },
            { path: '/course_management/:course_id/course_detail', element: <CourseDetail /> },
            { path: '/course_management/:course_id/course_detail/create', element: <AddCourseDetail /> },
            { path: '/course_management/:course_id/course_detail/:course_detail_id/edit', element: <EditCourseDetail /> },

            // Subscription Management
            { path: '/subscription', element: <Subscription /> },
            { path: '/subscription/create', element: <AddSubscription /> },
            { path: '/subscription/:id/edit', element: <EditSubscription /> },

            { path: '/live_class_subscription', element: <LiveClassSubscription /> },
            { path: '/live_class_subscription/create', element: <AddLiveClassSubscription /> },
            { path: '/live_class_subscription/:id/edit', element: <EditLiveClassSubscription /> },

            // Email Management
            { path: '/email_management', element: <EmailManagement /> },
            // { path: '/email_management/create', element: <UserAddForm /> },
            // { path: '/email_management/:id/edit', element: <UserEditForm /> },

            //Notification Gallery
            { path: '/notifications', element: <NotificationManagement /> },
            { path: '/notifications/create', element: <AddNotifications /> },
            { path: '/notifications/:id/edit', element: <EditNotifications /> },

            // Promotional Posters
            { path: '/promotional_posters', element: <PromotioanlPosters /> },
            { path: '/promotional_posters/create', element: <AddPromotionalPosters /> },
            { path: '/promotional_posters/:id/edit', element: <EditPromotionalPosters /> },

            //Event Gallery
            { path: '/event_gallery', element: <EventGallery /> },
            { path: '/event_gallery/create', element: <AddEventGallery /> },
            { path: '/event_gallery/:id/edit', element: <EditEventGallery /> },

            //Reason
            { path: '/reason', element: <Reason /> },
            { path: '/reason/create', element: <AddReason /> },
            { path: '/reason/:id/edit', element: <EditReason /> },

            //Static Management
            { path: '/static/about_us', element: <StaticView from="aboutus" /> },
            { path: '/static/terms_condition', element: <StaticView from="terms" /> },
            { path: '/static/privacy_policy', element: <StaticView from="privacy" /> },
            { path: '/static/premium-terms-condition', element: <StaticView from="premium-terms" /> },

            // Review Rating Posters
            { path: '/rating_review', element: <ReviewRating /> },
            { path: '/rating_review/:id', element: <EditReviewRating /> },

            // Premium Rating Posters
            { path: '/pre_rating_review', element: <PreReviewRating /> },
            { path: '/pre_rating_review/:id', element: <PreEditReviewRating /> },
            { path: '/pre_rating_review/:id/view', element: <PreViewReviewRating /> },

            // Live Rating Posters
            { path: '/live_rating_review', element: <LiveReviewRating /> },
            { path: '/live_rating_review/:id', element: <LiveEditReviewRating /> },
            { path: '/live_rating_review/:id/view', element: <LiveViewReviewRating /> },

            // DeliveyCharges Route
            { path: '/delivery-charge', element: <DeliveyCharges /> },
            { path: '/delivery-charge/:id/edit', element: <EditDeliveyCharges /> },

            //Success Stories
            { path: '/success_stories', element: <SuccessStories /> },
            { path: '/success_stories/create', element: <AddSuccessStories /> },
            { path: '/success_stories/:id/edit', element: <EditSuccessStories /> },

            //Meet our team
            { path: '/meet_team', element: <MeetTeam /> },
            { path: '/meet_team/create', element: <AddMeetTeam /> },
            { path: '/meet_team/:id/edit', element: <EditMeetTeam /> },

            //Meet our team
            { path: '/payment_mode', element: <PaymentMode /> },
            { path: '/meet_team/create', element: <AddMeetTeam /> },
            { path: '/meet_team/:id/edit', element: <EditMeetTeam /> },

            //Meet our team
            { path: '/contact_us', element: <ContactUs /> },
            { path: '/contact_us/edit', element: <EditContactUs /> },

            //Product Management
            { path: '/product/manage_product', element: <ProductManagement /> },
            { path: '/product/manage_product/create', element: <AddProduct /> },
            { path: '/product/manage_product/:id/edit', element: <EditProduct /> },
            { path: '/product/manage_product/:id/upload', element: <ImageUpload /> },
            { path: '/product/manage_product/:id/image-list', element: <ImageList /> },
            { path: '/product/manage_product/:id/image/edit', element: <ImageEdit /> },
            { path: '/product/manage_product/:id/image/view', element: <ImageView /> },
            { path: '/product/manage_product/:id/upload-multiple-images', element: <MultipleImages /> },

            //Size Chart
            { path: '/size-chart', element: <SizeChart /> },
            { path: '/size-chart/create', element: <AddSize /> },
            { path: '/size-chart/:id/edit', element: <EditSize /> },

            //Product Deliver Route ----------------------------------
            { path: '/product-deliver', element: <ProductDeliver /> },
            { path: '/product-deliver/create', element: <AddProductDeliver /> },
            { path: '/product-deliver/:id/edit', element: <EditProductDeliver /> },

            //Customer Experinces Route ----------------------------------
            { path: '/customer-experince', element: <CustomerExperinces /> },
            { path: '/customer-experince/create', element: <AddCustomerExperinces /> },
            { path: '/customer-experince/:id/edit', element: <EditCustomerExperinces /> },

            //History Management
            { path: '/order_history', element: <OrderHistory /> },
            { path: '/order_history/:id/view', element: <ViewOrderHistory /> },

            //Walkthrough
            { path: '/walkthrough', element: <WalkthroughScreen /> },
            { path: '/walkthrough/create', element: <AddWalkThrough /> },
            { path: '/walkthrough/:id/edit', element: <EditWalkthrough /> },

            { path: '/sample-page', element: <SamplePage /> },
            { path: '*', element: <Navigate to="/" replace /> },
            // {
            //     path: 'member',
            //     element: <Outlet />,
            //     children: [
            //         { path: '/', element: <MemberGrid /> },
            //         { path: '/add', element: <AddMember /> }
            //     ]
            // }

            // Popular Courses Routes
            { path: '/popular/courses', element: <PopularCourses /> },
            { path: '/popular/courses/create', element: <AddPopularCourses /> },
            { path: '/popular/courses/:id/edit', element: <EditPopularCourses /> },

            // Course Upload Video Routes
            { path: '/course-upload-video/:id/list', element: <CourseUploadVideo /> },
            { path: '/course-upload-video/:id/create', element: <AddCourseUploadVideo /> },
            { path: '/course-upload-video/:id/edit', element: <EditCourseUploadVideo /> },

            // Live Courses Routes
            { path: '/live-courses', element: <LiveCourses /> },
            { path: '/live-courses/create', element: <AddLiveCourses /> },
            { path: '/live-courses/:id/edit', element: <EditLiveCourses /> },

            // SplashScreen Routes
            { path: '/splash-screen', element: <SplashScreen /> },
            { path: '/splash-screen/create', element: <AddSplashScreen /> },
            { path: '/splash-screen/:id/edit', element: <EditSplashScreen /> },

            // Live course Content Routes
            { path: '/live-course-Content/:id/list', element: <LiveCourseContent /> },
            { path: '/live-course-Content/:id/create', element: <AddLiveCourseContent /> },
            { path: '/live-course-Content/:id/edit', element: <EditLiveCourseContent /> },

            // Recorded Courses Routes
            { path: '/recorded-courses', element: <RecordedCourses /> },
            { path: '/recorded-courses/create', element: <AddRecordedCourses /> },
            { path: '/recorded-courses/:id/edit', element: <EditRecordedCourses /> },
            { path: '/recorded-courses/:id/upload-record-pdf', element: <UploadPdf /> },

            // Recorded Courses Routes
            { path: '/yoga-e-books', element: <YogaEBooks /> },
            { path: '/yoga-e-books/create', element: <AddYogaEBook /> },
            { path: '/yoga-e-books/:id/edit', element: <EditYogaEBook /> },

            // Recorded course Content Routes
            { path: '/course-Content/:id/list', element: <CourseContent /> },
            { path: '/course-Content/:id/create', element: <AddCourseContent /> },
            { path: '/course-Content/:id/edit', element: <EditCourseContent /> },

            // Recorded course What will learn Content Routes
            { path: '/record-static-content/:id/list', element: <RecordStaticContent /> },
            { path: '/record-static-content/:id/create', element: <AddRecordStaticContent /> },
            { path: '/record-static-content/:id/edit', element: <EditRecordStaticContent /> },

            // Recorded course Comprises Routes
            { path: '/record-course-comprises/:id/list', element: <RecordCourseComprise /> },
            { path: '/record-course-comprises/:id/create', element: <AddRecordCourseComprise /> },
            { path: '/record-course-comprises/:id/edit', element: <EditRecordCourseComprise /> },

            // Live course What will learn Content Routes
            { path: '/live-static-content/:id/list', element: <LiveStaticContent /> },
            { path: '/live-static-content/:id/create', element: <AddLiveStaticContent /> },
            { path: '/live-static-content/:id/edit', element: <EditLiveStaticContent /> },

            // Live course Comprises Routes
            { path: '/live-course-comprises/:id/list', element: <LiveCourseComprise /> },
            { path: '/live-course-comprises/:id/create', element: <AddLiveCourseComprise /> },
            { path: '/live-course-comprises/:id/edit', element: <EditLiveCourseComprise /> },

            // Live course Comprises Routes
            { path: '/live-eligibility-requirements/:id/list', element: <LiveEligibility /> },
            { path: '/live-eligibility-requirements/:id/create', element: <AddLiveEligibility /> },
            { path: '/live-eligibility-requirements/:id/edit', element: <EditLiveEligibility /> },

            // Record course Comprises Routes
            { path: '/record-eligibility-requirements/:id/list', element: <RecordEligibility /> },
            { path: '/record-eligibility-requirements/:id/create', element: <AddRecordEligibility /> },
            { path: '/record-eligibility-requirements/:id/edit', element: <EditRecordEligibility /> },

            // Upload Video Routes
            { path: '/upload-video/:id/list', element: <UploadVideo /> },
            { path: '/upload-video/:id/create', element: <AddUploadVideo /> },
            { path: '/upload-video/:id/edit', element: <EditUploadVideo /> },

            // Sataic Content Routes
            { path: '/static-content', element: <StaticContent /> },
            { path: '/static-content/create', element: <AddStaticContent /> },
            { path: '/static-content/:id/edit', element: <EditStaticContent /> },

            // course-comprise Routes
            { path: '/course-comprise', element: <CourseComprise /> },
            { path: '/course-comprise/create', element: <AddCourseComprise /> },
            { path: '/course-comprise/:id/edit', element: <EditCourseComprise /> },

            // Eligibility Routes
            { path: '/eligibility', element: <Eligibility /> },
            { path: '/eligibility/create', element: <AddEligibility /> },
            { path: '/eligibility/:id/edit', element: <EditEligibility /> },

            // Teacher Routes
            { path: '/teachers', element: <Teachers /> },
            { path: '/teachers/create', element: <AddTeacher /> },
            { path: '/teachers/:id/edit', element: <EditTeacher /> },
            { path: '/teachers/:id/upload-teacher-images', element: <UploadImages /> },

            // Teacher Rating
            { path: '/rating', element: <Rating /> },
            { path: '/rating/create', element: <AddTeacherRating /> },
            { path: '/rating/:id/edit', element: <EditTeacherRating /> },

            { path: '/teachers/:id/teacher-schedule', element: <TeacherSchedule /> },
            { path: '/teachers/:id/teacher-schedule-create', element: <AddTeacherSchedule /> },

            { path: '/course-histories', element: <CourseHistory /> },
            { path: '/course-histories/:id/view', element: <ViewCourseHistory /> },

            { path: '/booking-histories', element: <BookingHistory /> },
            { path: '/booking-histories/:id/view', element: <ViewBookingHistory /> },

            //Website Admin Routes
            { path: '/about-us/list', element: <ListAboutUs /> },
            { path: '/about-us/create', element: <AddAboutUs /> },
            { path: '/about-us/:id/edit', element: <EditAboutUs /> },
        ]
    },
    {
        path: '/',
        element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/app/dashboard" />,
        children: [
            { path: 'login', element: <AuthLogin /> },
            { path: 'forgot-password', element: <AuthForgotPassword /> },
            { path: '/', element: <Navigate to="/login" /> }
        ]
    }
];

export default routes;
