import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { openDrawerAction, staticactions } from 'redux/reducers/static.slice';
import PropTypes from 'prop-types';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const { openDrawer, staticReducer } = props;
    const { drawerOpen } = staticReducer;

    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        openDrawer({ drawerOpen: !open });
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        openDrawer({ drawerOpen: !matchDownLG });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    return (
        <Box sx={{ display: 'flex', width: '100%', minHeight: '100vh', backgroundColor: 'grey.200' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 5, sm: 5 } }}>
                <Toolbar />
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
                <Outlet />
            </Box>
        </Box>
    );
};

MainLayout.propTypes = {
    openDrawer: PropTypes.func,
    staticReducer: PropTypes.object
};

const mapStateToProps = (state) => {
    return { staticReducer: state.staticReducer };
};

const mapDispatchToProps = (dispatch) => ({
    openDrawer: (params) => dispatch(staticactions.openDrawerAction(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
