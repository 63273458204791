import { Link, useParams } from 'react-router-dom';
import { capitalizeFirstLetter, getBadge } from 'utils/helper';
import {
    changeStatusProductImageAction,
    deleteProductImageAction,
    ecommerceActions,
    productImageByIdAction,
    showProductImageByIdAction
} from 'redux/reducers/ecommerce.slice';

import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { CustomActionButton } from 'components/@extended/ActionButton';
import CustomDataGrid from 'components/CustomDataGrid';
import Grid from '@mui/material/Grid';
import { LoadingStatus } from 'utils/constant';
import MainCard from 'components/MainCard';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import { useEffect } from 'react';
import { useTitle } from 'components/useTitle';

export const ProductImageList = (props) => {
    const { ecommerceReducer, deleteProductImage, getProductImageByID, onChangeStatusProductImage, resetProductState,resetUploadImageState, getImagesByID } =
        props;
    const routeParams = useParams();
    useTitle({ title: 'Products' });
    useEffect(() => {
        resetUploadImageState();
        getImagesByID({ product_id: routeParams.id });
    }, []);

    useEffect(() => {
        if (
            ecommerceReducer.deleteProductImageLoadingStatus === LoadingStatus.LOADED ||
            ecommerceReducer.changeStatusProductImageLoadingStatus === LoadingStatus.LOADED
        ) {
            resetUploadImageState();
            getImagesByID({ product_id: routeParams.id });
        }
    }, [ecommerceReducer]);

    const rows = ecommerceReducer?.productImageList ?? [];

    const columns = [
        {
            field: 'productImage_image',
            headerName: 'Image',
            flex: 1,
            minWidth: 80,
            filterable: false,
            sortable: false,
            renderCell: (params) => <Avatar sx={{ width: 75, height: 75, border: '1px solid grey' }} src={params.row.productImage_image} />
        },
        {
            field: 'productImageColor',
            headerName: 'Color',
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => params.row.productImageColor ?? 'N/A'
        },
        {
            field: 'productImageColorName',
            headerName: 'Color Name',
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => params.row.productImageColorName ?? 'N/A'
        },
        {
            field: 'productImage_amount',
            headerName: 'Price',
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => params.row.productImage_amount ?? 'N/A'
        },
        {
            field: 'available_quantity',
            flex: 1,
            minWidth: 250,
            headerName: 'Available Quantity',
            valueGetter: (params) => params.row.available_quantity ?? 'N/A'
        },
        {
            field: 'productImage_status',
            headerName: 'Status',
            flex: 1,
            minWidth: 150,
            type: 'singleSelect',
            valueOptions: ['Active', 'Inactive'],
            renderCell: (params) => (
                <Chip size="small" color={getBadge(params.row.productImage_status)} label={capitalizeFirstLetter(params.row.productImage_status ?? 'N/A')} />
            )
        },
        {
            field: 'created_at',
            headerName: 'Date Created',
            type: 'dateTime',
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => (params.row?.created_at ? dayjs(params.row.created_at).format('DD/MM/YYYY') : 'N/A')
        },

        {
            field: 'actions',
            headerName: 'Action(s)',
            type: 'actions',
            flex: 1,
            minWidth: 150,
            getActions: (params) => [
                <CustomActionButton
                    type={'Upload Image'}
                    to={`/product/manage_product/${params.row.productImage_id}/upload-multiple-images`}
                    // onClick={() => getImagesByID({ product_id: params.row.id })}
                />,

                <CustomActionButton
                    type={'View'}
                    to={`/product/manage_product/${params.row?.productImage_id}/image/view`}
                    // onClick={() => getOrderHistoryByID({ order_id: params.row.id })}
                />,
                <CustomActionButton
                    type={params.row.productImage_status === 'active' ? 'Active' : 'Inactive'}
                    onClick={() => onChangeStatus(params.row)}
                />,
                <CustomActionButton
                    type={'Edit'}
                    to={`/product/manage_product/${params.row.productImage_id}/image/edit`}
                    onClick={() => getProductImageByID({ id: params.row.productImage_id })}
                />,

                <CustomActionButton type={'Delete'} onClick={() => onDeleteClick(params.row)} />
            ]
        }
    ];

    const onDeleteClick = (val) => {
        swal({
            title: 'Product image Delete ?',
            text: 'Are you sure, you want to delete this product image !',
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                deleteProductImage({ id: val.productImage_id });
            }
        });
    };
    const onChangeStatus = (val) => {
        const msg = val?.productImage_status === 'inactive' ? 'active' : 'inactive';
        swal({
            title: `Product image ${msg} ?`,
            text: `Are you sure, you want to ${msg} this product image!`,
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                onChangeStatusProductImage({ id: val.productImage_id, status: msg });
            }
        });
    };
    return (
        <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between" pr={5}>
                <Grid item>
                    <Typography variant="h5">Product Image Details</Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Button component={Link} size="large" to={`/product/manage_product/${routeParams.id}/upload`} color={'primary'} variant={'outlined'}>
                            Add Image & Size
                        </Button>
                        <Button component={Link} size="large" to={`/product/manage_product`} color={'primary'} variant={'outlined'}>
                            Back
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
                <Box sx={{ pt: 1, px: 2 }}>
                    <CustomDataGrid rows={rows} columns={columns} />
                </Box>
            </MainCard>
        </Grid>
    );
};
ProductImageList.propTypes = {
    ecommerceReducer: PropTypes.object,
    deleteProductImage: PropTypes.func,
    getProductImageByID: PropTypes.func,
    getImagesByID: PropTypes.func,
    onChangeStatusProductImage: PropTypes.func,
    resetProductState: PropTypes.func
};

const mapStateToProps = (state) => {
    return { ecommerceReducer: state.ecommerceReducer };
};

const mapDispatchToProps = (dispatch) => ({
    deleteProductImage: (val) => dispatch(deleteProductImageAction(val)),
    getProductImageByID: (val) => dispatch(showProductImageByIdAction(val)),
    getImagesByID: (val) => dispatch(productImageByIdAction(val)),
    onChangeStatusProductImage: (val) => dispatch(changeStatusProductImageAction(val)),
    resetProductState: () => dispatch(ecommerceActions.resetProductsState()),
    resetUploadImageState: () => dispatch(ecommerceActions.resetUploadImageState())


});
export default connect(mapStateToProps, mapDispatchToProps)(ProductImageList);
