/* eslint-disable no-unused-vars */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ToastMessage from 'utils/ToastMessage';
const SLICE_NAME = 'static';

const staticentityAdapter = createEntityAdapter();

const staticinitialState = staticentityAdapter.getInitialState({
    allAboutusLoadingStatus: LoadingStatus.NOT_LOADED,
    allPrivacyPolicyLoadingStatus: LoadingStatus.NOT_LOADED,
    allTermsAndConditionLoadingStatus: LoadingStatus.NOT_LOADED,
    allPremiumTermsAndConditionLoadingStatus: LoadingStatus.NOT_LOADED,
    editAboutusLoadingStatus: LoadingStatus.NOT_LOADED,
    editPrivacyPolicyLoadingStatus: LoadingStatus.NOT_LOADED,
    editTermsAndConditionLoadingStatus: LoadingStatus.NOT_LOADED,
    allAboutus: [],
    allPrivacyPolicy: [],
    allTermsAndCondition: [],
    allPremiumTermsAndCondition:[],
    allAboutusError: null,
    allPrivacyPolicyError: null,
    editAboutusError: null,
    allTermsAndConditionError: null,
    allPremiumTermsAndConditionError: null,
    openItem: ['dashboard'],
    openComponent: 'buttons',
    drawerOpen: false,
    componentDrawerOpen: true,

    dashboardCountLoadingStatus: LoadingStatus.NOT_LOADED,
    dashboardCount: null,
    dashboardCountError: null
});

/**
 * Dashboard count action
 */

export const dashboardCountAction = createAsyncThunk(`${SLICE_NAME}/dashboardCountAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/dashboard-count',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All About US Action
 */

export const allAboutusAction = createAsyncThunk(`${SLICE_NAME}/allAboutusAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/aboutUs',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
export const allPrivacyPolicyAction = createAsyncThunk(`${SLICE_NAME}/allPrivacyPolicyAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/privacyPolicy',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
export const allTermsAndConditionAction = createAsyncThunk(`${SLICE_NAME}/allTermsAndConditionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/termsANDcondition',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

export const allPremiumTermsAndConditionAction = createAsyncThunk(`${SLICE_NAME}/allPremiumTermsAndConditionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/premium-terms-conditions',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit Static Data Action
 */
export const editStaticData = createAsyncThunk(`${SLICE_NAME}/editStaticData`, async (params, thunkAPI) => {
    const { from, ...reqBody } = params;
    try {
        const result = await axiosRequest({
            url: `/updateStaticPage`,
            method: 'POST',
            data: reqBody
        });
        return { from, ...result };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const staticSlice = createSlice({
    name: SLICE_NAME,
    initialState: staticinitialState,
    reducers: {
        activeItemAction: (state, action) => {
            return {
                ...state,
                openItem: action.payload.openItem
            };
        },
        activeComponentAction: (state, action) => {
            return {
                ...state,
                openComponent: action.payload.openComponent
            };
        },
        openDrawerAction: (state, action) => {
            return {
                ...state,
                drawerOpen: action.payload.drawerOpen
            };
        },
        openComponentDrawerAction: (state, action) => {
            return {
                ...state,
                componentDrawerOpen: action.payload.componentDrawerOpen
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // all dashboard count action
            .addCase(dashboardCountAction.pending, (state, action) => {
                state.dashboardCountLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(dashboardCountAction.fulfilled, (state, action) => {
                state.dashboardCountLoadingStatus = LoadingStatus.LOADED;
                state.dashboardCount = action.payload?.data;
            })
            .addCase(dashboardCountAction.rejected, (state, action) => {
                state.dashboardCountLoadingStatus = LoadingStatus.FAILED;
                state.dashboardCountError = action.payload || action.error.message;
            })
            // all About us action
            .addCase(allAboutusAction.pending, (state, action) => {
                state.allAboutusLoadingStatus = LoadingStatus.LOADING;
                state.allAboutusError = null;
            })
            .addCase(allAboutusAction.fulfilled, (state, action) => {
                state.allAboutusLoadingStatus = LoadingStatus.LOADED;
                state.allAboutus = action.payload?.data;
            })
            .addCase(allAboutusAction.rejected, (state, action) => {
                state.allAboutusLoadingStatus = LoadingStatus.FAILED;
                state.allAboutusError = action.payload || action.error.message;
            })
            // all Privacy Policy action
            .addCase(allPrivacyPolicyAction.pending, (state, action) => {
                state.allPrivacyPolicyLoadingStatus = LoadingStatus.LOADING;
                state.allPrivacyPolicyError = null;
            })
            .addCase(allPrivacyPolicyAction.fulfilled, (state, action) => {
                state.allPrivacyPolicyLoadingStatus = LoadingStatus.LOADED;
                state.allPrivacyPolicy = action.payload?.data;
            })
            .addCase(allPrivacyPolicyAction.rejected, (state, action) => {
                state.allPrivacyPolicyLoadingStatus = LoadingStatus.FAILED;
                state.allPrivacyPolicyError = action.payload || action.error.message;
            })
            // all Terms and Condition action
            .addCase(allTermsAndConditionAction.pending, (state, action) => {
                state.allTermsAndConditionLoadingStatus = LoadingStatus.LOADING;
                state.allTermsAndConditionError = null;
            })
            .addCase(allTermsAndConditionAction.fulfilled, (state, action) => {
                state.allTermsAndConditionLoadingStatus = LoadingStatus.LOADED;
                state.allTermsAndCondition = action.payload?.data;
            })
            .addCase(allTermsAndConditionAction.rejected, (state, action) => {
                state.allTermsAndConditionLoadingStatus = LoadingStatus.FAILED;
                state.allTermsAndConditionError = action.payload || action.error.message;
            })
             // all premium Terms and Condition action
             .addCase(allPremiumTermsAndConditionAction.pending, (state, action) => {
                state.allPremiumTermsAndConditionLoadingStatus = LoadingStatus.LOADING;
                state.allPremiumTermsAndConditionError = null;
            })
            .addCase(allPremiumTermsAndConditionAction.fulfilled, (state, action) => {
                state.allPremiumTermsAndConditionLoadingStatus = LoadingStatus.LOADED;
                state.allPremiumTermsAndCondition = action.payload?.data;
            })
            .addCase(allPremiumTermsAndConditionAction.rejected, (state, action) => {
                state.allPremiumTermsAndConditionLoadingStatus = LoadingStatus.FAILED;
                state.allPremiumTermsAndConditionError = action.payload || action.error.message;
            })
            //edit Static action
            .addCase(editStaticData.pending, (state, action) => {
                state.editAboutusLoadingStatus = LoadingStatus.LOADING;
                state.editAboutusAction = null;
            })
            .addCase(editStaticData.fulfilled, (state, action) => {
                state.editAboutusLoadingStatus = LoadingStatus.LOADED;
                switch (action.payload.from) {
                    case 'aboutus':
                        state.allAboutus = action.payload?.data;
                        ToastMessage.success('About us updated successfully.');
                        break;
                    case 'terms':
                        state.allTermsAndCondition = action.payload?.data;
                        ToastMessage.success('Terms & Conditions updated successfully.');
                        break;
                    case 'premium-terms':
                        state.allPremiumTermsAndCondition = action.payload?.data;
                        ToastMessage.success('Terms & Conditions updated successfully.');
                        break;
                    case 'privacy':
                        state.allPrivacyPolicy = action.payload?.data;
                        ToastMessage.success('Privacy Policy updated successfully.');
                        break;
                    default:
                        break;
                }
            })
            .addCase(editStaticData.rejected, (state, action) => {
                state.editAboutusLoadingStatus = LoadingStatus.FAILED;
                state.editAboutusAction = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const staticReducer = staticSlice.reducer;
export const staticactions = staticSlice.actions;

export const {
    activeItemAction,
    activeComponentAction,
    openDrawerAction,
    openComponentDrawerAction,
    resetEditPrivacyPolicyState,
    resetEditTermsAndConditionState
} = staticSlice.actions;
