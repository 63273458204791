/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'popularCourse';

const initialState = entityAdapter.getInitialState({
    allPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    popularCourseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    editPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    deletePopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    allPopularCourses: [],
    popularCourseById: null,
    allPopularCourseError: null,
    popularCourseByIdError: null,
    addPopularCourseError: null,
    editPopularCourseError: null,
    deletePopularCourseError: null,
    changeStatusPopularCourseError: null,
    allLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    liveClassPopularCourseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    editLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    allLiveClassPopularCourses: [],
    liveClassPopularCourseById: null,
    allLiveClassPopularCourseError: null,
    liveClassPopularCourseByIdError: null,
    addLiveClassPopularCourseError: null,
    editLiveClassPopularCourseError: null,
    deleteLiveClassPopularCourseError: null,
    changeStatusLiveClassPopularCourseError: null
});

/**
 * All PopularCourse Action
 */

export const allPopularCourseAction = createAsyncThunk(`${SLICE_NAME}/allPopularCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/courses',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add PopularCourse Action
 */

export const addPopularCourseAction = createAsyncThunk(`${SLICE_NAME}/addPopularCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  PopularCourse by ID Action
 */

export const popularCourseByIdAction = createAsyncThunk(`${SLICE_NAME}/popularCourseByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-view/' + params.id,
            method: 'GET'
            // data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit PopularCourse Action
 */

export const editPopularCourseAction = createAsyncThunk(`${SLICE_NAME}/editPopularCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete PopularCourse Action
 */

export const deletePopularCourseAction = createAsyncThunk(`${SLICE_NAME}/deletePopularCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status PopularCourse Action
 */

export const changeStatusPopularCourseAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusPopularCourseAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/course-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * All LiveClassPopularCourse Action
 */

export const allLiveClassPopularCourseAction = createAsyncThunk(
    `${SLICE_NAME}/allLiveClassPopularCourseAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/show-live-class',
                method: 'GET'
                // data: reqBody
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 *  LiveClassPopularCourse by ID Action
 */

export const liveClassPopularCourseByIdAction = createAsyncThunk(
    `${SLICE_NAME}/liveClassPopularCourseByIdAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/view-live-class',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Add LiveClassPopularCourse Action
 */

export const addLiveClassPopularCourseAction = createAsyncThunk(
    `${SLICE_NAME}/addLiveClassPopularCourseAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/save-live-class',
                method: 'POST',
                data: params,
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);
/**
 * Edit LiveClassPopularCourse Action
 */

export const editLiveClassPopularCourseAction = createAsyncThunk(
    `${SLICE_NAME}/editLiveClassPopularCourseAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/update-live-class',
                method: 'POST',
                data: params,
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);
/**
 * Delete PopularCourse Action
 */

export const deleteLiveClassPopularCourseAction = createAsyncThunk(
    `${SLICE_NAME}/deleteLiveClassPopularCourseAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/course-delete',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Change Status LiveClassPopularCourse Action
 */

export const changeStatusLiveClassPopularCourseAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusLiveClassPopularCourseAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/change-status-live-class',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const popularCourseSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetPopularCourseState: (state, action) => {
            return {
                ...state,
                popularCourseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                editPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                deletePopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                popularCourseById: null,
                popularCourseByIdError: null,
                addPopularCourseError: null,
                editPopularCourseError: null,
                deletePopularCourseError: null,
                changeStatusPopularCourseError: null
            };
        },
        resetLiveClassPopularCourseState: (state, action) => {
            return {
                ...state,
                liveClassPopularCourseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                editLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusLiveClassPopularCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                liveClassPopularCourseById: null,
                liveClassPopularCourseByIdError: null,
                addLiveClassPopularCourseError: null,
                editLiveClassPopularCourseError: null,
                deleteLiveClassPopularCourseError: null,
                changeStatusLiveClassPopularCourseError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All PopularCourse action
            .addCase(allPopularCourseAction.pending, (state, action) => {
                state.allPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allPopularCourseAction.fulfilled, (state, action) => {
                state.allPopularCourseLoadingStatus = LoadingStatus.LOADED;
                state.allPopularCourses = action.payload?.data ?? [];
            })
            .addCase(allPopularCourseAction.rejected, (state, action) => {
                state.allPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.allPopularCourseError = action.payload || action.error.message;
            })
            //  PopularCourse by id action
            .addCase(popularCourseByIdAction.pending, (state, action) => {
                state.popularCourseByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(popularCourseByIdAction.fulfilled, (state, action) => {
                state.popularCourseByIdLoadingStatus = LoadingStatus.LOADED;
                state.popularCourseById = action.payload?.data;
            })
            .addCase(popularCourseByIdAction.rejected, (state, action) => {
                state.popularCourseByIdLoadingStatus = LoadingStatus.FAILED;
                state.popularCourseByIdError = action.payload || action.error.message;
            })
            // Add PopularCourse action
            .addCase(addPopularCourseAction.pending, (state, action) => {
                state.addPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addPopularCourseAction.fulfilled, (state, action) => {
                state.addPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('PopularCourse added successfully.');
            })
            .addCase(addPopularCourseAction.rejected, (state, action) => {
                state.addPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.addPopularCourseError = action.payload || action.error.message;
            })
            // Edit PopularCourse action
            .addCase(editPopularCourseAction.pending, (state, action) => {
                state.editPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editPopularCourseAction.fulfilled, (state, action) => {
                state.editPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('PopularCourse updated successfully.');
            })
            .addCase(editPopularCourseAction.rejected, (state, action) => {
                state.editPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.editPopularCourseError = action.payload || action.error.message;
            })
            // Delete PopularCourse action
            .addCase(deletePopularCourseAction.pending, (state, action) => {
                state.deletePopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deletePopularCourseAction.fulfilled, (state, action) => {
                state.deletePopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('PopularCourse deleted successfully.');
            })
            .addCase(deletePopularCourseAction.rejected, (state, action) => {
                state.deletePopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.deletePopularCourseError = action.payload || action.error.message;
            })
            // Change Status PopularCourse action
            .addCase(changeStatusPopularCourseAction.pending, (state, action) => {
                state.changeStatusPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusPopularCourseAction.fulfilled, (state, action) => {
                state.changeStatusPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('PopularCourse status updated successfully.');
            })
            .addCase(changeStatusPopularCourseAction.rejected, (state, action) => {
                state.changeStatusPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusPopularCourseError = action.payload || action.error.message;
            })
            // All Live class popularCourse detail action
            .addCase(allLiveClassPopularCourseAction.pending, (state, action) => {
                state.allLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allLiveClassPopularCourseAction.fulfilled, (state, action) => {
                state.allLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADED;
                state.allLiveClassPopularCourses = action.payload?.data ?? [];
            })
            .addCase(allLiveClassPopularCourseAction.rejected, (state, action) => {
                state.allLiveClassPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.allLiveClassPopularCourseError = action.payload || action.error.message;
            })
            //  Live class popularCourse details by id action
            .addCase(liveClassPopularCourseByIdAction.pending, (state, action) => {
                state.liveClassPopularCourseByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(liveClassPopularCourseByIdAction.fulfilled, (state, action) => {
                state.liveClassPopularCourseByIdLoadingStatus = LoadingStatus.LOADED;
                state.liveClassPopularCourseById = action.payload?.data ?? [];
            })
            .addCase(liveClassPopularCourseByIdAction.rejected, (state, action) => {
                state.liveClassPopularCourseByIdLoadingStatus = LoadingStatus.FAILED;
                state.liveClassPopularCourseByIdError = action.payload || action.error.message;
            })
            // Add Live class popularCourse detail action
            .addCase(addLiveClassPopularCourseAction.pending, (state, action) => {
                state.addLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addLiveClassPopularCourseAction.fulfilled, (state, action) => {
                state.addLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class popularCourse added successfully.');
            })
            .addCase(addLiveClassPopularCourseAction.rejected, (state, action) => {
                state.addLiveClassPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.addLiveClassPopularCourseError = action.payload || action.error.message;
            })
            // Edit Live class popularCourse detail action
            .addCase(editLiveClassPopularCourseAction.pending, (state, action) => {
                state.editLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editLiveClassPopularCourseAction.fulfilled, (state, action) => {
                state.editLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class popularCourse updated successfully.');
            })
            .addCase(editLiveClassPopularCourseAction.rejected, (state, action) => {
                state.editLiveClassPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.editLiveClassPopularCourseError = action.payload || action.error.message;
            })
            // Delete Live class popularCourse detail action
            .addCase(deleteLiveClassPopularCourseAction.pending, (state, action) => {
                state.deleteLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteLiveClassPopularCourseAction.fulfilled, (state, action) => {
                state.deleteLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class popularCourse deleted successfully.');
            })
            .addCase(deleteLiveClassPopularCourseAction.rejected, (state, action) => {
                state.deleteLiveClassPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.deleteLiveClassPopularCourseError = action.payload || action.error.message;
            })
            // Change Status Live class popularCourse detail action
            .addCase(changeStatusLiveClassPopularCourseAction.pending, (state, action) => {
                state.changeStatusLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusLiveClassPopularCourseAction.fulfilled, (state, action) => {
                state.changeStatusLiveClassPopularCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class popularCourse status updated successfully.');
            })
            .addCase(changeStatusLiveClassPopularCourseAction.rejected, (state, action) => {
                state.changeStatusLiveClassPopularCourseLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusLiveClassPopularCourseError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const popularCourseReducer = popularCourseSlice.reducer;

export const popularCourseActions = popularCourseSlice.actions;
