/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'subscription';

const initialState = entityAdapter.getInitialState({
    allSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    subscriptionByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    editSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    allSubscriptions: [],
    subscriptionById: null,
    allSubscriptionError: null,
    subscriptionByIdError: null,
    addSubscriptionError: null,
    editSubscriptionError: null,
    deleteSubscriptionError: null,
    changeStatusSubscriptionError: null,
    allLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    liveClassSubscriptionByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    editLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
    allLiveClassSubscriptions: [],
    liveClassSubscriptionById: null,
    allLiveClassSubscriptionError: null,
    liveClassSubscriptionByIdError: null,
    addLiveClassSubscriptionError: null,
    editLiveClassSubscriptionError: null,
    deleteLiveClassSubscriptionError: null,
    changeStatusLiveClassSubscriptionError: null
});

/**
 * All Subscription Action
 */

export const allSubscriptionAction = createAsyncThunk(`${SLICE_NAME}/allSubscriptionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/list-subscription-data',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Subscription by ID Action
 */

export const subscriptionByIdAction = createAsyncThunk(`${SLICE_NAME}/subscriptionByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-subscription-package',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add Subscription Action
 */

export const addSubscriptionAction = createAsyncThunk(`${SLICE_NAME}/addSubscriptionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-subscription-package',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit Subscription Action
 */

export const editSubscriptionAction = createAsyncThunk(`${SLICE_NAME}/editSubscriptionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-subscription-package',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete Subscription Action
 */

export const deleteSubscriptionAction = createAsyncThunk(`${SLICE_NAME}/deleteSubscriptionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-subscription-package',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status Subscription Action
 */

export const changeStatusSubscriptionAction = createAsyncThunk(`${SLICE_NAME}/changeStatusSubscriptionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/status-subscription-package',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All LiveClassSubscription Action
 */

export const allLiveClassSubscriptionAction = createAsyncThunk(`${SLICE_NAME}/allLiveClassSubscriptionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/show-live-class',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  LiveClassSubscription by ID Action
 */

export const liveClassSubscriptionByIdAction = createAsyncThunk(
    `${SLICE_NAME}/liveClassSubscriptionByIdAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/view-live-class',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Add LiveClassSubscription Action
 */

export const addLiveClassSubscriptionAction = createAsyncThunk(`${SLICE_NAME}/addLiveClassSubscriptionAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-live-class',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit LiveClassSubscription Action
 */

export const editLiveClassSubscriptionAction = createAsyncThunk(
    `${SLICE_NAME}/editLiveClassSubscriptionAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/update-live-class',
                method: 'POST',
                data: params,
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);
/**
 * Delete Subscription Action
 */

export const deleteLiveClassSubscriptionAction = createAsyncThunk(
    `${SLICE_NAME}/deleteLiveClassSubscriptionAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/delete-live-class',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Change Status LiveClassSubscription Action
 */

export const changeStatusLiveClassSubscriptionAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusLiveClassSubscriptionAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/change-status-live-class',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const subscriptionSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetSubscriptionState: (state, action) => {
            return {
                ...state,
                subscriptionByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                editSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                subscriptionById: null,
                subscriptionByIdError: null,
                addSubscriptionError: null,
                editSubscriptionError: null,
                deleteSubscriptionError: null,
                changeStatusSubscriptionError: null
            };
        },
        resetLiveClassSubscriptionState: (state, action) => {
            return {
                ...state,
                liveClassSubscriptionByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                editLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusLiveClassSubscriptionLoadingStatus: LoadingStatus.NOT_LOADED,
                liveClassSubscriptionById: null,
                liveClassSubscriptionByIdError: null,
                addLiveClassSubscriptionError: null,
                editLiveClassSubscriptionError: null,
                deleteLiveClassSubscriptionError: null,
                changeStatusLiveClassSubscriptionError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All Subscription action
            .addCase(allSubscriptionAction.pending, (state, action) => {
                state.allSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allSubscriptionAction.fulfilled, (state, action) => {
                state.allSubscriptionLoadingStatus = LoadingStatus.LOADED;
                state.allSubscriptions = action.payload?.data ?? [];
            })
            .addCase(allSubscriptionAction.rejected, (state, action) => {
                state.allSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.allSubscriptionError = action.payload || action.error.message;
            })
            //  Subscription by id action
            .addCase(subscriptionByIdAction.pending, (state, action) => {
                state.subscriptionByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(subscriptionByIdAction.fulfilled, (state, action) => {
                state.subscriptionByIdLoadingStatus = LoadingStatus.LOADED;
                state.subscriptionById = action.payload?.data;
            })
            .addCase(subscriptionByIdAction.rejected, (state, action) => {
                state.subscriptionByIdLoadingStatus = LoadingStatus.FAILED;
                state.subscriptionByIdError = action.payload || action.error.message;
            })
            // Add Subscription action
            .addCase(addSubscriptionAction.pending, (state, action) => {
                state.addSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addSubscriptionAction.fulfilled, (state, action) => {
                state.addSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Subscription added successfully.');
            })
            .addCase(addSubscriptionAction.rejected, (state, action) => {
                state.addSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.addSubscriptionError = action.payload || action.error.message;
            })
            // Edit Subscription action
            .addCase(editSubscriptionAction.pending, (state, action) => {
                state.editSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editSubscriptionAction.fulfilled, (state, action) => {
                state.editSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Subscription updated successfully.');
            })
            .addCase(editSubscriptionAction.rejected, (state, action) => {
                state.editSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.editSubscriptionError = action.payload || action.error.message;
            })
            // Delete Subscription action
            .addCase(deleteSubscriptionAction.pending, (state, action) => {
                state.deleteSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteSubscriptionAction.fulfilled, (state, action) => {
                state.deleteSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Subscription deleted successfully.');
            })
            .addCase(deleteSubscriptionAction.rejected, (state, action) => {
                state.deleteSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.deleteSubscriptionError = action.payload || action.error.message;
            })
            // Change Status Subscription action
            .addCase(changeStatusSubscriptionAction.pending, (state, action) => {
                state.changeStatusSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusSubscriptionAction.fulfilled, (state, action) => {
                state.changeStatusSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Subscription status updated successfully.');
            })
            .addCase(changeStatusSubscriptionAction.rejected, (state, action) => {
                state.changeStatusSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusSubscriptionError = action.payload || action.error.message;
            })
            // All Live class subscription detail action
            .addCase(allLiveClassSubscriptionAction.pending, (state, action) => {
                state.allLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allLiveClassSubscriptionAction.fulfilled, (state, action) => {
                state.allLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADED;
                state.allLiveClassSubscriptions = action.payload?.data ?? [];
            })
            .addCase(allLiveClassSubscriptionAction.rejected, (state, action) => {
                state.allLiveClassSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.allLiveClassSubscriptionError = action.payload || action.error.message;
            })
            //  Live class subscription details by id action
            .addCase(liveClassSubscriptionByIdAction.pending, (state, action) => {
                state.liveClassSubscriptionByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(liveClassSubscriptionByIdAction.fulfilled, (state, action) => {
                state.liveClassSubscriptionByIdLoadingStatus = LoadingStatus.LOADED;
                state.liveClassSubscriptionById = action.payload?.data;
            })
            .addCase(liveClassSubscriptionByIdAction.rejected, (state, action) => {
                state.liveClassSubscriptionByIdLoadingStatus = LoadingStatus.FAILED;
                state.liveClassSubscriptionByIdError = action.payload || action.error.message;
            })
            // Add Live class subscription detail action
            .addCase(addLiveClassSubscriptionAction.pending, (state, action) => {
                state.addLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addLiveClassSubscriptionAction.fulfilled, (state, action) => {
                state.addLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class subscription added successfully.');
            })
            .addCase(addLiveClassSubscriptionAction.rejected, (state, action) => {
                state.addLiveClassSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.addLiveClassSubscriptionError = action.payload || action.error.message;
            })
            // Edit Live class subscription detail action
            .addCase(editLiveClassSubscriptionAction.pending, (state, action) => {
                state.editLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editLiveClassSubscriptionAction.fulfilled, (state, action) => {
                state.editLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class subscription updated successfully.');
            })
            .addCase(editLiveClassSubscriptionAction.rejected, (state, action) => {
                state.editLiveClassSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.editLiveClassSubscriptionError = action.payload || action.error.message;
            })
            // Delete Live class subscription detail action
            .addCase(deleteLiveClassSubscriptionAction.pending, (state, action) => {
                state.deleteLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteLiveClassSubscriptionAction.fulfilled, (state, action) => {
                state.deleteLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class subscription deleted successfully.');
            })
            .addCase(deleteLiveClassSubscriptionAction.rejected, (state, action) => {
                state.deleteLiveClassSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.deleteLiveClassSubscriptionError = action.payload || action.error.message;
            })
            // Change Status Live class subscription detail action
            .addCase(changeStatusLiveClassSubscriptionAction.pending, (state, action) => {
                state.changeStatusLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusLiveClassSubscriptionAction.fulfilled, (state, action) => {
                state.changeStatusLiveClassSubscriptionLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Live class subscription status updated successfully.');
            })
            .addCase(changeStatusLiveClassSubscriptionAction.rejected, (state, action) => {
                state.changeStatusLiveClassSubscriptionLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusLiveClassSubscriptionError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const subscriptionReducer = subscriptionSlice.reducer;

export const subscriptionActions = subscriptionSlice.actions;
