/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'courseHistory';

const initialState = entityAdapter.getInitialState({
    allCourseHistoryLoading: LoadingStatus.NOT_LOADED,
    allCourseHistory: [],
    allCourseHistoryError: null,

    allCourseHistoryByIdLoading: LoadingStatus.NOT_LOADED,
    allCourseHistoryById: [],
    allCourseHistoryByIdError: null,

    allBookingHistoryLoading: LoadingStatus.NOT_LOADED,
    allBookingHistory: [],
    allBookingHistoryError: null,

    allBookingHistoryByIdLoading: LoadingStatus.NOT_LOADED,
    allBookingHistoryById: [],
    allBookingHistoryByIdError: null,

    addAssignCoursesError: null,
    addAssignCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
   
});

/**
 * All CourseContent Action
 */

export const allCourseHistoryAction = createAsyncThunk(`${SLICE_NAME}/allCourseHistoryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-course-order-history',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All CourseContent Action
 */

 export const allCourseHistoryByIdAction = createAsyncThunk(`${SLICE_NAME}/allCourseHistoryByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-course-order-history-view?id='+ params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});



/**
 * All CourseContent Action
 */

 export const allBookingHistoryAction = createAsyncThunk(`${SLICE_NAME}/allBookingHistoryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-booking-history',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All Booking By Id Action
 */

 export const allBookingHistoryByIdAction = createAsyncThunk(`${SLICE_NAME}/allBookingHistoryByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-booking-history-view?id='+ params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});


export const assignCoursesByAdmin = createAsyncThunk(`${SLICE_NAME}/assignCoursesByAdmin`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/assined-courses-by-admin',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Slice for all reducres
 */
const courseHistorySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetOrderHistoryState: (state, action) => {
            return {
                ...state,
                allCourseHistoryLoading: LoadingStatus.NOT_LOADED,
                allCourseHistory: [],
                allCourseHistoryError: null,

                allCourseHistoryByIdLoading: LoadingStatus.NOT_LOADED,
                allCourseHistoryById: [],
                allCourseHistoryByIdError: null,

                allBookingHistoryLoading: LoadingStatus.NOT_LOADED,
                allBookingHistory: [],
                allBookingHistoryError: null,

                allBookingHistoryByIdLoading: LoadingStatus.NOT_LOADED,
                allBookingHistoryById: [],
                allBookingHistoryByIdError: null,

                addAssignCoursesError: null,
                addAssignCoursesLoadingStatus: LoadingStatus.NOT_LOADED,

            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All CourseContent action
            .addCase(allCourseHistoryAction.pending, (state, action) => {
                state.allCourseHistoryLoading = LoadingStatus.LOADING;
            })
            .addCase(allCourseHistoryAction.fulfilled, (state, action) => {
                state.allCourseHistoryLoading = LoadingStatus.LOADED;
                state.allCourseHistory = action.payload?.data ?? [];
            })
            .addCase(allCourseHistoryAction.rejected, (state, action) => {
                state.allCourseHistoryLoading = LoadingStatus.FAILED;
                state.allCourseContentError = action.payload || action.error.message;
            })
            // All By Id action
            .addCase(allCourseHistoryByIdAction.pending, (state, action) => {
                state.allCourseHistoryByIdLoading = LoadingStatus.LOADING;
            })
            .addCase(allCourseHistoryByIdAction.fulfilled, (state, action) => {
                state.allCourseHistoryByIdLoading = LoadingStatus.LOADED;
                state.allCourseHistoryById = action.payload?.data ?? [];
            })
            .addCase(allCourseHistoryByIdAction.rejected, (state, action) => {
                state.allCourseHistoryByIdLoading = LoadingStatus.FAILED;
                state.allCourseContentByIdError = action.payload || action.error.message;
            })
             // All Booking action
            .addCase(allBookingHistoryAction.pending, (state, action) => {
                state.allBookingHistoryLoading = LoadingStatus.LOADING;
            })
            .addCase(allBookingHistoryAction.fulfilled, (state, action) => {
                state.allBookingHistoryLoading = LoadingStatus.LOADED;
                state.allBookingHistory = action.payload?.data ?? [];
            })
            .addCase(allBookingHistoryAction.rejected, (state, action) => {
                state.allBookingHistoryLoading = LoadingStatus.FAILED;
                state.allbookingContentError = action.payload || action.error.message;
            })
            // All By Id action
            .addCase(allBookingHistoryByIdAction.pending, (state, action) => {
                state.allBookingHistoryByIdLoading = LoadingStatus.LOADING;
            })
            .addCase(allBookingHistoryByIdAction.fulfilled, (state, action) => {
                state.allBookingHistoryByIdLoading = LoadingStatus.LOADED;
                state.allBookingHistoryById = action.payload?.data ?? [];
            })
            .addCase(allBookingHistoryByIdAction.rejected, (state, action) => {
                state.allBookingHistoryByIdLoading = LoadingStatus.FAILED;
                state.allBookingContentByIdError = action.payload || action.error.message;
            })
            .addCase(assignCoursesByAdmin.pending, (state, action) => {
                state.addAssignCoursesError = null;
                state.addAssignCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(assignCoursesByAdmin.fulfilled, (state, action) => {
                state.addAssignCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course assined successfully.');
            })
            .addCase(assignCoursesByAdmin.rejected, (state, action) => {
                state.addAssignCoursesLoadingStatus = LoadingStatus.FAILED;
                state.addAssignCoursesError = action.payload || action.error.message;
            })
    }
});

/*
 * Export reducer for store configuration.
 */
export const courseHistoryReducer = courseHistorySlice.reducer;

export const courseHistoryActions = courseHistorySlice.actions;
