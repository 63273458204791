/* eslint-disable jsx-a11y/media-has-caption */
import { InsertPhotoOutlined } from '@mui/icons-material';
import { Box, Button, FormHelperText, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { CustomActionButton } from 'components/@extended/ActionButton';
import AnimateButton from 'components/@extended/AnimateButton';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addMultipleImageAction ,deleteMultipleImageAction,productMultipleImageByIdAction,ecommerceActions} from 'redux/reducers/ecommerce.slice';
import { IMAGE_SUPPORTED_FORMATS, IMAGE_SUPPORTED_FORMATS_MESSAGE, LoadingStatus } from 'utils/constant';
import * as Yup from 'yup';

//Validation Schema
const validationSchema = Yup.object({
    currentFile: Yup.mixed()
        .required('Image is required.')
        .test('fileType', IMAGE_SUPPORTED_FORMATS_MESSAGE, (value) => IMAGE_SUPPORTED_FORMATS.includes(value?.type))
});

export const ImageUpload = (props) => {
    const { ecommerceReducer, onUploadProductImage, getImagesList, onDeleteProductImage, resetUploadImageState } = props;
    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const params = useParams();
    const navigate = useNavigate();
    // Initial Values for login form
    const initialValues = {
        product_image_id: params.id,
        currentFile: '',
        previewImage: '',
        serverError: null
    };

    useEffect(() => {
        getImagesList({ product_image_id: params?.id });
        resetUploadImageState();
    }, []);

    useEffect(() => {
        if (
            ecommerceReducer.deleteMultipleImageLoadingStatus === LoadingStatus.LOADED ||
            ecommerceReducer.addMultipleImageLoadingStatus === LoadingStatus.LOADED
        ) {
            getImagesList({ product_image_id: params?.id });
            resetUploadImageState();
        }
    }, [ecommerceReducer]);

    // destructure formik values from formik hook
    const { handleBlur, handleChange, handleSubmit, values, errors, setFieldValue, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors, resetForm }) => {
            try {
                setButtonDisabled(true);
                const formData = new FormData();
                formData.append('image', values.currentFile);
                formData.append('product_image_id', values.product_image_id);
                onUploadProductImage(formData).then((res) => {
                    setButtonDisabled(false);
                    resetForm();
                });
            } catch (err) {
                setErrors({ serverError: err.message });
                setButtonDisabled(false);
            }
        }
    });

    const selectFile = (event) => {
        const file = event.target.files[0];
        setFieldValue('currentFile', file);
        setFieldValue('previewImage', URL.createObjectURL(file));
    };
    if (ecommerceReducer.productMultipleImageListLoadingStatus === LoadingStatus.LOADING) {
        return <LinearProgress />;
    }

    return (
        <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between" pr={5}>
                <Grid item>
                    <Typography variant="h5">Add Product Image</Typography>
                </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
                <Box sx={{ py: 5, px: 2 }}>
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid container mb={2} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Stack spacing={1}>
                                        {values.previewImage ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 233,
                                                    width: 350,
                                                    maxHeight: { xs: 233, md: 167 },
                                                    maxWidth: { xs: 350, md: 250 },
                                                    border: '1px solid grey'
                                                }}
                                                alt="ImagePreview"
                                                src={values.previewImage}
                                            />
                                        ) : (
                                            <InsertPhotoOutlined
                                                sx={{
                                                    height: 233,
                                                    width: 350,
                                                    maxHeight: { xs: 233, md: 167 },
                                                    maxWidth: { xs: 350, md: 250 },
                                                    border: '1px solid grey'
                                                }}
                                            />
                                        )}
                                        <Grid item xs={12} sm={6}>
                                            <Stack style={{ width: 200 }} spacing={1}>
                                                <input
                                                    id="btn-upload"
                                                    name="btn-upload"
                                                    // style={{ display: 'none' }}
                                                    type="file"
                                                    // accept="image/*"
                                                    accept="image/jpg,image/png,image/gif,image/jpeg,image/svg"
                                                    onChange={selectFile}
                                                    onBlur={handleBlur}
                                                />
                                                {touched.currentFile && errors.currentFile && (
                                                    <FormHelperText error id="standard-weight-helper-text-currentFile-login">
                                                        {errors.currentFile}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {errors.submit && (
                                <Grid item xs={12} sm={6}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                            )}
                            <Grid container spacing={2} ml={-2} mt={1}>
                                <Grid item>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={buttonDisabled}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Upload
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                                <Grid item>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={buttonDisabled}
                                            fullWidth
                                            size="large"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                <Box sx={{ py: 5, px: 2 }}>
                    <Grid container mb={2} spacing={2}>
                        {(ecommerceReducer?.productMultipleImageList ?? []).map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} md={4}>
                                    <Stack
                                        style={{
                                            position: 'relative',
                                            alignItems: 'center',
                                            height: 300,
                                            width: 300
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: 5,
                                                top: 5,
                                                borderRadius: 15,
                                                backgroundColor: 'lightgrey',
                                                zIndex: 100
                                            }}
                                        >
                                            <CustomActionButton
                                                type={'Delete'}
                                                onClick={() => {
                                                    onDeleteProductImage({ id: item?.id });
                                                }}
                                            />
                                        </div>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 300,
                                                width: 300,
                                                position: 'relative',
                                                border: '1px solid grey'
                                            }}
                                            alt="ImagePreview"
                                            src={item?.image}
                                        />
                                    </Stack>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </MainCard>
        </Grid>
    );
};

ImageUpload.propTypes = {
    ecommerceReducer: PropTypes.object,
    onUploadProductImage: PropTypes.func,
    onDeleteProductImage: PropTypes.func,
    getImagesList: PropTypes.func
};
const mapStateToProps = (state) => {
    return { ecommerceReducer: state.ecommerceReducer };
};

const mapDispatchToProps = (dispatch) => ({
    onUploadProductImage: (val) => dispatch(addMultipleImageAction(val)),
    onDeleteProductImage: (val) => dispatch(deleteMultipleImageAction(val)),
    getImagesList: (val) => dispatch(productMultipleImageByIdAction(val)),
    resetUploadImageState: () => dispatch(ecommerceActions.resetUploadImageState())
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
