import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, List, ListItemButton, ListItemIcon, ListItemText, Typography, Collapse } from '@mui/material';
import { drawerTextColor } from 'config';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import NavItem from './NavItem';
import NavGroup from './NavGroup';
import { CustomWidthTooltip } from 'components/@extended/CustomToolTip';
// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavCollapse = ({ item, level, staticReducer, activeItem, ...props }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { drawerOpen, openItem } = staticReducer;

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        activeItem({ openItem: [id] });
        setOpen(!open);
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1.25rem' : '1.5rem' }} /> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            activeItem({ openItem: [item.id] });
        }
        // eslint-disable-next-line
    }, []);

    const textColor = drawerTextColor;
    const iconSelectedColor = 'primary.main';

    return (
        <List sx={{ py: 0 }}>
            <ListItemButton
                {...listItemProps}
                disabled={item.disabled}
                onClick={() => itemHandler(item.id)}
                selected={isSelected}
                sx={{
                    zIndex: 1201,
                    pl: drawerOpen ? `${level * 28}px` : 1.5,
                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                    ...(drawerOpen && {
                        '&:hover': {
                            // bgcolor: 'primary.lighter'
                            bgcolor: '#ffffff50'
                        },
                        '&.Mui-selected': {
                            bgcolor: 'primary.lighter',
                            borderRight: `2px solid ${theme.palette.primary.main}`,
                            color: iconSelectedColor,
                            '&:hover': {
                                color: iconSelectedColor,
                                bgcolor: 'primary.lighter'
                            }
                        }
                    }),
                    ...(!drawerOpen && {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        '&.Mui-selected': {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            bgcolor: 'transparent'
                        }
                    })
                }}
            >
                {itemIcon && (
                    <CustomWidthTooltip title={!drawerOpen ? item.title : ''}>
                        <ListItemIcon
                            sx={{
                                minWidth: 28,
                                color: isSelected ? iconSelectedColor : textColor,
                                ...(!drawerOpen && {
                                    borderRadius: 1.5,
                                    width: 36,
                                    height: 36,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        // bgcolor: 'secondary.lighter',
                                        bgcolor: '#ffffff50'
                                    }
                                }),
                                ...(!drawerOpen &&
                                    isSelected && {
                                        bgcolor: 'primary.lighter',
                                        '&:hover': {
                                            bgcolor: 'primary.lighter'
                                        }
                                    })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    </CustomWidthTooltip>
                )}
                {(drawerOpen || (!drawerOpen && level > 2)) && (
                    <ListItemText
                        primary={
                            <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor, fontWeight: 500 }}>
                                {item.title}
                            </Typography>
                        }
                    />
                )}
                {(drawerOpen || (!drawerOpen && level > 2)) && item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    />
                )}
                {drawerOpen && itemIcon && (
                    <ListItemIcon
                        sx={{
                            minWidth: 28,
                            color: isSelected ? iconSelectedColor : textColor,
                            ...(!drawerOpen && {
                                borderRadius: 1.5,
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    // bgcolor: 'secondary.lighter',
                                    bgcolor: '#ffffff50'
                                }
                            }),
                            ...(!drawerOpen &&
                                isSelected && {
                                    bgcolor: 'primary.lighter',
                                    '&:hover': {
                                        bgcolor: 'primary.lighter'
                                    }
                                })
                        }}
                    >
                        {open ? <UpOutlined /> : <DownOutlined />}
                    </ListItemIcon>
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List sx={{ mb: drawerOpen ? 1 : 0, py: 0, zIndex: 0 }}>
                    {item.children.map((menuItem) => (
                        <NavItem key={menuItem.id} item={menuItem} level={2} staticReducer={staticReducer} activeItem={activeItem} />
                    ))}
                </List>
            </Collapse>
        </List>
    );
};

NavCollapse.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    staticReducer: PropTypes.object,
    activeItem: PropTypes.func
};

export default NavCollapse;
