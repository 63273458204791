/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'productDeliver';

const initialState = entityAdapter.getInitialState({

    allProductDeliverLoadingStatus: LoadingStatus.NOT_LOADED,
    productDeliverByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addProductDeliverLoadingStatus: LoadingStatus.NOT_LOADED,
    editProductDeliverLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteProductDeliverLoadingStatus: LoadingStatus.NOT_LOADED,
    allProductDeliver: [],
    productDeliverById: null,
    allProductDeliverError: null,
    productDeliverByIdError: null,
    addProductDeliverError: null,
    editProductDeliverError: null,
    deleteProductDeliverError: null,
});


/**
 * All ProductDeliver Action
 */

export const allProductDeliverAction = createAsyncThunk(`${SLICE_NAME}/allProductDeliverAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/product-delivers',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  ProductDeliver By Id Action
 */

export const productDeliverByIdAction = createAsyncThunk(`${SLICE_NAME}/productDeliverByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/product-deliver-view',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add ProductDeliver Action
 */

export const addProductDeliverAction = createAsyncThunk(`${SLICE_NAME}/addProductDeliverAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/product-deliver',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit ProductDeliver Action
 */

export const editProductDeliverAction = createAsyncThunk(`${SLICE_NAME}/editProductDeliverAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/product-deliver-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete ProductDeliver Action
 */

export const deleteProductDeliverAction = createAsyncThunk(`${SLICE_NAME}/deleteProductDeliverAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/product-deliver-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const productDeliverSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetProductDeliverState: (state, action) => {
            return {
                ...state,
                addProductDeliverLoadingStatus: LoadingStatus.NOT_LOADED,
                editProductDeliverLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteProductDeliverLoadingStatus: LoadingStatus.NOT_LOADED,
                addProductDeliverError: null,
                editProductDeliverError: null,
                deleteProductDeliverError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder

            // All ProductDeliver action
            .addCase(allProductDeliverAction.pending, (state, action) => {
                state.allProductDeliverError = null;
                state.allProductDeliverLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allProductDeliverAction.fulfilled, (state, action) => {
                state.allProductDeliverLoadingStatus = LoadingStatus.LOADED;
                state.allProductDeliver = action.payload?.data;
            })
            .addCase(allProductDeliverAction.rejected, (state, action) => {
                state.allProductDeliverLoadingStatus = LoadingStatus.FAILED;
                state.allProductDeliverError = action.payload || action.error.message;
            })
            // ProductDeliver by id action
            .addCase(productDeliverByIdAction.pending, (state, action) => {
                state.productDeliverByIdError = null;
                state.productDeliverByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(productDeliverByIdAction.fulfilled, (state, action) => {
                state.productDeliverByIdLoadingStatus = LoadingStatus.LOADED;
                state.productDeliverById = action.payload?.data?.deliver_data;
            })
            .addCase(productDeliverByIdAction.rejected, (state, action) => {
                state.productDeliverByIdLoadingStatus = LoadingStatus.FAILED;
                state.productDeliverByIdError = action.payload || action.error.message;
            })
            // Add ProductDeliver action
            .addCase(addProductDeliverAction.pending, (state, action) => {
                state.addProductDeliverError = null;
                state.addProductDeliverLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addProductDeliverAction.fulfilled, (state, action) => {
                state.addProductDeliverLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product Deliver added successfully.');
            })
            .addCase(addProductDeliverAction.rejected, (state, action) => {
                state.addProductDeliverLoadingStatus = LoadingStatus.FAILED;
                state.addProductDeliverError = action.payload || action.error.message;
            })
            // Edit ProductDeliver action
            .addCase(editProductDeliverAction.pending, (state, action) => {
                state.editProductDeliverError = null;
                state.editProductDeliverLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editProductDeliverAction.fulfilled, (state, action) => {
                state.editProductDeliverLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product Deliver updated successfully.');
            })
            .addCase(editProductDeliverAction.rejected, (state, action) => {
                state.editProductDeliverLoadingStatus = LoadingStatus.FAILED;
                state.editProductDeliverError = action.payload || action.error.message;
            })
            // Delete ProductDeliver action
            .addCase(deleteProductDeliverAction.pending, (state, action) => {
                state.deleteProductDeliverError = null;
                state.deleteProductDeliverLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteProductDeliverAction.fulfilled, (state, action) => {
                state.deleteProductDeliverLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product Deliver deleted successfully.');
            })
            .addCase(deleteProductDeliverAction.rejected, (state, action) => {
                state.deleteProductDeliverLoadingStatus = LoadingStatus.FAILED;
                state.deleteProductDeliverError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const productDeliverReducer = productDeliverSlice.reducer;

export const productDeliverActions = productDeliverSlice.actions;
