/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'eBooks';

const initialState = entityAdapter.getInitialState({
    allEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
    eBooksByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
    editEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
    allEBooks: [],
    eBooksById: null,
    allEBooksError: null,
    addEBooksError: null,
    editEBooksError: null,
    deleteEBooksError: null,
    changeStatusEBooksError: null,
    eBooksByIdError: null,



});

/**
 * All EBooks Action
 */

export const allEBooksAction = createAsyncThunk(`${SLICE_NAME}/allEBooksAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-e-books',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add EBooks Action
 */

export const addEBooksAction = createAsyncThunk(`${SLICE_NAME}/addEBooksAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/e-books-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  EBooks by ID Action
 */

export const eBooksByIdAction = createAsyncThunk(`${SLICE_NAME}/eBooksByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-e-books/'+ params.id,
            method: 'GET',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit EBooks Action
 */

export const editEBooksAction = createAsyncThunk(`${SLICE_NAME}/editEBooksAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/e-books-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete EBooks Action
 */

export const deleteEBooksAction = createAsyncThunk(`${SLICE_NAME}/deleteEBooksAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/e-books-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status EBooks Action
 */

export const changeStatusEBooksAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusEBooksAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/e-books-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const eBooksSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetEBooksState: (state, action) => {
            return {
                ...state,
                eBooksByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
                editEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusEBooksLoadingStatus: LoadingStatus.NOT_LOADED,
                eBooksById: null,
                eBooksByIdError: null,
                addEBooksError: null,
                editEBooksError: null,
                deleteEBooksError: null,
                changeStatusEBooksError: null,

               
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All EBooks action
            .addCase(allEBooksAction.pending, (state, action) => {
                state.allEBooksLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allEBooksAction.fulfilled, (state, action) => {
                state.allEBooksLoadingStatus = LoadingStatus.LOADED;
                state.allEBooks = action.payload?.data ?? [];
            })
            .addCase(allEBooksAction.rejected, (state, action) => {
                state.allEBooksLoadingStatus = LoadingStatus.FAILED;
                state.allEBooksError = action.payload || action.error.message;
            })
            .addCase(eBooksByIdAction.pending, (state, action) => {
                state.eBooksByIdError = null;
                state.eBooksByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(eBooksByIdAction.fulfilled, (state, action) => {
                state.eBooksByIdLoadingStatus = LoadingStatus.LOADED;
                state.eBooksById = action.payload?.data;
            })
            .addCase(eBooksByIdAction.rejected, (state, action) => {
                state.eBooksByIdLoadingStatus = LoadingStatus.FAILED;
                state.eBooksByIdError = action.payload || action.error.message;
            })

            // Add EBooks action
            .addCase(addEBooksAction.pending, (state, action) => {
                state.addEBooksLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addEBooksAction.fulfilled, (state, action) => {
                state.addEBooksLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('EBooks added successfully.');
            })
            .addCase(addEBooksAction.rejected, (state, action) => {
                state.addEBooksLoadingStatus = LoadingStatus.FAILED;
                state.addEBooksError = action.payload || action.error.message;
            })
            // Edit EBooks action
            .addCase(editEBooksAction.pending, (state, action) => {
                state.editEBooksLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editEBooksAction.fulfilled, (state, action) => {
                state.editEBooksLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('EBooks updated successfully.');
            })
            .addCase(editEBooksAction.rejected, (state, action) => {
                state.editEBooksLoadingStatus = LoadingStatus.FAILED;
                state.editEBooksError = action.payload || action.error.message;
            })
            // Delete EBooks action
            .addCase(deleteEBooksAction.pending, (state, action) => {
                state.deleteEBooksLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteEBooksAction.fulfilled, (state, action) => {
                state.deleteEBooksLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('EBooks deleted successfully.');
            })
            .addCase(deleteEBooksAction.rejected, (state, action) => {
                state.deleteEBooksLoadingStatus = LoadingStatus.FAILED;
                state.deleteEBooksError = action.payload || action.error.message;
            })
            // Change Status EBooks action
            .addCase(changeStatusEBooksAction.pending, (state, action) => {
                state.changeStatusEBooksLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusEBooksAction.fulfilled, (state, action) => {
                state.changeStatusEBooksLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('EBooks status updated successfully.');
            })
            .addCase(changeStatusEBooksAction.rejected, (state, action) => {
                state.changeStatusEBooksLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusEBooksError = action.payload || action.error.message;
            })
            
            
    }
});

/*
 * Export reducer for store configuration.
 */
export const eBooksReducer = eBooksSlice.reducer;

export const eBooksActions = eBooksSlice.actions;
