import React, { useState, useEffect } from 'react';
import {
    Grid,
    Stack,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    Button,
    LinearProgress,
    Box,
    Typography,
    InputAdornment,
    List,
    ListItem
} from '@mui/material';
import MainCard from 'components/MainCard';
import AnimateButton from 'components/@extended/AnimateButton';
import * as Yup from 'yup';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoadingStatus, IMAGE_SUPPORTED_FORMATS, IMAGE_SUPPORTED_FORMATS_MESSAGE } from 'utils/constant';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showProductImageByIdAction, updateProductImageAction, ecommerceActions } from 'redux/reducers/ecommerce.slice';
import './style.css';
import { InsertPhotoOutlined } from '@mui/icons-material';

// Validation Schema
const validationSchema = Yup.object().shape({
    currentFile: Yup.mixed()
        .test('fileType', 'Unsupported file format', (value) => {
            if (!value) return true;
            return IMAGE_SUPPORTED_FORMATS.includes(value.type);
        }),
    color: Yup.string().required('Color is required.'),
    color_name: Yup.string().required('Color name is required.').trim().min(1, 'Color name is required.'),
    amount: Yup.number().required('Price is required.').typeError('Please enter a valid number.').min(0.01, 'Price must be greater than 0.'),
    discount: Yup.number()
        .typeError('Please enter a valid number.')
        .integer('Discount should not accept decimal number.')
        .min(0, 'Discount must be positive number.')
        .max(100, 'Discount must be less than or equal to 100.'),
    available_quantity: Yup.number()
        .typeError('Please enter a valid number.')
        .integer('Quantity should not accept decimal number.')
        .min(1, 'Quantity must be greater than 0.')
});

// Initial Values for login form

export const EditProductImage = (props) => {
    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const routeParams = useParams();
    const [row, setRow] = useState(false);
    const [size, setSize] = useState('');
    const [price, setPrice] = useState('');
    const [size_discount, setSizeDiscount] = useState('');
    const [payable_amount, setPayableAmount] = useState('');
    const [quantity, setQuantity] = useState('');
    const [item_weight, setItemWeight] = useState('');
    const [itemsList, setItemsList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    const { ecommerceReducer, updateProductImageClick, getProductImagesByID, resetUploadImageState } = props;

    useEffect(() => {
        resetUploadImageState();
        getProductImagesByID({ id: routeParams.id });
    }, []);

    const initialValues = {
        id: ecommerceReducer.productImageById?.id ?? '',
        color: ecommerceReducer.productImageById?.color ?? '',
        product_id: ecommerceReducer.productImageById?.product_id ?? '',
        color_name: ecommerceReducer.productImageById?.color_name ?? '',
        amount: ecommerceReducer.productImageById?.amount ?? '',
        discount: ecommerceReducer.productImageById?.discount ?? '',
        discounted_price: ecommerceReducer.productImageById?.discounted_price ?? '',
        available_quantity: ecommerceReducer.productImageById?.available_quantity ?? '',
        previewImage: ecommerceReducer.productImageById?.image ?? '',
        productImageSize: ecommerceReducer.productImageById?.product_image_size ?? null,
        image: '',
        serverError: null
    };
    const addRow = () => {
        setRow(true);
    };

    const handleChangeNew = (event) => {
        const { name, value } = event.target;

        // Validate if the entered value is a valid positive number or an empty string
        const isValidPositiveNumber = (input) => {
            return input === '' || /^\d*\.?\d*$/.test(input);
        };

        // Validate if the entered value is alphanumeric
        const isValidAlphanumeric = (input) => {
            return /^[a-zA-Z0-9 ]+$/.test(input);
        };

        // Update state variables
        if (name === 'size') {
            if (isValidAlphanumeric(value)) {
                setSize(value.toUpperCase()); // Convert to uppercase and set the value
            }
        } else if (name === 'quantity' && isValidPositiveNumber(value)) {
            setQuantity(value);
        } else if (name === 'price' && isValidPositiveNumber(value)) {
            setPrice(value);
        } else if (name === 'size_discount' && isValidPositiveNumber(value)) {
            setSizeDiscount(value);
        } else if (name === 'payable_amount' && isValidPositiveNumber(value)) {
            setPayableAmount(value);
        } else if (name === 'item_weight' && isValidPositiveNumber(value)) {
            setItemWeight(value);
        }

        // Check if at least one required field has a valid value
        if (size || quantity || price || size_discount || payable_amount || item_weight) {
            setIsDisabled(false); // Enable the button if at least one field has a valid value
        } else {
            setIsDisabled(true); // Disable the button if all fields are empty or invalid
        }
    };

    const handleRemoveItem = (index) => {
        const updatedList = [...itemsList];
        updatedList.splice(index, 1);
        setItemsList(updatedList);
    };

    // Modify the getFieldsValues function to prevent adding empty values to itemsList
    const getFieldsValues = () => {
        // Check if at least one required field has a valid value before adding to itemsList
        if (size && quantity && price && item_weight) {
            // Create a new item object
            const newItem = {
                size: size,
                quantity: quantity,
                price: price,
                size_discount: size_discount,
                payable_amount: payable_amount,
                item_weight: item_weight
            };
            // Add the new item to the itemsList array
            setItemsList([...itemsList, newItem]);

            // Clear input fields after adding the item
            setSize('');
            setQuantity('');
            setPrice('');
            setSizeDiscount('');
            setPayableAmount('');
            setItemWeight('');
            setIsDisabled(true); // Disable the button after adding the item
        }
    };

    const navigate = useNavigate();

    const { handleBlur, handleChange, handleSubmit, setFieldValue, values, errors, touched, resetForm } = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors }) => {
            try {
                setButtonDisabled(true);
                const formData = new FormData();
                if (values.image) {
                    formData.append('image', values.image);
                }
                formData.append('id', values.id);
                formData.append('amount', values.amount);
                formData.append('discount', values.discount);
                formData.append('discounted_price', values.discounted_price);
                formData.append('available_quantity', values.available_quantity);
                formData.append('color', values.color);
                formData.append('color_name', values.color_name);
                formData.append('size_item_list', JSON.stringify(itemsList));
                await updateProductImageClick(formData);
                setButtonDisabled(true);
                resetForm(); // Reset form after successful submission
                // Reset other state variables if needed
                // setIsDisabled(true); // If needed
            } catch (err) {
                console.error('Error occurred during form submission:', err);
                setErrors({ serverError: err.message });
                setButtonDisabled(false);
            }
        }
    });

    useEffect(() => {
        if (values.productImageSize) {
            setRow(true);
            setItemsList(values.productImageSize);
            console.log(JSON.stringify(itemsList), 'Pritee');
        }
        if (values.discount && values.amount) {
            const discountPrice = values.amount * (values.discount / 100);
            const payable = discountPrice > 0 ? (values.amount - discountPrice).toFixed(2) : values.amount;
            setFieldValue('discounted_price', payable);
        }
    }, [values.discount, values.amount]);
    // Size
    useEffect(() => {
        if (size_discount && price) {
            const disPrice = price * (size_discount / 100);
            const payablePrice = disPrice > 0 ? (price - disPrice).toFixed(2) : price;
            setPayableAmount(payablePrice);
        }
    }, [size_discount, price]);

    const selectFile = (event) => {
        const file = event.target.files[0];
        setFieldValue('image', file);
        setFieldValue('previewImage', URL.createObjectURL(file));
    };
    if (ecommerceReducer.updateProductLoadingStatus === LoadingStatus.LOADED) {
        return <Navigate to={`/product/manage_product/${values.product_id}/image-list`} />;
    }
    if (ecommerceReducer.productImageByIdLoadingStatus === LoadingStatus.LOADING) {
        return <LinearProgress />;
    }

    return (
        <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between" pr={5}>
                <Grid item>
                    <Typography variant="h5">Update Product Image</Typography>
                </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
                <Box sx={{ py: 5, px: 2 }}>
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid container mb={2} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    
                                    <Stack mb={2} spacing={1}>
                                        <InputLabel htmlFor="amount">Product Amount</InputLabel>
                                        <OutlinedInput
                                            id="amount"
                                            type="amount"
                                            value={values.amount}
                                            name="amount"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder="Enter amount "
                                            fullWidth
                                            autoComplete="off"
                                            error={Boolean(touched.amount && errors.amount)}
                                        />
                                        {touched.amount && errors.amount && (
                                            <FormHelperText error id="standard-weight-helper-text-amount">
                                                {errors.amount}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <InputLabel htmlFor="discount">Discount</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            error={Boolean(touched.discount && errors.discount)}
                                            id="discount"
                                            type="text"
                                            value={values.discount}
                                            name="discount"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            placeholder="Enter discount"
                                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                        />
                                        {touched.discount && errors.discount && (
                                            <FormHelperText error id="standard-weight-helper-text-discount">
                                                {errors.discount}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <InputLabel htmlFor="discounted_price">Payable Amount</InputLabel>
                                        <OutlinedInput
                                            disabled={true}
                                            fullWidth
                                            error={Boolean(touched.discounted_price && errors.discounted_price)}
                                            id="discount"
                                            type="text"
                                            value={values.discounted_price}
                                            name="discounted_price"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            placeholder="Enter payable amount"
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#000000'
                                                }
                                            }}
                                        />
                                        {touched.discounted_price && errors.discounted_price && (
                                            <FormHelperText error id="standard-weight-helper-text-discounted_price">
                                                {errors.discounted_price}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <InputLabel htmlFor="available_quantity">Total Quantity</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            error={Boolean(touched.available_quantity && errors.available_quantity)}
                                            id="available_quantity"
                                            type="text"
                                            value={values.available_quantity}
                                            name="available_quantity"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            placeholder="Enter available quantity"
                                        />
                                        {touched.available_quantity && errors.available_quantity && (
                                            <FormHelperText error id="standard-weight-helper-text-available_quantity">
                                                {errors.available_quantity}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <InputLabel htmlFor="color"> Choose Color </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            error={Boolean(touched.color && errors.color)}
                                            id="color"
                                            type="color"
                                            value={values.color}
                                            name="color"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {touched.color && errors.color && (
                                            <FormHelperText error id="standard-weight-helper-text-color">
                                                {errors.color}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <InputLabel htmlFor="color_name">Color Name</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            error={Boolean(touched.color_name && errors.color_name)}
                                            id="color_name"
                                            type="text"
                                            value={values.color_name}
                                            name="color_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            placeholder="Enter color name"
                                        />
                                        {touched.color_name && errors.color_name && (
                                            <FormHelperText error id="standard-weight-helper-text-color_name">
                                                {errors.color_name}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Stack spacing={1} alignItems="center">
                                        {values.previewImage ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 233,
                                                    width: 350,
                                                    maxHeight: { xs: 233, md: 167 },
                                                    maxWidth: { xs: 350, md: 250 },
                                                    border: '1px solid grey'
                                                }}
                                                alt="ImagePreview"
                                                src={values.previewImage}
                                            />
                                        ) : (
                                            <InsertPhotoOutlined
                                                sx={{
                                                    height: 233,
                                                    width: 350,
                                                    maxHeight: { xs: 233, md: 167 },
                                                    maxWidth: { xs: 350, md: 250 },
                                                    border: '1px solid grey'
                                                }}
                                            />
                                        )}
                                        <Grid item xs={12} sm={6}>
                                            <Stack style={{ width: 200 }} spacing={1}>
                                                <input
                                                    id="btn-upload"
                                                    name="btn-upload"
                                                    type="file"
                                                    accept="image/jpg,image/png,image/gif,image/jpeg,image/svg"
                                                    onChange={selectFile}
                                                    onBlur={handleBlur}
                                                />
                                                {touched.image && errors.image && (
                                                    <FormHelperText error id="standard-weight-helper-text-image">
                                                        {errors.image}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={addRow}
                                    >
                                        Add Size
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {row && (
                                <Grid container my={2} mx={1} spacing={2}>
                                    <Box>
                                        <form autoComplete="off" className="sqp_form" onSubmit={(e) => e.preventDefault()}>
                                            <Stack direction="row">
                                                <Stack>
                                                    <InputLabel htmlFor="size">Size</InputLabel>
                                                    <OutlinedInput
                                                        id="size"
                                                        type="text"
                                                        value={size}
                                                        name="size"
                                                        onBlur={handleBlur}
                                                        onChange={handleChangeNew}
                                                        placeholder="Size"
                                                        fullWidth
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <InputLabel htmlFor="quantity">Quantity</InputLabel>
                                                    <OutlinedInput
                                                        id="quantity"
                                                        type="text"
                                                        value={quantity}
                                                        name="quantity"
                                                        onBlur={handleBlur}
                                                        onChange={handleChangeNew}
                                                        placeholder="Quantity"
                                                        fullWidth
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <InputLabel htmlFor="price">Price</InputLabel>
                                                    <OutlinedInput
                                                        id="price"
                                                        type="text"
                                                        value={price}
                                                        name="price"
                                                        onBlur={handleBlur}
                                                        onChange={handleChangeNew}
                                                        placeholder="Price"
                                                        fullWidth
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <InputLabel htmlFor="size_discount">Discount(%)</InputLabel>
                                                    <OutlinedInput
                                                        id="size_discount"
                                                        type="text"
                                                        value={size_discount}
                                                        name="size_discount"
                                                        onBlur={handleBlur}
                                                        onChange={handleChangeNew}
                                                        placeholder="Discount"
                                                        fullWidth
                                                        // error={Boolean(touched.name && errors.name)}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <InputLabel htmlFor="payable_amount">Payable Price</InputLabel>
                                                    <OutlinedInput
                                                        disabled={true}
                                                        id="payable_amount"
                                                        type="text"
                                                        value={payable_amount}
                                                        name="payable_amount"
                                                        onBlur={handleBlur}
                                                        onChange={handleChangeNew}
                                                        placeholder="Payable Price"
                                                        fullWidth
                                                        // error={Boolean(touched.name && errors.name)}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <InputLabel htmlFor="item_weight">Item Weight</InputLabel>
                                                    <OutlinedInput
                                                        id="item_weight"
                                                        type="text"
                                                        value={item_weight}
                                                        name="item_weight"
                                                        onBlur={handleBlur}
                                                        onChange={handleChangeNew}
                                                        placeholder="Item Weight"
                                                        fullWidth
                                                        // error={Boolean(touched.name && errors.name)}
                                                    />
                                                </Stack>
                                                <Stack>
                                                    <InputLabel htmlFor="size">&nbsp;</InputLabel>
                                                    <Button
                                                        disableElevation
                                                        fullWidth
                                                        size="large"
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        className="addRows"
                                                        disabled={isDisabled}
                                                        onClick={getFieldsValues}
                                                    >
                                                        +
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                            <Stack>
                                                {itemsList.map((item, index) => (
                                                    <div className="form-values" key={index}>
                                                        <List className="list">
                                                            <ListItem>
                                                                <span className="value">Size: {item.size}</span>
                                                                <span className="value">Quantity: {item.quantity}</span>
                                                                <span className="value">Price: {item.price}</span>
                                                                <span className="value">Discount: {item.size_discount}%</span>
                                                                <span className="value">Payable Price: {item.payable_amount}</span>
                                                                <span className="value">Item Weight: {item.item_weight}</span>
                                                                <Button onClick={() => handleRemoveItem(index)}>Remove</Button>
                                                            </ListItem>
                                                        </List>
                                                    </div>
                                                ))}
                                            </Stack>
                                        </form>
                                    </Box>
                                </Grid>
                            )}
                            <Grid container spacing={2} ml={-2} mt={1}>
                                <Grid item>
                                    <AnimateButton>
                                    <form noValidate onSubmit={handleSubmit}></form>
                                        <Button
                                            disableElevation
                                            disabled={false}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Update
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                                <Grid item>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={buttonDisabled}
                                            fullWidth
                                            size="large"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </MainCard>
        </Grid>
    );
};

EditProductImage.propTypes = {
    ecommerceReducer: PropTypes.object,
    updateProductImageClick: PropTypes.func,
    getProductImagesByID: PropTypes.func
};

const mapStateToProps = (state) => {
    return { ecommerceReducer: state.ecommerceReducer };
};

const mapDispatchToProps = (dispatch) => ({
    updateProductImageClick: (val) => dispatch(updateProductImageAction(val)),
    getProductImagesByID: (val) => dispatch(showProductImageByIdAction(val)),
    resetUploadImageState: () => dispatch(ecommerceActions.resetUploadImageState())
});
export default connect(mapStateToProps, mapDispatchToProps)(EditProductImage);
