// third-party
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

// project import
import rootReducers from './reducers';

const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
export { store };
