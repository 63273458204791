import axios from 'axios';
import { ApiPath } from './constant';
// import store from 'redux/store';
import LocalStorage from './localStorage';
import ToastMessage from 'utils/ToastMessage';
// import { store } from '../redux/store';

const axiosRequest = axios.create({
    baseURL: ApiPath.BASE_URL
});

//All response from axios
axiosRequest.interceptors.response.use(
    (response) => {
        console.log('Response=======>', response);
        return response.data;
    },
    (error) => {
        console.log('Error=======>', error.response);
        if (error?.response) {
            if (error.response?.status === 401) {
                // store.dispatch({ type: 'USER_LOGOUT' });
                LocalStorage.clearAllItem();
            } else {
                console.log('===>', error.response.data.message);
                ToastMessage.error(error.response.data.message);
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        }
        return Promise.reject(error);
    }
);

// All request from axios
axiosRequest.interceptors.request.use(
    async (config) => {
        const token = await LocalStorage.getItem(LocalStorage.keys.TOKEN, '');
        console.log('Token Interceptor', token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosRequest;
