/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'sizeChart';

const initialState = entityAdapter.getInitialState({
    // allProductLoadingStatus: LoadingStatus.NOT_LOADED,
    allSizeChartLoadingStatus: LoadingStatus.NOT_LOADED,
    sizeChartByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addSizeChartLoadingStatus: LoadingStatus.NOT_LOADED,
    editSizeChartLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteSizeChartLoadingStatus: LoadingStatus.NOT_LOADED,
    allSizeChart: [],
    SizeChartById: null,
    allSizeChartError: null,
    sizeChartByIdError: null,
    addSizeChartError: null,
    editSizeChartError: null,
    deleteSizeChartError: null,


});

/**
 * All SizeChart Action
 */

export const allSizeChartAction = createAsyncThunk(`${SLICE_NAME}/allSizeChartAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/size-charts',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  SizeChart By Id Action
 */

export const sizeChartByIdAction = createAsyncThunk(`${SLICE_NAME}/sizeChartByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/size-chart-view',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add SizeChart Action
 */
export const addSizeChartAction = createAsyncThunk(`${SLICE_NAME}/addSizeChartAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/size-chart',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit SizeChart Action
 */

export const editSizeChartAction = createAsyncThunk(`${SLICE_NAME}/editSizeChartAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/size-chart-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete SizeChart Action
 */
export const deleteSizeChartAction = createAsyncThunk(`${SLICE_NAME}/deleteSizeChartAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/size-chart-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const sizeChartSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetSizeChartState: (state, action) => {
            return {
                ...state,
                addSizeChartLoadingStatus: LoadingStatus.NOT_LOADED,
                editSizeChartLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteSizeChartLoadingStatus: LoadingStatus.NOT_LOADED,
                addSizeChartError: null,
                editSizeChartError: null,
                deleteSizeChartError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All SizeChart action
            .addCase(allSizeChartAction.pending, (state, action) => {
                state.allSizeChartError = null;
                state.allSizeChartLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allSizeChartAction.fulfilled, (state, action) => {
                state.allSizeChartLoadingStatus = LoadingStatus.LOADED;
                state.allSizeChart = action.payload?.data;
            })
            .addCase(allSizeChartAction.rejected, (state, action) => {
                state.allSizeChartLoadingStatus = LoadingStatus.FAILED;
                state.allSizeChartError = action.payload || action.error.message;
            })
            //  SizeChart by id action
            .addCase(sizeChartByIdAction.pending, (state, action) => {
                state.sizeChartByIdError = null;
                state.sizeChartByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(sizeChartByIdAction.fulfilled, (state, action) => {
                state.sizeChartByIdLoadingStatus = LoadingStatus.LOADED;
                state.sizeChartById = action.payload?.data?.size_data;
            })
            .addCase(sizeChartByIdAction.rejected, (state, action) => {
                state.sizeChartByIdLoadingStatus = LoadingStatus.FAILED;
                state.sizeChartByIdError = action.payload || action.error.message;
            })
            // Add EventGallery action
            .addCase(addSizeChartAction.pending, (state, action) => {
                state.addSizeChartError = null;
                state.addSizeChartLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addSizeChartAction.fulfilled, (state, action) => {
                state.addSizeChartLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Size Chart added successfully.');
            })
            .addCase(addSizeChartAction.rejected, (state, action) => {
                state.addSizeChartLoadingStatus = LoadingStatus.FAILED;
                state.addSizeChartError = action.payload || action.error.message;
            })
            // Edit SizeChart action
            .addCase(editSizeChartAction.pending, (state, action) => {
                state.editSizeChartError = null;
                state.editSizeChartLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editSizeChartAction.fulfilled, (state, action) => {
                state.editSizeChartLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Size Chart updated successfully.');
            })
            .addCase(editSizeChartAction.rejected, (state, action) => {
                state.editSizeChartLoadingStatus = LoadingStatus.FAILED;
                state.editSizeChartError = action.payload || action.error.message;
            })
            // Delete SizeChart action
            .addCase(deleteSizeChartAction.pending, (state, action) => {
                state.deleteSizeChartError = null;
                state.deleteSizeChartLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteSizeChartAction.fulfilled, (state, action) => {
                state.deleteSizeChartLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Size Chart deleted successfully.');
            })
            .addCase(deleteSizeChartAction.rejected, (state, action) => {
                state.deleteSizeChartLoadingStatus = LoadingStatus.FAILED;
                state.deleteSizeChartError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const SizeChartReducer = sizeChartSlice.reducer;
export const sizeChartActions = sizeChartSlice.actions;
