const keys = {
    TOKEN: '@token',
    USER_DETAILS: '@user_detail',
    IS_REMEMBER: '@isRemember'
};

// Set local storage data
const setItem = (key, value) => {
    if (value != null) {
        localStorage.setItem(key, value);
    } else {
        localStorage.setItem(key, '');
    }
};

// Get local storage data
const getItem = (key, value) => {
    const stored = localStorage.getItem(key);
    if (stored != null) {
        return stored;
    } else {
        setItem(key, value);
        return value;
    }
};

// Delete local storage data
const deleteItem = (key) => {
    localStorage.removeItem(key);
};

// Clear local storage data
const clearAllItem = () => {
    localStorage.clear();
};

const LocalStorage = {
    keys,
    getItem,
    setItem,
    clearAllItem,
    deleteItem
};

export default LocalStorage;
