/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'eventGallery';

const initialState = entityAdapter.getInitialState({
    allProductLoadingStatus: LoadingStatus.NOT_LOADED,
    allEventGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    eventGalleryByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addEventGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    editEventGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteEventGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    allEventGallery: [],
    eventGalleryById: null,
    allEventGalleryError: null,
    eventGalleryByIdError: null,
    addEventGalleryError: null,
    editEventGalleryError: null,
    deleteEventGalleryError: null,
    getProductError: null,
    getProduct:[],

});

export const getProductData = createAsyncThunk(`${SLICE_NAME}/getProductData`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-product',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * All EventGallery Action
 */

export const allEventGalleryAction = createAsyncThunk(`${SLICE_NAME}/allEventGalleryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/show-event-gallery',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  EventGallery By Id Action
 */

export const eventGalleryByIdAction = createAsyncThunk(`${SLICE_NAME}/eventGalleryByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/views-event-gallery',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add EventGallery Action
 */

export const addEventGalleryAction = createAsyncThunk(`${SLICE_NAME}/addEventGalleryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-event-gallery',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit EventGallery Action
 */

export const editEventGalleryAction = createAsyncThunk(`${SLICE_NAME}/editEventGalleryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-event-gallery',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete EventGallery Action
 */

export const deleteEventGalleryAction = createAsyncThunk(`${SLICE_NAME}/deleteEventGalleryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-event-gallery',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const eventGallerySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetEventGalleryState: (state, action) => {
            return {
                ...state,
                addEventGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
                editEventGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteEventGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
                addEventGalleryError: null,
                editEventGalleryError: null,
                deleteEventGalleryError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All product list for dropdown
            .addCase(getProductData.pending, (state, action) => {
                state.getProductError = null;
                state.allProductLoadingStatus = LoadingStatus.LOADING;
                console.log('LOading');
            })
            .addCase(getProductData.fulfilled, (state, action) => {
                state.allProductLoadingStatus = LoadingStatus.LOADED;
                state.getProduct = action.payload?.data;
                console.log( state.getProduct,"<<<<<<<<<<<<<")
            })
            .addCase(getProductData.rejected, (state, action) => {
                state.allProductLoadingStatus = LoadingStatus.FAILED;
                state.getProductError = action.payload || action.error.message;
                console.log('LOading Failed');
            })
            // All EventGallery action
            .addCase(allEventGalleryAction.pending, (state, action) => {
                state.allEventGalleryError = null;
                state.allEventGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allEventGalleryAction.fulfilled, (state, action) => {
                state.allEventGalleryLoadingStatus = LoadingStatus.LOADED;
                state.allEventGallery = action.payload?.data;
            })
            .addCase(allEventGalleryAction.rejected, (state, action) => {
                state.allEventGalleryLoadingStatus = LoadingStatus.FAILED;
                state.allEventGalleryError = action.payload || action.error.message;
            })
            //  EventGallery by id action
            .addCase(eventGalleryByIdAction.pending, (state, action) => {
                state.eventGalleryByIdError = null;
                state.eventGalleryByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(eventGalleryByIdAction.fulfilled, (state, action) => {
                state.eventGalleryByIdLoadingStatus = LoadingStatus.LOADED;
                state.eventGalleryById = action.payload?.data?.event_data;
            })
            .addCase(eventGalleryByIdAction.rejected, (state, action) => {
                state.eventGalleryByIdLoadingStatus = LoadingStatus.FAILED;
                state.eventGalleryByIdError = action.payload || action.error.message;
            })
            // Add EventGallery action
            .addCase(addEventGalleryAction.pending, (state, action) => {
                state.addEventGalleryError = null;
                state.addEventGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addEventGalleryAction.fulfilled, (state, action) => {
                state.addEventGalleryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Event added successfully.');
            })
            .addCase(addEventGalleryAction.rejected, (state, action) => {
                state.addEventGalleryLoadingStatus = LoadingStatus.FAILED;
                state.addEventGalleryError = action.payload || action.error.message;
            })
            // Edit EventGallery action
            .addCase(editEventGalleryAction.pending, (state, action) => {
                state.editEventGalleryError = null;
                state.editEventGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editEventGalleryAction.fulfilled, (state, action) => {
                state.editEventGalleryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Event updated successfully.');
            })
            .addCase(editEventGalleryAction.rejected, (state, action) => {
                state.editEventGalleryLoadingStatus = LoadingStatus.FAILED;
                state.editEventGalleryError = action.payload || action.error.message;
            })
            // Delete EventGallery action
            .addCase(deleteEventGalleryAction.pending, (state, action) => {
                state.deleteEventGalleryError = null;
                state.deleteEventGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteEventGalleryAction.fulfilled, (state, action) => {
                state.deleteEventGalleryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Event deleted successfully.');
            })
            .addCase(deleteEventGalleryAction.rejected, (state, action) => {
                state.deleteEventGalleryLoadingStatus = LoadingStatus.FAILED;
                state.deleteEventGalleryError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const eventGalleryReducer = eventGallerySlice.reducer;

export const eventGalleryActions = eventGallerySlice.actions;
