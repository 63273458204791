/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'customerExperinces';

const initialState = entityAdapter.getInitialState({
    allCustomerExperincesLoadingStatus: LoadingStatus.NOT_LOADED,
    allTeacherRatingLoadingStatus: LoadingStatus.NOT_LOADED,
    customerExperincesByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addCustomerExperincesLoadingStatus: LoadingStatus.NOT_LOADED,
    editCustomerExperincesLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteCustomerExperincesLoadingStatus: LoadingStatus.NOT_LOADED,
    allCustomerExperinces: [],
    customerExperincesById: null,
    allCustomerExperincesError: null,
    allTeacherRatingError: null,
    customerExperincesByIdError: null,
    addCustomerExperincesError: null,
    editCustomerExperincesError: null,
    deleteCustomerExperincesError: null
});

/**
 * All CustomerExperinces Action
 */

export const allCustomerExperincesAction = createAsyncThunk(`${SLICE_NAME}/allCustomerExperincesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/customer-experinces',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
export const allTeacherRatingAction = createAsyncThunk(`${SLICE_NAME}/allTeacherRatingAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-rating',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  CustomerExperinces By Id Action
 */

export const customerExperincesByIdAction = createAsyncThunk(`${SLICE_NAME}/customerExperincesByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/customer-experince-view',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add CustomerExperinces Action
 */

export const addCustomerExperincesAction = createAsyncThunk(`${SLICE_NAME}/addCustomerExperincesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/customer-experince',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit CustomerExperinces Action
 */

export const editCustomerExperincesAction = createAsyncThunk(`${SLICE_NAME}/editCustomerExperincesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/customer-experince-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete CustomerExperinces Action
 */

export const deleteCustomerExperincesAction = createAsyncThunk(`${SLICE_NAME}/deleteCustomerExperincesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/customer-experince-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const customerExperincesSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetCustomerExperincesState: (state, action) => {
            return {
                ...state,
                addCustomerExperincesLoadingStatus: LoadingStatus.NOT_LOADED,
                editCustomerExperincesLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCustomerExperincesLoadingStatus: LoadingStatus.NOT_LOADED,
                addCustomerExperincesError: null,
                editCustomerExperincesError: null,
                deleteCustomerExperincesError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder

            // All CustomerExperinces action
            .addCase(allCustomerExperincesAction.pending, (state, action) => {
                state.allCustomerExperincesError = null;
                state.allCustomerExperincesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCustomerExperincesAction.fulfilled, (state, action) => {
                state.allCustomerExperincesLoadingStatus = LoadingStatus.LOADED;
                state.allCustomerExperinces = action.payload?.data;
            })
            .addCase(allCustomerExperincesAction.rejected, (state, action) => {
                state.allCustomerExperincesLoadingStatus = LoadingStatus.FAILED;
                state.allCustomerExperincesError = action.payload || action.error.message;
            })
            // All CustomerExperinces action
            .addCase(allTeacherRatingAction.pending, (state, action) => {
                state.allTeacherRatingError = null;
                state.allTeacherRatingLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allTeacherRatingAction.fulfilled, (state, action) => {
                state.allTeacherRatingLoadingStatus = LoadingStatus.LOADED;
                state.allTeacherRating = action.payload?.data;
            })
            .addCase(allTeacherRatingAction.rejected, (state, action) => {
                state.allTeacherRatingLoadingStatus = LoadingStatus.FAILED;
                state.allTeacherRatingError = action.payload || action.error.message;
            })
            // CustomerExperinces by id action
            .addCase(customerExperincesByIdAction.pending, (state, action) => {
                state.customerExperincesByIdError = null;
                state.customerExperincesByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(customerExperincesByIdAction.fulfilled, (state, action) => {
                state.customerExperincesByIdLoadingStatus = LoadingStatus.LOADED;
                state.customerExperincesById = action.payload?.data?.customer_experince_data;
            })
            .addCase(customerExperincesByIdAction.rejected, (state, action) => {
                state.customerExperincesByIdLoadingStatus = LoadingStatus.FAILED;
                state.customerExperincesByIdError = action.payload || action.error.message;
            })
            // Add CustomerExperinces action
            .addCase(addCustomerExperincesAction.pending, (state, action) => {
                state.addCustomerExperincesError = null;
                state.addCustomerExperincesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCustomerExperincesAction.fulfilled, (state, action) => {
                state.addCustomerExperincesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data added successfully.');
            })
            .addCase(addCustomerExperincesAction.rejected, (state, action) => {
                state.addCustomerExperincesLoadingStatus = LoadingStatus.FAILED;
                state.addCustomerExperincesError = action.payload || action.error.message;
            })
            // Edit CustomerExperinces action
            .addCase(editCustomerExperincesAction.pending, (state, action) => {
                state.editCustomerExperincesError = null;
                state.editCustomerExperincesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editCustomerExperincesAction.fulfilled, (state, action) => {
                state.editCustomerExperincesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data updated successfully.');
            })
            .addCase(editCustomerExperincesAction.rejected, (state, action) => {
                state.editCustomerExperincesLoadingStatus = LoadingStatus.FAILED;
                state.editCustomerExperincesError = action.payload || action.error.message;
            })
            // Delete CustomerExperinces action
            .addCase(deleteCustomerExperincesAction.pending, (state, action) => {
                state.deleteCustomerExperincesError = null;
                state.deleteCustomerExperincesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCustomerExperincesAction.fulfilled, (state, action) => {
                state.deleteCustomerExperincesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data deleted successfully.');
            })
            .addCase(deleteCustomerExperincesAction.rejected, (state, action) => {
                state.deleteCustomerExperincesLoadingStatus = LoadingStatus.FAILED;
                state.deleteCustomerExperincesError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const customerExperincesReducer = customerExperincesSlice.reducer;

export const customerExperincesActions = customerExperincesSlice.actions;
