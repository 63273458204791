import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { stayLoginAction } from 'redux/reducers/auth.slice';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LocalStorage from 'utils/localStorage';
import { allCountryCodeAction } from 'redux/reducers/users.slice';

// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = (props) => {
    const { authReducer, stayLogin, getCountryCode } = props;
    useEffect(() => {
        stayLogin();
        getCountryCode();
    }, []);

    const userDetails = LocalStorage.getItem(LocalStorage.keys.USER_DETAILS);

    const routing = useRoutes(routes(Boolean(userDetails) ?? false));
    return <>{routing}</>;
};

ThemeRoutes.propTypes = {
    authReducer: PropTypes.object,
    stayLogin: PropTypes.func
};

const mapStateToProps = (state) => {
    return { authReducer: state.authReducer };
};

const mapDispatchToProps = (dispatch) => ({
    stayLogin: () => dispatch(stayLoginAction()),
    getCountryCode: () => dispatch(allCountryCodeAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeRoutes);
