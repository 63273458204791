/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'teachers';

const initialState = entityAdapter.getInitialState({
    allTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
    teachersByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
    editTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
    allPopularCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    allTeacherss: [],
    allPopularCourses: [],
    teachersById: null,
    allTeachersError: null,
    allPopularCoursesError: null,
    addTeachersError: null,
    editTeachersError: null,
    deleteTeachersError: null,
    changeStatusTeachersError: null,
    teachersByIdError: null,
});

/**
 * All Teachers Action
 */

export const allTeachersAction = createAsyncThunk(`${SLICE_NAME}/allTeachersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-teachers',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add Teachers Action
 */

export const addTeachersAction = createAsyncThunk(`${SLICE_NAME}/addTeachersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-store',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Teachers by ID Action
 */

export const teachersByIdAction = createAsyncThunk(`${SLICE_NAME}/teachersByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-view/'+ params.id,
            method: 'GET',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit Teachers Action
 */

export const editTeachersAction = createAsyncThunk(`${SLICE_NAME}/editTeachersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete Teachers Action
 */

export const deleteTeachersAction = createAsyncThunk(`${SLICE_NAME}/deleteTeachersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status Teachers Action
 */

export const changeStatusTeachersAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusTeachersAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/teacher-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * All Teachers Action
 */

 export const getPopularCourses = createAsyncThunk(`${SLICE_NAME}/getPopularCourses`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-popular-course',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Teacher Multiple Images by ID Action 
 */

 export const getTeacherImagesByIdAction = createAsyncThunk(`${SLICE_NAME}/getTeacherImagesByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/get-teachers-images?teacher_id=${params.teacher_id}`,
            method: 'GET',
            params: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  Add Multiple Teacher Images by ID Action
 */

 export const addMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/addMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-teachers-images',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete Product Multiple Images by ID Action
 */

 export const deleteMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/deleteMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-teachers-images',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});


/**
 * Slice for all reducres
 */
const teachersSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetTeachersState: (state, action) => {
            return {
                ...state,
                teachersByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
                editTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusTeachersLoadingStatus: LoadingStatus.NOT_LOADED,
                teachersById: null,
                teachersByIdError: null,
                addTeachersError: null,
                editTeachersError: null,
                deleteTeachersError: null,
                changeStatusTeachersError: null,
                allTeacherImagesListLoadingStatus:LoadingStatus.NOT_LOADED,
                allTeacherImagesListError:null,
                allTeacherImagesList:[],
                addMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                addMultipleImageError:null,
                deleteMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteMultipleImageError:null,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All Teachers action
            .addCase(allTeachersAction.pending, (state, action) => {
                state.allTeachersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allTeachersAction.fulfilled, (state, action) => {
                state.allTeachersLoadingStatus = LoadingStatus.LOADED;
                state.allTeacherss = action.payload?.data ?? [];
            })
            .addCase(allTeachersAction.rejected, (state, action) => {
                state.allTeachersLoadingStatus = LoadingStatus.FAILED;
                state.allTeachersError = action.payload || action.error.message;
            })
            .addCase(teachersByIdAction.pending, (state, action) => {
                state.teachersByIdError = null;
                state.teachersByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(teachersByIdAction.fulfilled, (state, action) => {
                state.teachersByIdLoadingStatus = LoadingStatus.LOADED;
                state.teachersById = action.payload?.data;
            })
            .addCase(teachersByIdAction.rejected, (state, action) => {
                state.teachersByIdLoadingStatus = LoadingStatus.FAILED;
                state.teachersByIdError = action.payload || action.error.message;
            })

            // Add Teachers action
            .addCase(addTeachersAction.pending, (state, action) => {
                state.addTeachersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addTeachersAction.fulfilled, (state, action) => {
                state.addTeachersLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teachers added successfully.');
            })
            .addCase(addTeachersAction.rejected, (state, action) => {
                state.addTeachersLoadingStatus = LoadingStatus.FAILED;
                state.addTeachersError = action.payload || action.error.message;
            })
            // Edit Teachers action
            .addCase(editTeachersAction.pending, (state, action) => {
                state.editTeachersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editTeachersAction.fulfilled, (state, action) => {
                state.editTeachersLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teachers updated successfully.');
            })
            .addCase(editTeachersAction.rejected, (state, action) => {
                state.editTeachersLoadingStatus = LoadingStatus.FAILED;
                state.editTeachersError = action.payload || action.error.message;
            })
            // Delete Teachers action
            .addCase(deleteTeachersAction.pending, (state, action) => {
                state.deleteTeachersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteTeachersAction.fulfilled, (state, action) => {
                state.deleteTeachersLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teachers deleted successfully.');
            })
            .addCase(deleteTeachersAction.rejected, (state, action) => {
                state.deleteTeachersLoadingStatus = LoadingStatus.FAILED;
                state.deleteTeachersError = action.payload || action.error.message;
            })
            // Change Status Teachers action
            .addCase(changeStatusTeachersAction.pending, (state, action) => {
                state.changeStatusTeachersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusTeachersAction.fulfilled, (state, action) => {
                state.changeStatusTeachersLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teachers status updated successfully.');
            })
            .addCase(changeStatusTeachersAction.rejected, (state, action) => {
                state.changeStatusTeachersLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusTeachersError = action.payload || action.error.message;
            })
             // All courses action
             .addCase(getPopularCourses.pending, (state, action) => {
                state.allPopularCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getPopularCourses.fulfilled, (state, action) => {
                state.allPopularCoursesLoadingStatus = LoadingStatus.LOADED;
                state.allPopularCourses = action.payload?.data ?? [];
            })
            .addCase(getPopularCourses.rejected, (state, action) => {
                state.allPopularCoursesLoadingStatus = LoadingStatus.FAILED;
                state.allPopularCoursesError = action.payload || action.error.message;
            })

             // all Teacher Multiple Images action
             .addCase(getTeacherImagesByIdAction.pending, (state, action) => {
                state.allTeacherImagesListLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getTeacherImagesByIdAction.fulfilled, (state, action) => {
                state.allTeacherImagesListLoadingStatus = LoadingStatus.LOADED;
                state.allTeacherImagesList = action.payload?.data;
            })
            .addCase(getTeacherImagesByIdAction.rejected, (state, action) => {
                state.allTeacherImagesListLoadingStatus = LoadingStatus.FAILED;
                state.allTeacherImagesListError = action.payload || action.error.message;
                state.allTeacherImagesList = [];
            })
            // Add Multiple Images action
            .addCase(addMultipleImageAction.pending, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addMultipleImageAction.fulfilled, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teacher image added successfully.');
            })
            .addCase(addMultipleImageAction.rejected, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.addMultipleImageError = action.payload || action.error.message;
            })
            // delete Teacher Multiple Image action 
            .addCase(deleteMultipleImageAction.pending, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteMultipleImageAction.fulfilled, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teacher image deleted successfully.');
            })
            .addCase(deleteMultipleImageAction.rejected, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.deleteMultipleImageError = action.payload || action.error.message;
            })
    }
});

/*
 * Export reducer for store configuration.
 */
export const teachersReducer = teachersSlice.reducer;

export const teachersActions = teachersSlice.actions;
