/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'ecommerce';

const initialState = entityAdapter.getInitialState({
    oderHistoryLoadingStatus: LoadingStatus.NOT_LOADED,
    orderHistory: [],
    orderHistoryError: null,

    viewOrderHistoryLoadingStatus: LoadingStatus.NOT_LOADED,
    viewOrderHistoryByID: null,
    viewOrderHistoryByIDError: null,

    changeStatusOrderLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusOrderError: null,

    saveTrackingLoadingStatus: LoadingStatus.NOT_LOADED,
    saveTrackingError: null,

    allProductsLoadingStatus: LoadingStatus.NOT_LOADED,
    allProducts: [],
    allProductsError: null,

    productByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    productById: null,
    productByIdError: null,

    addProductLoadingStatus: LoadingStatus.NOT_LOADED,
    addProductError: null,

    editProductLoadingStatus: LoadingStatus.NOT_LOADED,
    editProductError: null,

    deleteProductLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteProductError: null,

    changeStatusProductLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusProductError: null,

    productImageListLoadingStatus: LoadingStatus.NOT_LOADED,
    productImageList: [],
    productImageListError: null,

    addProductImageLoadingStatus: LoadingStatus.NOT_LOADED,
    addProductImageError: null,

    deleteProductImageLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteProductImageError: null,

    changeStatusProductImageLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusProductImageError: null,

    productImageByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    productImageById: null,
    productImageByIdError: null,

    updateProductLoadingStatus: LoadingStatus.NOT_LOADED,
    updateProductError: null,

    getSizeChartError: null,
    getSizeChart: []
});

// get Size Chart Data
export const getSizeChartData = createAsyncThunk(`${SLICE_NAME}/getSizeChartData`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/size-charts',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Order History Action
 */

export const orderHistoryAction = createAsyncThunk(`${SLICE_NAME}/orderHistoryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-product-history-users',
            method: 'GET'
        });
        console.log(result);
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Order History By ID
 */

export const viewOrderHistoryAction = createAsyncThunk(`${SLICE_NAME}/viewOrderHistoryAction`, async (params, thunkAPI) => {
    const { order_id } = params;
    try {
        const result = await axiosRequest({
            url: `/view-history`,
            method: 'GET',
            params: { order_id: order_id }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Order History Action
 */

export const changeStatusOrderAction = createAsyncThunk(`${SLICE_NAME}/changeStatusOrderAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/change-delivered-status',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Order History Action
 */

 export const saveTrackingLinkAction = createAsyncThunk(`${SLICE_NAME}/saveTrackingLinkAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-order-tracking-link',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Product Management Action
 */

/**
 *  All Products Action
 */

export const allProductsAction = createAsyncThunk(`${SLICE_NAME}/allProductsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/listing-product',
            method: 'GET'
        });
        return result;
    } catch (error) {
        // console.log('error', error);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Product by ID Action
 */

export const productByIdAction = createAsyncThunk(`${SLICE_NAME}/productByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-byid-product',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Add Product Action
 */

export const addProductAction = createAsyncThunk(`${SLICE_NAME}/addProductAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-product',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Edit Product Action
 */

export const editProductAction = createAsyncThunk(`${SLICE_NAME}/editProductAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-product',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete Product Action
 */

export const deleteProductAction = createAsyncThunk(`${SLICE_NAME}/deleteProductAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-product',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Change Status User Action
 */

export const changeStatusProductAction = createAsyncThunk(`${SLICE_NAME}/changeStatusProductAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/status-product',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Product Images by ID Action 
 */

export const productImageByIdAction = createAsyncThunk(`${SLICE_NAME}/productImageByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/list-product-image?product_id=${params.product_id}`,
            method: 'GET',
            params: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Product Multiple Images by ID Action 
 */

 export const productMultipleImageByIdAction = createAsyncThunk(`${SLICE_NAME}/productMultipleImageByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/multiple-color-images?product_image_id=${params.product_image_id}`,
            method: 'GET',
            params: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  Product image by ID Action
 */

export const showProductImageByIdAction = createAsyncThunk(`${SLICE_NAME}/showProductImageByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-product-image',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  Edit Product Image Action
 */

export const updateProductImageAction = createAsyncThunk(`${SLICE_NAME}/updateProductImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-product-image',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Change Status product image Action
 */

export const changeStatusProductImageAction = createAsyncThunk(`${SLICE_NAME}/changeStatusProductImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/change-product-image-status',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  Add Product Images by ID Action
 */

export const addProductImageAction = createAsyncThunk(`${SLICE_NAME}/addProductImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-product-image',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  Add Multiple Product Images by ID Action
 */

 export const addMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/addMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/multiple-color-image',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete Product Images by ID Action
 */

export const deleteProductImageAction = createAsyncThunk(`${SLICE_NAME}/deleteProductImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-product-image',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete Product Multiple Images by ID Action
 */

 export const deleteMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/deleteMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/multiple-color-image-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const ecommerceSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetOrderHistoryState: (state, action) => {
            return {
                ...state,
                viewOrderHistoryLoadingStatus: LoadingStatus.NOT_LOADED,
                viewOrderHistoryByID: null,
                viewOrderHistoryByIDError: null,
                changeStatusOrderLoadingStatus: LoadingStatus.NOT_LOADED,
                saveTrackingLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusOrderError: null,
                saveTrackingError:null,
            };
        },
        resetProductsState: (state, action) => {
            return {
                ...state,
                productByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addProductLoadingStatus: LoadingStatus.NOT_LOADED,
                editProductLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteProductLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusProductLoadingStatus: LoadingStatus.NOT_LOADED,
                productImageListLoadingStatus: LoadingStatus.NOT_LOADED,
                productImageList: [],
                productImageListError: null,
                productById: null,
                productByIdError: null,
                editProductError: null,
                addProductError: null,
                deleteProductError: null,
                changeStatusProductError: null
            };
        },
        resetUploadImageState: (state, action) => {
            return {
                ...state,
                addProductImageLoadingStatus: LoadingStatus.NOT_LOADED,
                addProductImageError: null,
                deleteProductImageLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteProductImageError: null,
                changeStatusProductImageLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusProductImageError: null,
                productImageByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                productImageByIdError: null,
                updateProductLoadingStatus: LoadingStatus.NOT_LOADED,
                updateProductError: null,
                addMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                addMultipleImageError:null,
                productMultipleImageListLoadingStatus:LoadingStatus.NOT_LOADED,
                productMultipleImageListError:null,
                productMultipleImageList:[],
                deleteMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteMultipleImageError:null,

            };
        }
    },
    extraReducers: (builder) => {
        builder

            // All Size Chart list for dropdown
            .addCase(getSizeChartData.pending, (state, action) => {
                state.getSizeChartError = null;
                state.allProductLoadingStatus = LoadingStatus.LOADING;
                console.log('LOading');
            })
            .addCase(getSizeChartData.fulfilled, (state, action) => {
                state.allProductLoadingStatus = LoadingStatus.LOADED;
                state.getSizeChart = action.payload?.data;
                console.log(state.getSizeChart, '<<<<<<<<<<<<<');
            })
            .addCase(getSizeChartData.rejected, (state, action) => {
                state.allProductLoadingStatus = LoadingStatus.FAILED;
                state.getSizeChartError = action.payload || action.error.message;
                console.log('LOading Failed');
            })

            // order history action
            .addCase(orderHistoryAction.pending, (state, action) => {
                state.oderHistoryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(orderHistoryAction.fulfilled, (state, action) => {
                state.oderHistoryLoadingStatus = LoadingStatus.LOADED;
                state.orderHistory = action.payload?.data;
            })
            .addCase(orderHistoryAction.rejected, (state, action) => {
                state.oderHistoryLoadingStatus = LoadingStatus.FAILED;
                state.orderHistoryError = action.payload || action.error.message;
            })
            // view history by id action
            .addCase(viewOrderHistoryAction.pending, (state, action) => {
                state.viewOrderHistoryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(viewOrderHistoryAction.fulfilled, (state, action) => {
                state.viewOrderHistoryLoadingStatus = LoadingStatus.LOADED;
                state.viewOrderHistoryByID = action.payload?.data[0];
            })
            .addCase(viewOrderHistoryAction.rejected, (state, action) => {
                state.viewOrderHistoryLoadingStatus = LoadingStatus.FAILED;
                state.viewOrderHistoryByIDError = action.payload || action.error.message;
            })
            // Chnge Order Status 
            .addCase(changeStatusOrderAction.pending, (state, action) => {
                state.changeStatusOrderLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusOrderAction.fulfilled, (state, action) => {
                state.changeStatusOrderLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Order status changed successfully.');
            })
            .addCase(changeStatusOrderAction.rejected, (state, action) => {
                state.changeStatusOrderLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusOrderError = action.payload || action.error.message;
            })
            // Chnge Order Status 
            .addCase(saveTrackingLinkAction.pending, (state, action) => {
                state.saveTrackingLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(saveTrackingLinkAction.fulfilled, (state, action) => {
                state.saveTrackingLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Link updated successfully.');
            })
            .addCase(saveTrackingLinkAction.rejected, (state, action) => {
                state.saveTrackingLoadingStatus = LoadingStatus.FAILED;
                state.saveTrackingError = action.payload || action.error.message;
            })
            // all Products action
            .addCase(allProductsAction.pending, (state, action) => {
                state.allProductsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allProductsAction.fulfilled, (state, action) => {
                state.allProductsLoadingStatus = LoadingStatus.LOADED;
                state.allProducts = action.payload?.data;
            })
            .addCase(allProductsAction.rejected, (state, action) => {
                state.allProductsLoadingStatus = LoadingStatus.FAILED;
                state.allProductsError = action.payload || action.error.message;
            })
            //  product by id action
            .addCase(productByIdAction.pending, (state, action) => {
                state.productByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(productByIdAction.fulfilled, (state, action) => {
                state.productByIdLoadingStatus = LoadingStatus.LOADED;
                state.productById = action.payload?.data;
            })
            .addCase(productByIdAction.rejected, (state, action) => {
                state.productByIdLoadingStatus = LoadingStatus.FAILED;
                state.productByIdError = action.payload || action.error.message;
            })
            // add product action
            .addCase(addProductAction.pending, (state, action) => {
                state.addProductLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addProductAction.fulfilled, (state, action) => {
                state.addProductLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product added successfully.');
            })
            .addCase(addProductAction.rejected, (state, action) => {
                state.addProductLoadingStatus = LoadingStatus.FAILED;
                state.addProductError = action.payload || action.error.message;
            })
            // edit Product action
            .addCase(editProductAction.pending, (state, action) => {
                state.editProductLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editProductAction.fulfilled, (state, action) => {
                state.editProductLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product updated successfully.');
            })
            .addCase(editProductAction.rejected, (state, action) => {
                state.editProductLoadingStatus = LoadingStatus.FAILED;
                state.editProductError = action.payload || action.error.message;
            })
            // delete Products action
            .addCase(deleteProductAction.pending, (state, action) => {
                state.deleteProductLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteProductAction.fulfilled, (state, action) => {
                state.deleteProductLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product deleted successfully.');
            })
            .addCase(deleteProductAction.rejected, (state, action) => {
                state.deleteProductLoadingStatus = LoadingStatus.FAILED;
                state.deleteProductError = action.payload || action.error.message;
            })
            // change status product action
            .addCase(changeStatusProductAction.pending, (state, action) => {
                state.changeStatusProductLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusProductAction.fulfilled, (state, action) => {
                state.changeStatusProductLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product status updated successfully.');
            })
            .addCase(changeStatusProductAction.rejected, (state, action) => {
                state.changeStatusProductLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusProductError = action.payload || action.error.message;
            })
            // all Product Images action
            .addCase(productImageByIdAction.pending, (state, action) => {
                state.productImageListLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(productImageByIdAction.fulfilled, (state, action) => {
                state.productImageListLoadingStatus = LoadingStatus.LOADED;
                state.productImageList = action.payload?.data;
            })
            .addCase(productImageByIdAction.rejected, (state, action) => {
                state.productImageListLoadingStatus = LoadingStatus.FAILED;
                state.productImageListError = action.payload || action.error.message;
                state.productImageList = [];
            })
             // all Product Multiple Images action
             .addCase(productMultipleImageByIdAction.pending, (state, action) => {
                state.productMultipleImageListLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(productMultipleImageByIdAction.fulfilled, (state, action) => {
                state.productMultipleImageListLoadingStatus = LoadingStatus.LOADED;
                state.productMultipleImageList = action.payload?.data;
            })
            .addCase(productMultipleImageByIdAction.rejected, (state, action) => {
                state.productMultipleImageListLoadingStatus = LoadingStatus.FAILED;
                state.productMultipleImageListError = action.payload || action.error.message;
                state.productMultipleImageList = [];
            })
            // Add Product action
            .addCase(addProductImageAction.pending, (state, action) => {
                state.addProductImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addProductImageAction.fulfilled, (state, action) => {
                state.addProductImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product image added successfully.');
            })
            .addCase(addProductImageAction.rejected, (state, action) => {
                state.addProductImageLoadingStatus = LoadingStatus.FAILED;
                state.addProductImageError = action.payload || action.error.message;
            })
             // Add Multiple Images action
             .addCase(addMultipleImageAction.pending, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addMultipleImageAction.fulfilled, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product image added successfully.');
            })
            .addCase(addMultipleImageAction.rejected, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.addMultipleImageError = action.payload || action.error.message;
            })
            // delete Products action 
            .addCase(deleteProductImageAction.pending, (state, action) => {
                state.deleteProductImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteProductImageAction.fulfilled, (state, action) => {
                state.deleteProductImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product image deleted successfully.');
            })
            .addCase(deleteProductImageAction.rejected, (state, action) => {
                state.deleteProductImageLoadingStatus = LoadingStatus.FAILED;
                state.deleteProductImageError = action.payload || action.error.message;
            })
              // delete Products Multiple Image action 
              .addCase(deleteMultipleImageAction.pending, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteMultipleImageAction.fulfilled, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product image deleted successfully.');
            })
            .addCase(deleteMultipleImageAction.rejected, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.deleteMultipleImageError = action.payload || action.error.message;
            })
            // change product image status
            .addCase(changeStatusProductImageAction.pending, (state, action) => {
                state.changeStatusProductImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusProductImageAction.fulfilled, (state, action) => {
                state.changeStatusProductImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product image status updated successfully.');
            })
            .addCase(changeStatusProductImageAction.rejected, (state, action) => {
                state.changeStatusProductImageLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusProductImageError = action.payload || action.error.message;
            })
            //  product image by id action
            .addCase(showProductImageByIdAction.pending, (state, action) => {
                state.productImageByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(showProductImageByIdAction.fulfilled, (state, action) => {
                state.productImageByIdLoadingStatus = LoadingStatus.LOADED;
                state.productImageById = action.payload?.data;
            })
            .addCase(showProductImageByIdAction.rejected, (state, action) => {
                state.productImageByIdLoadingStatus = LoadingStatus.FAILED;
                state.productImageByIdError = action.payload || action.error.message;
            })
            // update Product Image action
            .addCase(updateProductImageAction.pending, (state, action) => {
                state.updateProductLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updateProductImageAction.fulfilled, (state, action) => {
                state.updateProductLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product image updated successfully.');
            })
            .addCase(updateProductImageAction.rejected, (state, action) => {
                state.updateProductLoadingStatus = LoadingStatus.FAILED;
                state.updateProductError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const ecommerceReducer = ecommerceSlice.reducer;

export const ecommerceActions = ecommerceSlice.actions;
