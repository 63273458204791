/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'deliveryCharge';

const initialState = entityAdapter.getInitialState({

    allDeliveryChargeLoadingStatus: LoadingStatus.NOT_LOADED,
    deliveryChargeByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addDeliveryChargeLoadingStatus: LoadingStatus.NOT_LOADED,
    editDeliveryChargeLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteDeliveryChargeLoadingStatus: LoadingStatus.NOT_LOADED,
    allDeliveryCharge: [],
    deliveryChargeById: null,
    allDeliveryChargeError: null,
    deliveryChargeByIdError: null,
    addDeliveryChargeError: null,
    editDeliveryChargeError: null,
    deleteDeliveryChargeError: null,
});


/**
 * All DeliveryCharge Action
 */

export const allDeliveryChargeAction = createAsyncThunk(`${SLICE_NAME}/allDeliveryChargeAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delivery-charges',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  DeliveryCharge By Id Action
 */

export const deliveryChargeByIdAction = createAsyncThunk(`${SLICE_NAME}/deliveryChargeByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delivery-charge-view',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit DeliveryCharge Action
 */

export const editDeliveryChargeAction = createAsyncThunk(`${SLICE_NAME}/editDeliveryChargeAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delivery-charge-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const deliveryChargeSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetDeliveryChargeState: (state, action) => {
            return {
                ...state,
                addDeliveryChargeLoadingStatus: LoadingStatus.NOT_LOADED,
                editDeliveryChargeLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteDeliveryChargeLoadingStatus: LoadingStatus.NOT_LOADED,
                addDeliveryChargeError: null,
                editDeliveryChargeError: null,
                deleteDeliveryChargeError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All DeliveryCharge action
            .addCase(allDeliveryChargeAction.pending, (state, action) => {
                state.allDeliveryChargeError = null;
                state.allDeliveryChargeLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allDeliveryChargeAction.fulfilled, (state, action) => {
                state.allDeliveryChargeLoadingStatus = LoadingStatus.LOADED;
                state.allDeliveryCharge = action.payload?.data;
            })
            .addCase(allDeliveryChargeAction.rejected, (state, action) => {
                state.allDeliveryChargeLoadingStatus = LoadingStatus.FAILED;
                state.allDeliveryChargeError = action.payload || action.error.message;
            })
            // DeliveryCharge by id action
            .addCase(deliveryChargeByIdAction.pending, (state, action) => {
                state.deliveryChargeByIdError = null;
                state.deliveryChargeByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deliveryChargeByIdAction.fulfilled, (state, action) => {
                state.deliveryChargeByIdLoadingStatus = LoadingStatus.LOADED;
                state.deliveryChargeById = action.payload?.data;
            })
            .addCase(deliveryChargeByIdAction.rejected, (state, action) => {
                state.deliveryChargeByIdLoadingStatus = LoadingStatus.FAILED;
                state.deliveryChargeByIdError = action.payload || action.error.message;
            })

            // Edit DeliveryCharge action
            .addCase(editDeliveryChargeAction.pending, (state, action) => {
                state.editDeliveryChargeError = null;
                state.editDeliveryChargeLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editDeliveryChargeAction.fulfilled, (state, action) => {
                state.editDeliveryChargeLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Product Deliver updated successfully.');
            })
            .addCase(editDeliveryChargeAction.rejected, (state, action) => {
                state.editDeliveryChargeLoadingStatus = LoadingStatus.FAILED;
                state.editDeliveryChargeError = action.payload || action.error.message;
            })

    }
});

/*
 * Export reducer for store configuration.
 */
export const deliveryChargeReducer = deliveryChargeSlice.reducer;

export const deliveryChargeActions = deliveryChargeSlice.actions;
