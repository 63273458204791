/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    // GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridPrintExportMenuItem
} from '@mui/x-data-grid';
import NoRowFound from 'components/NoRowFound';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import { generateRandom } from 'utils/helper';

const GridToolbarExport = ({ csvOptions, printOptions, ...other }) => (
    <GridToolbarExportContainer {...other}>
        <GridCsvExportMenuItem options={csvOptions} />
        {/* <GridPrintExportMenuItem options={printOptions} /> */}
    </GridToolbarExportContainer>
);

const CustomToolbar = ({ setFilterButtonEl, hideFilter = false }) => (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <div>
            {!hideFilter && <GridToolbarFilterButton ref={setFilterButtonEl} />}
            <GridToolbarExport />
        </div>
        <GridToolbarQuickFilter />
    </GridToolbarContainer>
);

CustomToolbar.propTypes = {
    setFilterButtonEl: PropTypes.func.isRequired
};

function CustomDataGrid({ rows = [], columns, hideFilter = false }) {
    const [pageSize, setPageSize] = React.useState(10);
    const [filterButtonEl, setFilterButtonEl] = React.useState(null);
    const theme = useTheme();
    return (
        <div style={{ height: 700 }}>
            <DataGrid
                sx={{
                    border: 0,
                    color: '#000',
                    fontSize: '0.85rem',
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none !important'
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        paddingBottom: 2,
                        paddingTop: 2,
                        // paddingRight: 5,
                        // paddingLeft: 1,
                        marginBottom: 2,
                        borderBottom: '1px solid #dec7c7'
                    },
                    '& .MuiDataGrid-columnsContainer': {
                        backgroundColor: '#fafafa'
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none'
                    },
                    // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                    //     borderRight: `1px solid #dec7c7`
                    // },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#deb887',
                        color: '#a52a2a',
                        fontWeight: 'bold',
                        fontSize: '1rem'
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                        borderBottom: `1px solid #dec7c7`,
                        display: 'flex'
                        // alignItems: 'flex-start'
                    },
                    '& .MuiDataGrid-cell': {
                        color: '#000',
                        py: 1
                    },
                    '& .MuiPaginationItem-root': {
                        borderRadius: 0
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold'
                    }
                }}
                hideFooterSelectedRowCount
                disableColumnMenu
                rows={rows}
                getRowId={(row) => generateRandom()}
                getRowHeight={() => 'auto'}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                components={{
                    Toolbar: CustomToolbar,
                    NoRowsOverlay: NoRowFound
                }}
                componentsProps={{
                    panel: {
                        anchorEl: filterButtonEl
                    },
                    toolbar: {
                        quickFilterProps: { debounceMs: 500 },
                        setFilterButtonEl,
                        hideFilter
                    }
                }}
            />
        </div>
    );
}

export default CustomDataGrid;
