/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'courseContent';

const initialState = entityAdapter.getInitialState({
    allCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
    courseContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
    editCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
    allCourseContents: [],
    courseContentById: null,
    allCourseContentError: null,
    addCourseContentError: null,
    editCourseContentError: null,
    deleteCourseContentError: null,
    changeStatusCourseContentError: null,
    courseContentByIdError: null
});

/**
 * All CourseContent Action
 */

export const allCourseContentAction = createAsyncThunk(`${SLICE_NAME}/allCourseContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-content/' + params.id,
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add CourseContent Action
 */

export const addCourseContentAction = createAsyncThunk(`${SLICE_NAME}/addCourseContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-content-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  CourseContent by ID Action
 */

export const courseContentByIdAction = createAsyncThunk(`${SLICE_NAME}/courseContentByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params, 'Params');
        const result = await axiosRequest({
            url: '/course-content-view/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit CourseContent Action
 */

export const editCourseContentAction = createAsyncThunk(`${SLICE_NAME}/editCourseContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-content-update',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete CourseContent Action
 */

export const deleteCourseContentAction = createAsyncThunk(`${SLICE_NAME}/deleteCourseContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-content-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status CourseContent Action
 */

export const changeStatusCourseContentAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusCourseContentAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/live-course-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const courseContentSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetCourseContentState: (state, action) => {
            return {
                ...state,
                courseContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
                editCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusCourseContentLoadingStatus: LoadingStatus.NOT_LOADED,
                courseContentById: null,
                courseContentByIdError: null,
                addCourseContentError: null,
                editCourseContentError: null,
                deleteCourseContentError: null,
                changeStatusCourseContentError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All CourseContent action
            .addCase(allCourseContentAction.pending, (state, action) => {
                state.allCourseContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCourseContentAction.fulfilled, (state, action) => {
                state.allCourseContentLoadingStatus = LoadingStatus.LOADED;
                state.allCourseContents = action.payload?.data ?? [];
            })
            .addCase(allCourseContentAction.rejected, (state, action) => {
                state.allCourseContentLoadingStatus = LoadingStatus.FAILED;
                state.allCourseContentError = action.payload || action.error.message;
            })
            .addCase(courseContentByIdAction.pending, (state, action) => {
                state.courseContentByIdError = null;
                state.courseContentByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(courseContentByIdAction.fulfilled, (state, action) => {
                state.courseContentByIdLoadingStatus = LoadingStatus.LOADED;
                state.courseContentById = action.payload?.data;
            })
            .addCase(courseContentByIdAction.rejected, (state, action) => {
                state.courseContentByIdLoadingStatus = LoadingStatus.FAILED;
                state.courseContentByIdError = action.payload || action.error.message;
            })

            // Add CourseContent action
            .addCase(addCourseContentAction.pending, (state, action) => {
                state.addCourseContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCourseContentAction.fulfilled, (state, action) => {
                state.addCourseContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course Content added successfully.');
            })
            .addCase(addCourseContentAction.rejected, (state, action) => {
                state.addCourseContentLoadingStatus = LoadingStatus.FAILED;
                state.addCourseContentError = action.payload || action.error.message;
            })
            // Edit CourseContent action
            .addCase(editCourseContentAction.pending, (state, action) => {
                state.editCourseContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editCourseContentAction.fulfilled, (state, action) => {
                state.editCourseContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course Content updated successfully.');
            })
            .addCase(editCourseContentAction.rejected, (state, action) => {
                state.editCourseContentLoadingStatus = LoadingStatus.FAILED;
                state.editCourseContentError = action.payload || action.error.message;
            })
            // Delete CourseContent action
            .addCase(deleteCourseContentAction.pending, (state, action) => {
                state.deleteCourseContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCourseContentAction.fulfilled, (state, action) => {
                state.deleteCourseContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course Content deleted successfully.');
            })
            .addCase(deleteCourseContentAction.rejected, (state, action) => {
                state.deleteCourseContentLoadingStatus = LoadingStatus.FAILED;
                state.deleteCourseContentError = action.payload || action.error.message;
            })
            // Change Status CourseContent action
            .addCase(changeStatusCourseContentAction.pending, (state, action) => {
                state.changeStatusCourseContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusCourseContentAction.fulfilled, (state, action) => {
                state.changeStatusCourseContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course Content status updated successfully.');
            })
            .addCase(changeStatusCourseContentAction.rejected, (state, action) => {
                state.changeStatusCourseContentLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusCourseContentError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const courseContentReducer = courseContentSlice.reducer;

export const courseContentActions = courseContentSlice.actions;
