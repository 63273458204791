import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Chip, ButtonBase, Typography, IconButton, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import config from 'config';
// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { drawerTextColor } from 'config';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open, handleDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';
    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            <div style={{ width: '100%' }}>
                {!open ? (
                    <Stack direction="row" justifyContent="center">
                        <IconButton
                            disableRipple
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            edge="center"
                            color="secondary"
                            sx={{
                                color: 'text.primary',
                                bgcolor: open ? iconBackColorOpen : iconBackColor,
                                ml: { xs: 0, lg: 0 }
                            }}
                        >
                            <MenuUnfoldOutlined />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" mr={2}>
                        {
                            <div>
                                <ButtonBase disableRipple component={Link} to={''}>
                                    <Typography sx={{ color: drawerTextColor }} variant="h4">
                                        Shivam Yoga
                                    </Typography>
                                </ButtonBase>
                                {/* <Chip
                                    label={config?.appVersion}
                                    size="small"
                                    sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25, color: drawerTextColor } }}
                                    component="a"
                                    target="_blank"
                                    clickable
                                /> */}
                            </div>
                        }
                        {(!matchDownMD || open) && (
                            <IconButton
                                disableRipple
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                edge="center"
                                color="secondary"
                                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: 0 } }}
                            >
                                <MenuFoldOutlined />
                            </IconButton>
                        )}
                    </Stack>
                )}
            </div>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
