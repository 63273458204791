/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'faqs';

const initialState = entityAdapter.getInitialState({
    allFaqsLoading: LoadingStatus.NOT_LOADED,
    faqsByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
    editFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
    allFaqs: [],
    faqsById: null,
    allFaqsError: null,
    faqsByIdError: null,
    addFaqsError: null,
    editFaqsError: null,
    deleteFaqsError: null,
    changeStatusFaqsError: null,
});

/**
 * All PopularCourse Action
 */

export const allFaqsAction = createAsyncThunk(`${SLICE_NAME}/allFaqsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/faqs',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add PopularCourse Action
 */

export const addFaqsAction = createAsyncThunk(`${SLICE_NAME}/addFaqsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/faqs',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  PopularCourse by ID Action
 */

export const faqsByIdAction = createAsyncThunk(`${SLICE_NAME}/faqsByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/faqs/' + params.id,
            method: 'GET'
            // data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit PopularCourse Action
 */

 export const editFaqsAction = createAsyncThunk(
    `${SLICE_NAME}/editFaqsAction`,
    async (formData, thunkAPI) => {
        try {
            const id = formData.get("id");
            if (!id) {
                throw new Error("ID is missing in FormData");
            }

            const result = await axiosRequest({
                url: `/faqs/${id}`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Delete PopularCourse Action
 */

export const deleteFaqsAction = createAsyncThunk(`${SLICE_NAME}/deleteFaqsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/faqs/'+ params.id,
            method: 'DELETE',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status PopularCourse Action
 */

export const changeStatusFaqsAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusFaqsAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/faqs-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);



/**
 * Slice for all reducres
 */
const faqsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetFaqsState: (state, action) => {
            return {
                ...state,
                faqsByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
                editFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusFaqsLoadingStatus: LoadingStatus.NOT_LOADED,
                faqsById: null,
                faqsByIdError: null,
                addFaqsError: null,
                editFaqsError: null,
                deleteFaqsError: null,
                changeStatusFaqsError: null
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All PopularCourse action
            .addCase(allFaqsAction.pending, (state, action) => {
                state.allFaqsLoading = LoadingStatus.LOADING;
            })
            .addCase(allFaqsAction.fulfilled, (state, action) => {
                state.allFaqsLoading = LoadingStatus.LOADED;
                state.allFaqs = action.payload?.data ?? [];
            })
            .addCase(allFaqsAction.rejected, (state, action) => {
                state.allFaqsLoading = LoadingStatus.FAILED;
                state.allFaqsError = action.payload || action.error.message;
            })
            //  PopularCourse by id action
            .addCase(faqsByIdAction.pending, (state, action) => {
                state.faqsByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(faqsByIdAction.fulfilled, (state, action) => {
                state.faqsByIdLoadingStatus = LoadingStatus.LOADED;
                state.faqsById = action.payload?.data;
            })
            .addCase(faqsByIdAction.rejected, (state, action) => {
                state.faqsByIdLoadingStatus = LoadingStatus.FAILED;
                state.faqsByIdError = action.payload || action.error.message;
            })
            // Add PopularCourse action
            .addCase(addFaqsAction.pending, (state, action) => {
                state.addFaqsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addFaqsAction.fulfilled, (state, action) => {
                state.addFaqsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data added successfully.');
            })
            .addCase(addFaqsAction.rejected, (state, action) => {
                state.addFaqsLoadingStatus = LoadingStatus.FAILED;
                state.addFaqsError = action.payload || action.error.message;
            })
            // Edit PopularCourse action
            .addCase(editFaqsAction.pending, (state, action) => {
                state.editFaqsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editFaqsAction.fulfilled, (state, action) => {
                state.editFaqsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data updated successfully.');
            })
            .addCase(editFaqsAction.rejected, (state, action) => {
                state.editFaqsLoadingStatus = LoadingStatus.FAILED;
                state.editFaqsError = action.payload || action.error.message;
            })
            // Delete PopularCourse action
            .addCase(deleteFaqsAction.pending, (state, action) => {
                state.deleteFaqsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteFaqsAction.fulfilled, (state, action) => {
                state.deleteFaqsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data deleted successfully.');
            })
            .addCase(deleteFaqsAction.rejected, (state, action) => {
                state.deleteFaqsLoadingStatus = LoadingStatus.FAILED;
                state.deleteFaqsError = action.payload || action.error.message;
            })
            // Change Status PopularCourse action
            .addCase(changeStatusFaqsAction.pending, (state, action) => {
                state.changeStatusFaqsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusFaqsAction.fulfilled, (state, action) => {
                state.changeStatusFaqsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data status updated successfully.');
            })
            .addCase(changeStatusFaqsAction.rejected, (state, action) => {
                state.changeStatusFaqsLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusFaqsError = action.payload || action.error.message;
            });
            
    }
});

/*
 * Export reducer for store configuration.
 */
export const faqsReducer = faqsSlice.reducer;

export const faqsActions = faqsSlice.actions;
