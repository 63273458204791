/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'paymentMode';

const initialState = entityAdapter.getInitialState({
    paymentModeLoadingStatus: LoadingStatus.NOT_LOADED,
    updatePaymentModeLoadingStatus: LoadingStatus.NOT_LOADED,
    paymentModeStatusLoadingStatus: LoadingStatus.NOT_LOADED,
    paymentModeList: [],
    setPaymentStatus: null,
    allPaymentModeError: null,
    updatePaymentModeError: null
});

/**
 * All PaymentMode Action
 */

export const allPaymentModeAction = createAsyncThunk(`${SLICE_NAME}/allPaymentModeAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/listing-payment-mode',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All PaymentMode Action
 */

export const updatePaymentModeAction = createAsyncThunk(`${SLICE_NAME}/updatePaymentModeAction`, async (params, thunkAPI) => {
    let reqbody = {
        client_id: params.client_id,
        secret_key: params.secrete_key,
        id: params.id
    };
    try {
        const result = await axiosRequest({
            url: '/update-payment-mode',
            method: 'POST',
            data: reqbody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
export const updatePaymentModeStatusAction = createAsyncThunk(`${SLICE_NAME}/updatePaymentModeStatusAction`, async (params, thunkAPI) => {
    let reqbody = {
        id: params
    };
    try {
        const result = await axiosRequest({
            url: '/status-update-payment-mode',
            method: 'POST',
            params: reqbody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const paymentModeSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetPostersState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All PaymentMode action
            .addCase(allPaymentModeAction.pending, (state, action) => {
                state.allPaymentModeError = null;
                state.paymentModeLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allPaymentModeAction.fulfilled, (state, action) => {
                state.paymentModeLoadingStatus = LoadingStatus.LOADED;
                state.paymentModeList = action.payload?.data ?? [];
                state.setPaymentStatus = action.payload?.data[0]?.status ?? [];
            })
            .addCase(allPaymentModeAction.rejected, (state, action) => {
                state.paymentModeLoadingStatus = LoadingStatus.FAILED;
                state.allPaymentModeError = action.payload || action.error.message;
            })

            // All updatePaymentMode action
            .addCase(updatePaymentModeAction.pending, (state, action) => {
                state.updatePaymentModeError = null;
                state.updatePaymentModeLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updatePaymentModeAction.fulfilled, (state, action) => {
                state.updatePaymentModeLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Payment Mode data updated Successfully.');
            })
            .addCase(updatePaymentModeAction.rejected, (state, action) => {
                state.updatePaymentModeLoadingStatus = LoadingStatus.FAILED;
                state.updatePaymentModeError = action.payload || action.error.message;
            })

            // All updatePaymentModeStatus action
            .addCase(updatePaymentModeStatusAction.pending, (state, action) => {
                state.allPaymentModeError = null;
                state.paymentModeStatusLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updatePaymentModeStatusAction.fulfilled, (state, action) => {
                state.paymentModeStatusLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Payment Mode changed Successfully.');
            })
            .addCase(updatePaymentModeStatusAction.rejected, (state, action) => {
                state.paymentModeStatusLoadingStatus = LoadingStatus.FAILED;
                state.allPaymentModeError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const paymentModeReducer = paymentModeSlice.reducer;

export const paymentModeActions = paymentModeSlice.actions;
