/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'courseComprise';

const initialState = entityAdapter.getInitialState({
    allCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
    courseCompriseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
    editCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
    allCourseComprises: [],
    courseCompriseById: null,
    allCourseCompriseError: null,
    addCourseCompriseError: null,
    editCourseCompriseError: null,
    deleteCourseCompriseError: null,
    changeStatusCourseCompriseError: null,
    courseCompriseByIdError: null
});

/**
 * All CourseComprise Action
 */

export const allCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/allCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course_comprise',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add CourseComprise Action
 */

export const addCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/addCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course_comprise-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  CourseComprise by ID Action
 */

export const courseCompriseByIdAction = createAsyncThunk(`${SLICE_NAME}/courseCompriseByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params, 'Params');
        const result = await axiosRequest({
            url: '/course_comprise/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit CourseComprise Action
 */

export const editCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/editCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course_comprise-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete CourseComprise Action
 */

export const deleteCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/deleteCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course_comprise-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status CourseComprise Action
 */

export const changeStatusCourseCompriseAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusCourseCompriseAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/live-course-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const courseCompriseSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetCourseCompriseState: (state, action) => {
            return {
                ...state,
                courseCompriseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
                editCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
                courseCompriseById: null,
                courseCompriseByIdError: null,
                addCourseCompriseError: null,
                editCourseCompriseError: null,
                deleteCourseCompriseError: null,
                changeStatusCourseCompriseError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All CourseComprise action
            .addCase(allCourseCompriseAction.pending, (state, action) => {
                state.allCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCourseCompriseAction.fulfilled, (state, action) => {
                state.allCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                state.allCourseComprises = action.payload?.data ?? [];
            })
            .addCase(allCourseCompriseAction.rejected, (state, action) => {
                state.allCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.allCourseCompriseError = action.payload || action.error.message;
            })
            .addCase(courseCompriseByIdAction.pending, (state, action) => {
                state.courseCompriseByIdError = null;
                state.courseCompriseByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(courseCompriseByIdAction.fulfilled, (state, action) => {
                state.courseCompriseByIdLoadingStatus = LoadingStatus.LOADED;
                state.courseCompriseById = action.payload?.data;
            })
            .addCase(courseCompriseByIdAction.rejected, (state, action) => {
                state.courseCompriseByIdLoadingStatus = LoadingStatus.FAILED;
                state.courseCompriseByIdError = action.payload || action.error.message;
            })

            // Add CourseComprise action
            .addCase(addCourseCompriseAction.pending, (state, action) => {
                state.addCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCourseCompriseAction.fulfilled, (state, action) => {
                state.addCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content added successfully.');
            })
            .addCase(addCourseCompriseAction.rejected, (state, action) => {
                state.addCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.addCourseCompriseError = action.payload || action.error.message;
            })
            // Edit CourseComprise action
            .addCase(editCourseCompriseAction.pending, (state, action) => {
                state.editCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editCourseCompriseAction.fulfilled, (state, action) => {
                state.editCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content updated successfully.');
            })
            .addCase(editCourseCompriseAction.rejected, (state, action) => {
                state.editCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.editCourseCompriseError = action.payload || action.error.message;
            })
            // Delete CourseComprise action
            .addCase(deleteCourseCompriseAction.pending, (state, action) => {
                state.deleteCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCourseCompriseAction.fulfilled, (state, action) => {
                state.deleteCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content deleted successfully.');
            })
            .addCase(deleteCourseCompriseAction.rejected, (state, action) => {
                state.deleteCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.deleteCourseCompriseError = action.payload || action.error.message;
            })
            // Change Status CourseComprise action
            .addCase(changeStatusCourseCompriseAction.pending, (state, action) => {
                state.changeStatusCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusCourseCompriseAction.fulfilled, (state, action) => {
                state.changeStatusCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content status updated successfully.');
            })
            .addCase(changeStatusCourseCompriseAction.rejected, (state, action) => {
                state.changeStatusCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusCourseCompriseError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const courseCompriseReducer = courseCompriseSlice.reducer;

export const courseCompriseActions = courseCompriseSlice.actions;
