/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'reason';

const initialState = entityAdapter.getInitialState({
    allReasonLoadingStatus: LoadingStatus.NOT_LOADED,
    reasonByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addReasonLoadingStatus: LoadingStatus.NOT_LOADED,
    editReasonLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteReasonLoadingStatus: LoadingStatus.NOT_LOADED,
    allReason: [],
    reasonById: null,
    allReasonError: null,
    reasonByIdError: null,
    addReasonError: null,
    editReasonError: null,
    deleteReasonError: null
});

/**
 * All Reason Action
 */

export const allReasonAction = createAsyncThunk(`${SLICE_NAME}/allReasonAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/reasons',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Reason By Id Action
 */

export const ReasonByIdAction = createAsyncThunk(`${SLICE_NAME}/ReasonByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-reasons',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add Reason Action
 */

export const addReasonAction = createAsyncThunk(`${SLICE_NAME}/addReasonAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/reasons',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit Reason Action
 */

export const editReasonAction = createAsyncThunk(`${SLICE_NAME}/editReasonAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/reasons/update',
            method: 'PUT',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete Reason Action
 */

export const deleteReasonAction = createAsyncThunk(`${SLICE_NAME}/deleteReasonAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/reasons/destroy',
            method: 'DELETE',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const reasonSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetReasonState: (state, action) => {
            return {
                ...state,
                addReasonLoadingStatus: LoadingStatus.NOT_LOADED,
                editReasonLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteReasonLoadingStatus: LoadingStatus.NOT_LOADED,
                addReasonError: null,
                editReasonError: null,
                deleteReasonError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All Reason action
            .addCase(allReasonAction.pending, (state, action) => {
                state.allReasonError = null;
                state.allReasonLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allReasonAction.fulfilled, (state, action) => {
                state.allReasonLoadingStatus = LoadingStatus.LOADED;
                state.allReason = action.payload?.data;
            })
            .addCase(allReasonAction.rejected, (state, action) => {
                state.allReasonLoadingStatus = LoadingStatus.FAILED;
                state.allReasonError = action.payload || action.error.message;
            })
            //  Reason by id action
            .addCase(ReasonByIdAction.pending, (state, action) => {
                state.ReasonByIdError = null;
                state.ReasonByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(ReasonByIdAction.fulfilled, (state, action) => {
                state.ReasonByIdLoadingStatus = LoadingStatus.LOADED;
                state.ReasonById = action.payload?.data?.reason_data;
            })
            .addCase(ReasonByIdAction.rejected, (state, action) => {
                state.ReasonByIdLoadingStatus = LoadingStatus.FAILED;
                state.ReasonByIdError = action.payload || action.error.message;
            })
            // Add Reason action
            .addCase(addReasonAction.pending, (state, action) => {
                state.addReasonError = null;
                state.addReasonLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addReasonAction.fulfilled, (state, action) => {
                state.addReasonLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Event added successfully.');
            })
            .addCase(addReasonAction.rejected, (state, action) => {
                state.addReasonLoadingStatus = LoadingStatus.FAILED;
                state.addReasonError = action.payload || action.error.message;
            })
            // Edit Reason action
            .addCase(editReasonAction.pending, (state, action) => {
                state.editReasonError = null;
                state.editReasonLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editReasonAction.fulfilled, (state, action) => {
                state.editReasonLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Event updated successfully.');
            })
            .addCase(editReasonAction.rejected, (state, action) => {
                state.editReasonLoadingStatus = LoadingStatus.FAILED;
                state.editReasonError = action.payload || action.error.message;
            })
            // Delete Reason action
            .addCase(deleteReasonAction.pending, (state, action) => {
                state.deleteReasonError = null;
                state.deleteReasonLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteReasonAction.fulfilled, (state, action) => {
                state.deleteReasonLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Event deleted successfully.');
            })
            .addCase(deleteReasonAction.rejected, (state, action) => {
                state.deleteReasonLoadingStatus = LoadingStatus.FAILED;
                state.deleteReasonError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const ReasonReducer = reasonSlice.reducer;

export const reasonActions = reasonSlice.actions;
