/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { ApiPath } from 'utils/constant';
import { LoadingStatus } from 'utils/constant';
import LocalStorage from 'utils/localStorage';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'auth';

const initialState = entityAdapter.getInitialState({
    loginLoadingStatus: LoadingStatus.NOT_LOADED,
    forgotPasswordLoadingStatus: LoadingStatus.NOT_LOADED,
    stayLoginLoadingStatus: LoadingStatus.NOT_LOADED,
    userDetails: null,
    isLoggedin: false,
    loginError: null,
    forgotPasswordError: null
});

/**
 *  Stay Login Action
 */

export const stayLoginAction = createAsyncThunk('auth/stayLoginAction', async (params, thunkAPI) => {
    try {
        const userDetail = LocalStorage.getItem(LocalStorage.keys.USER_DETAILS);
        console.log(userDetail);
        if (userDetail) {
            return JSON.parse(userDetail);
        } else {
            return thunkAPI.rejectWithValue('Logout');
        }
    } catch (error) {
        console.log('err', error);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Login Action
 */

export const loginAction = createAsyncThunk('auth/loginAction', async (params, thunkAPI) => {
    try {
        try {
            const result = await axiosRequest({
                url: '/admin-login',
                method: 'POST',
                data: params
            });

            return result;
        } catch (error) {
            console.log('err', error);
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * forgot password Action
 */

export const forgotPasswordAction = createAsyncThunk('auth/forgotPasswordAction', async (params, thunkAPI) => {
    try {
        // Api for login
        const reqBody = {
            email: params?.email,
            isAdmin: true
        };
        const result = await axiosRequest({
            url: ApiPath.FORGOT_PASS,
            method: 'POST',
            data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Logout Action
 */

export const logoutAction = createAsyncThunk('auth/logout', async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/admin-logout',
            method: 'POST',
            data: params
        });
        LocalStorage.clearAllItem();
        return true;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const authSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetauthState: (state, action) => {
            return {
                ...state
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // stay login action
            .addCase(stayLoginAction.fulfilled, (state, action) => {
                state.stayLoginLoadingStatus = LoadingStatus.LOADED;
                state.isLoggedin = true;
            })
            // logout action
            .addCase(logoutAction.fulfilled, (state, action) => {
                state.isLoggedin = false;
                ToastMessage.success('You have logged out successfully.');
            })
            // login action
            .addCase(loginAction.pending, (state, action) => {
                state.loginError = null;
                state.loginLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                state.loginLoadingStatus = LoadingStatus.LOADED;
                state.isLoggedin = true;
                state.userDetail = action.payload;
                LocalStorage.setItem(LocalStorage.keys.USER_DETAILS, JSON.stringify(action.payload));
                ToastMessage.success('You have logged in successfully.');
            })
            .addCase(loginAction.rejected, (state, action) => {
                state.loginLoadingStatus = LoadingStatus.FAILED;
                state.loginError = action.payload || action.error.message;
                ToastMessage.error(state.loginError);
            })
            // forgot password action
            .addCase(forgotPasswordAction.pending, (state, action) => {
                state.forgotPasswordError = null;
                state.forgotPasswordLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(forgotPasswordAction.fulfilled, (state, action) => {
                state.forgotPasswordLoadingStatus = LoadingStatus.LOADED;
            })
            .addCase(forgotPasswordAction.rejected, (state, action) => {
                state.forgotPasswordLoadingStatus = LoadingStatus.FAILED;
                state.forgotPasswordError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
