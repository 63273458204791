/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'spalashScreen';

const initialState = entityAdapter.getInitialState({
    allSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
    spalashScreenByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
    editSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
    allSplashScreens: [],
    spalashScreenById: null,
    allSplashScreenError: null,
    addSplashScreenError: null,
    editSplashScreenError: null,
    deleteSplashScreenError: null,
    changeStatusSplashScreenError: null,
    spalashScreenByIdError: null
});

/**
 * All SplashScreen Action
 */

export const allSplashScreenAction = createAsyncThunk(`${SLICE_NAME}/allSplashScreenAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/splash-screen',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add SplashScreen Action
 */

export const addSplashScreenAction = createAsyncThunk(`${SLICE_NAME}/addSplashScreenAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/splash-screen-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  SplashScreen by ID Action
 */

export const spalashScreenByIdAction = createAsyncThunk(`${SLICE_NAME}/spalashScreenByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params, 'Params');
        const result = await axiosRequest({
            url: '/splash-screen-view/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit SplashScreen Action
 */

export const editSplashScreenAction = createAsyncThunk(`${SLICE_NAME}/editSplashScreenAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/splash-screen-update',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete SplashScreen Action
 */

export const deleteSplashScreenAction = createAsyncThunk(`${SLICE_NAME}/deleteSplashScreenAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/splash-screen-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status SplashScreen Action
 */

export const changeStatusSplashScreenAction = createAsyncThunk(`${SLICE_NAME}/changeStatusSplashScreenAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/splash-screen-status',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const spalashScreenSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetSplashScreenState: (state, action) => {
            return {
                ...state,
                spalashScreenByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
                editSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusSplashScreenLoadingStatus: LoadingStatus.NOT_LOADED,
                spalashScreenById: null,
                spalashScreenByIdError: null,
                addSplashScreenError: null,
                editSplashScreenError: null,
                deleteSplashScreenError: null,
                changeStatusSplashScreenError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All SplashScreen action
            .addCase(allSplashScreenAction.pending, (state, action) => {
                state.allSplashScreenLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allSplashScreenAction.fulfilled, (state, action) => {
                state.allSplashScreenLoadingStatus = LoadingStatus.LOADED;
                state.allSplashScreens = action.payload?.data ?? [];
            })
            .addCase(allSplashScreenAction.rejected, (state, action) => {
                state.allSplashScreenLoadingStatus = LoadingStatus.FAILED;
                state.allSplashScreenError = action.payload || action.error.message;
            })
            .addCase(spalashScreenByIdAction.pending, (state, action) => {
                state.spalashScreenByIdError = null;
                state.spalashScreenByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(spalashScreenByIdAction.fulfilled, (state, action) => {
                state.spalashScreenByIdLoadingStatus = LoadingStatus.LOADED;
                state.spalashScreenById = action.payload?.data;
            })
            .addCase(spalashScreenByIdAction.rejected, (state, action) => {
                state.spalashScreenByIdLoadingStatus = LoadingStatus.FAILED;
                state.spalashScreenByIdError = action.payload || action.error.message;
            })

            // Add SplashScreen action
            .addCase(addSplashScreenAction.pending, (state, action) => {
                state.addSplashScreenLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addSplashScreenAction.fulfilled, (state, action) => {
                state.addSplashScreenLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Splash Screen added successfully.');
            })
            .addCase(addSplashScreenAction.rejected, (state, action) => {
                state.addSplashScreenLoadingStatus = LoadingStatus.FAILED;
                state.addSplashScreenError = action.payload || action.error.message;
            })
            // Edit SplashScreen action
            .addCase(editSplashScreenAction.pending, (state, action) => {
                state.editSplashScreenLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editSplashScreenAction.fulfilled, (state, action) => {
                state.editSplashScreenLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Splash Screen updated successfully.');
            })
            .addCase(editSplashScreenAction.rejected, (state, action) => {
                state.editSplashScreenLoadingStatus = LoadingStatus.FAILED;
                state.editSplashScreenError = action.payload || action.error.message;
            })
            // Delete SplashScreen action
            .addCase(deleteSplashScreenAction.pending, (state, action) => {
                state.deleteSplashScreenLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteSplashScreenAction.fulfilled, (state, action) => {
                state.deleteSplashScreenLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Splash Screen deleted successfully.');
            })
            .addCase(deleteSplashScreenAction.rejected, (state, action) => {
                state.deleteSplashScreenLoadingStatus = LoadingStatus.FAILED;
                state.deleteSplashScreenError = action.payload || action.error.message;
            })
            // Change Status SplashScreen action
            .addCase(changeStatusSplashScreenAction.pending, (state, action) => {
                state.changeStatusSplashScreenLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusSplashScreenAction.fulfilled, (state, action) => {
                state.changeStatusSplashScreenLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Splash Screen status updated successfully.');
            })
            .addCase(changeStatusSplashScreenAction.rejected, (state, action) => {
                state.changeStatusSplashScreenLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusSplashScreenError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const spalashScreenReducer = spalashScreenSlice.reducer;

export const spalashScreenActions = spalashScreenSlice.actions;
