/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'notifications';

const initialState = entityAdapter.getInitialState({
    allNotificationsLoadingStatus: LoadingStatus.NOT_LOADED,
    notificationByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addNotificationLoadingStatus: LoadingStatus.NOT_LOADED,
    editNotificationLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteNotificationLoadingStatus: LoadingStatus.NOT_LOADED,
    allNotifications: [],
    notificationById: null,
    allNotificationError: null,
    notificationsByIdError: null,
    addNotificationError: null,
    editNotificationError: null,
    deleteNotificationError: null
});

/**
 * All Notifications Action
 */

export const allNotificationsAction = createAsyncThunk(`${SLICE_NAME}/allNotificationsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/show-notification',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Notification by ID Action
 */

export const notificationByIdAction = createAsyncThunk(`${SLICE_NAME}/notificationByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-notification',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add Notifications Action
 */

export const addNotificationAction = createAsyncThunk(`${SLICE_NAME}/addNotificationAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-notification',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit Notification Action
 */

export const editNotificationAction = createAsyncThunk(`${SLICE_NAME}/editNotificationAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-notification',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete Notification Action
 */

export const deleteNotificationAction = createAsyncThunk(`${SLICE_NAME}/deleteNotificationAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-notification',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const notificationsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetNotificationsState: (state, action) => {
            return {
                ...state,
                notificationByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addNotificationLoadingStatus: LoadingStatus.NOT_LOADED,
                editNotificationLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteNotificationLoadingStatus: LoadingStatus.NOT_LOADED,
                notificationById: null,
                notificationsByIdError: null,
                addNotificationError: null,
                editNotificationError: null,
                deleteNotificationError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All Notification action
            .addCase(allNotificationsAction.pending, (state, action) => {
                state.allNotificationError = null;
                state.allNotificationsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allNotificationsAction.fulfilled, (state, action) => {
                state.allNotificationsLoadingStatus = LoadingStatus.LOADED;
                state.allNotifications = action.payload?.data ?? [];
            })
            .addCase(allNotificationsAction.rejected, (state, action) => {
                state.allNotificationsLoadingStatus = LoadingStatus.FAILED;
                state.allNotificationError = action.payload || action.error.message;
            })
            //  Notifications by id action
            .addCase(notificationByIdAction.pending, (state, action) => {
                state.notificationrByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(notificationByIdAction.fulfilled, (state, action) => {
                state.notificationByIdLoadingStatus = LoadingStatus.LOADED;
                state.notificationById = action.payload?.data ?? [];
            })
            .addCase(notificationByIdAction.rejected, (state, action) => {
                state.notificationByIdLoadingStatus = LoadingStatus.FAILED;
                state.notificationsByIdError = action.payload || action.error.message;
            })
            // Add Notification action
            .addCase(addNotificationAction.pending, (state, action) => {
                state.addNotificationLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addNotificationAction.fulfilled, (state, action) => {
                state.addNotificationLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Notification added successfully.');
            })
            .addCase(addNotificationAction.rejected, (state, action) => {
                state.addNotificationLoadingStatus = LoadingStatus.FAILED;
                state.addNotificationError = action.payload || action.error.message;
            })
            // Edit Notification action
            .addCase(editNotificationAction.pending, (state, action) => {
                state.editNotificationLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editNotificationAction.fulfilled, (state, action) => {
                state.editNotificationLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Notification updated successfully.');
            })
            .addCase(editNotificationAction.rejected, (state, action) => {
                state.editNotificationLoadingStatus = LoadingStatus.FAILED;
                state.editNotificationError = action.payload || action.error.message;
            })
            // Delete Notification action
            .addCase(deleteNotificationAction.pending, (state, action) => {
                state.deleteNotificationLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteNotificationAction.fulfilled, (state, action) => {
                state.deleteNotificationLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Notification deleted successfully.');
            })
            .addCase(deleteNotificationAction.rejected, (state, action) => {
                state.deleteNotificationLoadingStatus = LoadingStatus.FAILED;
                state.deleteNotificationError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const notificationReducer = notificationsSlice.reducer;

export const notificationActions = notificationsSlice.actions;
