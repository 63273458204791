/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'ratingAndReview';

const initialState = entityAdapter.getInitialState({
    allRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    ratingAndReviewByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    editRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    allRatingAndReview: [],
    ratingAndReviewById: null,
    allRatingAndReviewError: null,
    ratingAndReviewByIdError: null,
    addRatingAndReviewError: null,
    editRatingAndReviewError: null,
    deleteRatingAndReviewError: null,
    changeStatusRatingAndReviewError: null
});

/**
 * All ratingAndReview Action
 */

export const allRatingAndReviewAction = createAsyncThunk(`${SLICE_NAME}/allRatingAndReviewAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-rating',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  ratingAndReview By Id Action
 */

export const ratingAndReviewByIdAction = createAsyncThunk(`${SLICE_NAME}/ratingAndReviewByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-rating',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit ratingAndReview Action
 */

export const editRatingAndReviewAction = createAsyncThunk(`${SLICE_NAME}/editRatingAndReviewAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-event-gallery',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete ratingAndReview Action
 */

export const deleteRatingAndReviewAction = createAsyncThunk(`${SLICE_NAME}/deleteRatingAndReviewAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-event-gallery',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * change status ratingAndReview Action
 */

export const changeStatusRatingAndReviewAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusRatingAndReviewAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/update-rating',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const ratingAndReviewSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetRatingAndReviewState: (state, action) => {
            return {
                ...state,
                ratingAndReviewByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                editRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusRatingAndReviewError: null,
                ratingAndReviewById: null,
                ratingAndReviewByIdError: null,
                addRatingAndReviewError: null,
                editRatingAndReviewError: null,
                deleteRatingAndReviewError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All ratingAndReview action
            .addCase(allRatingAndReviewAction.pending, (state, action) => {
                state.allRatingAndReviewError = null;
                state.allRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allRatingAndReviewAction.fulfilled, (state, action) => {
                state.allRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                state.allRatingAndReview = action.payload?.data;
            })
            .addCase(allRatingAndReviewAction.rejected, (state, action) => {
                state.allRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.allRatingAndReviewError = action.payload || action.error.message;
            })
            //  ratingAndReview by id action
            .addCase(ratingAndReviewByIdAction.pending, (state, action) => {
                state.ratingAndReviewByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(ratingAndReviewByIdAction.fulfilled, (state, action) => {
                state.ratingAndReviewByIdLoadingStatus = LoadingStatus.LOADED;
                state.ratingAndReviewById = action.payload?.data?.[0];
            })
            .addCase(ratingAndReviewByIdAction.rejected, (state, action) => {
                state.ratingAndReviewByIdLoadingStatus = LoadingStatus.FAILED;
                state.ratingAndReviewByIdError = action.payload || action.error.message;
            })

            // Edit ratingAndReview action
            .addCase(editRatingAndReviewAction.pending, (state, action) => {
                state.editRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editRatingAndReviewAction.fulfilled, (state, action) => {
                state.editRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Rating and review updated successfully.');
            })
            .addCase(editRatingAndReviewAction.rejected, (state, action) => {
                state.editRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.editRatingAndReviewError = action.payload || action.error.message;
            })
            // Delete ratingAndReview action
            .addCase(deleteRatingAndReviewAction.pending, (state, action) => {
                state.deleteRatingAndReviewError = null;
                state.deleteRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteRatingAndReviewAction.fulfilled, (state, action) => {
                state.deleteRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Rating and review deleted successfully.');
            })
            .addCase(deleteRatingAndReviewAction.rejected, (state, action) => {
                state.deleteRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.deleteRatingAndReviewError = action.payload || action.error.message;
            })
            // change status ratingAndReview action
            .addCase(changeStatusRatingAndReviewAction.pending, (state, action) => {
                state.changeStatusRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusRatingAndReviewAction.fulfilled, (state, action) => {
                state.changeStatusRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Rating and review status updated successfully.');
            })
            .addCase(changeStatusRatingAndReviewAction.rejected, (state, action) => {
                state.changeStatusRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusRatingAndReviewError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const ratingAndReviewReducer = ratingAndReviewSlice.reducer;

export const ratingAndReviewActions = ratingAndReviewSlice.actions;
