/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'courselevel';

const initialState = entityAdapter.getInitialState({
    courseLevelLoadingStatus: LoadingStatus.NOT_LOADED,
    updateLevelLoadingStatus: LoadingStatus.NOT_LOADED,
    levelByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addLevelLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteCourseLevelLoadingStatus: LoadingStatus.NOT_LOADED,
    courseLevelList: [],
    levelById: null,
    setPaymentStatus: null,
    courseLevelError: null,
    levelByIdError: null,
    AddCourseLevelError: null,
    updateCourseLevelModeError: null,
    deleteCourseLevelError: null
});

/**
 * All Level Action
 */

export const allCourseLevelAction = createAsyncThunk(`${SLICE_NAME}/allCourseLevelAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/admin-course-level',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All Level Action
 */

export const AddCourseLevelAction = createAsyncThunk(`${SLICE_NAME}/AddCourseLevelAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-level',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Get Level By Id
 */
export const getCourseLevelById = createAsyncThunk(`${SLICE_NAME}/getCourseLevelById`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/course-level/${params.id}`, // Assuming you need to get a specific level by ID
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response.data : error.data);
    }
});

/**
 * Update Contact Us Action
 */

export const updateLevelAction = createAsyncThunk(`${SLICE_NAME}/updateLevelAction`, async (params, thunkAPI) => {
    let reqbody = {
        id: params.id,
        name: params.name
    };
    try {
        const result = await axiosRequest({
            url: '/update-course-level',
            method: 'POST',
            data: reqbody
        });
        // console.log(result);
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete CourseLevel Action
 */

export const deleteCourseLevelAction = createAsyncThunk(`${SLICE_NAME}/deleteCourseLevelAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-level-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const courseLevelSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetPostersState: (state, action) => {
            return {
                ...state,
                addLevelLoadingStatus: LoadingStatus.NOT_LOADED,
                updateLevelLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCourseLevelLoadingStatus: LoadingStatus.NOT_LOADED,
                AddCourseLevelError: null,
                updateCourseLevelModeError: null,
                deleteCourseLevelError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All ContactUs action
            .addCase(allCourseLevelAction.pending, (state, action) => {
                state.courseLevelError = null;
                state.courseLevelLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCourseLevelAction.fulfilled, (state, action) => {
                state.courseLevelLoadingStatus = LoadingStatus.LOADED;
                state.courseLevelList = action.payload?.data ?? [];
                state.setPaymentStatus = action.payload?.data[0]?.status ?? [];
            })
            .addCase(allCourseLevelAction.rejected, (state, action) => {
                state.courseLevelLoadingStatus = LoadingStatus.FAILED;
                state.courseLevelError = action.payload || action.error.message;
            })

            // Add Level action
            .addCase(AddCourseLevelAction.pending, (state, action) => {
                state.AddCourseLevelError = null;
                state.addLevelLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(AddCourseLevelAction.fulfilled, (state, action) => {
                state.addLevelLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course level added successfully.');
            })
            .addCase(AddCourseLevelAction.rejected, (state, action) => {
                state.addLevelLoadingStatus = LoadingStatus.FAILED;
                state.AddCourseLevelError = action.payload || action.error.message;
            })

            // ProductDeliver by id action
            .addCase(getCourseLevelById.pending, (state, action) => {
                state.levelByIdError = null;
                state.levelByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getCourseLevelById.fulfilled, (state, action) => {
                state.levelByIdLoadingStatus = LoadingStatus.LOADED;
                state.levelById = action.payload?.data;
            })
            .addCase(getCourseLevelById.rejected, (state, action) => {
                state.levelByIdLoadingStatus = LoadingStatus.FAILED;
                state.levelByIdError = action.payload || action.error.message;
            })

            // All updatePaymentMode action
            .addCase(updateLevelAction.pending, (state, action) => {
                state.updateCourseLevelModeError = null;
                state.updateLevelLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(updateLevelAction.fulfilled, (state, action) => {
                state.updateLevelLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Details updated successfully.');
            })
            .addCase(updateLevelAction.rejected, (state, action) => {
                state.updateLevelLoadingStatus = LoadingStatus.FAILED;
                state.updateCourseLevelModeError = action.payload || action.error.message;
            })

            // Delete CourseLevel action
            .addCase(deleteCourseLevelAction.pending, (state, action) => {
                state.deleteCourseLevelError = null;
                state.deleteCourseLevelLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCourseLevelAction.fulfilled, (state, action) => {
                state.deleteCourseLevelLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data deleted successfully.');
            })
            .addCase(deleteCourseLevelAction.rejected, (state, action) => {
                state.deleteCourseLevelLoadingStatus = LoadingStatus.FAILED;
                state.deleteCourseLevelError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const courseLevelReducer = courseLevelSlice.reducer;

export const contactUsActions = courseLevelSlice.actions;
