/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'generalSettings';

const initialState = entityAdapter.getInitialState({
    allSuccessStoriesLoadingStatus: LoadingStatus.NOT_LOADED,
    successStoryByIDLoadingStatus: LoadingStatus.NOT_LOADED,
    addSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
    editSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
    allMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
    meetTeamByIDLoadingStatus: LoadingStatus.NOT_LOADED,
    addMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
    editMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
    allSuccessStories: [],
    successStoryById: null,
    allMeetTeam: [],
    meetTeamById: null,
    allSuccessStoriesError: null,
    successStoryByIDError: null,
    addSuccessStoryError: null,
    editSuccessStoryError: null,
    deleteSuccessStoryError: null,
    changeStatusSuccessStoryError: null,
    allMeetTeamError: null,
    meetTeamByIDError: null,
    addMeetTeamError: null,
    editMeetTeamError: null,
    deleteMeetTeamError: null,
    changeStatusMeetTeamError: null,

    allWalkthroughsLoadingStatus: LoadingStatus.NOT_LOADED,
    walkthroughByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
    editWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
    allWalkthroughs: [],
    walkthroughById: null,
    allWalkthroughsError: null,
    walkthroughByIdError: null,
    addWalkthroughError: null,
    editWalkthroughError: null,
    deleteWalkthroughError: null,
    changeStatusWalkthroughError: null
});

/**
 * All SuccessStory Action
 */

export const allSuccessStoriesAction = createAsyncThunk(`${SLICE_NAME}/allSuccessStoriesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/show-success-stories',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  SuccessStory By ID Action
 */

export const successStoryByIDAction = createAsyncThunk(`${SLICE_NAME}/successStoryByIDAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/views-success-stories',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add SuccessStory Action
 */

export const addSuccessStoryAction = createAsyncThunk(`${SLICE_NAME}/addSuccessStoryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-success-stories',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit SuccessStory Action
 */

export const editSuccessStoryAction = createAsyncThunk(`${SLICE_NAME}/editSuccessStoryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-success-stories',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete SuccessStory Action
 */

export const deleteSuccessStoryAction = createAsyncThunk(`${SLICE_NAME}/deleteSuccessStoryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-success-stories',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status SuccessStory Action
 */

export const changeStatusSuccessStoryAction = createAsyncThunk(`${SLICE_NAME}/changeStatusSuccessStoryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/active-success-stories',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All Meet Team Action
 */

export const allMeetTeamAction = createAsyncThunk(`${SLICE_NAME}/allMeetTeamAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/show-team',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Meet Team By ID Action
 */

export const meetTeamByIDAction = createAsyncThunk(`${SLICE_NAME}/meetTeamByIDAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-team-member',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add Meet Team Action
 */

export const addMeetTeamAction = createAsyncThunk(`${SLICE_NAME}/addMeetTeamAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-team',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit Meet Team Action
 */

export const editMeetTeamAction = createAsyncThunk(`${SLICE_NAME}/editMeetTeamAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-team-member',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * delete meet team Action
 */

export const deleteMeetTeamAction = createAsyncThunk(`${SLICE_NAME}/deleteMeetTeamAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-team-member',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status SuccessStory Action
 */

export const changeStatusMeetTeamAction = createAsyncThunk(`${SLICE_NAME}/changeStatusMeetTeamAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/active-team-member',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All Walkthrough Action
 */

export const allWalkthroughAction = createAsyncThunk(`${SLICE_NAME}/allWalkthroughAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/listing-flash-screen',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Walkthrough By ID Action
 */

export const walkthroughByIDAction = createAsyncThunk(`${SLICE_NAME}/walkthroughByIDAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-flash-screen',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add Walkthrough Action
 */

export const addWalkthroughAction = createAsyncThunk(`${SLICE_NAME}/addWalkthroughAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-flash-screen',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit Walkthrough Action
 */

export const editWalkthroughAction = createAsyncThunk(`${SLICE_NAME}/editWalkthroughAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-flash-screen',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * delete Walkthrough Action
 */

export const deleteWalkthroughAction = createAsyncThunk(`${SLICE_NAME}/deleteWalkthroughAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-flash-screen',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status Walkthrough Action
 */

export const changeStatusWalkthroughAction = createAsyncThunk(`${SLICE_NAME}/changeStatusWalkthroughAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/status-flash-screen',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

//  All Meet Team Action

export const allReasonData = createAsyncThunk(`${SLICE_NAME}/allReasonData`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/reason',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const generalSettingsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetSuccessStoryState: (state, action) => {
            return {
                ...state,
                addSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
                editSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusSuccessStoryLoadingStatus: LoadingStatus.NOT_LOADED,
                addSuccessStoryError: null,
                editSuccessStoryError: null,
                deleteSuccessStoryError: null,
                changeStatusSuccessStoryError: null
            };
        },
        resetMeetTeamState: (state, action) => {
            return {
                ...state,
                addMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
                editMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusMeetTeamLoadingStatus: LoadingStatus.NOT_LOADED,
                addMeetTeamError: null,
                editMeetTeamError: null,
                deleteMeetTeamError: null,
                changeStatusMeetTeamError: null
            };
        },
        resetWalkthroughState: (state, action) => {
            return {
                ...state,
                addWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
                editWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusWalkthroughLoadingStatus: LoadingStatus.NOT_LOADED,
                addWalkthroughError: null,
                editWalkthroughError: null,
                deleteWalkthroughError: null,
                changeStatusWalkthroughError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All Success Story
            .addCase(allSuccessStoriesAction.pending, (state, action) => {
                state.allSuccessStoriesError = null;
                state.allSuccessStoriesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allSuccessStoriesAction.fulfilled, (state, action) => {
                state.allSuccessStoriesLoadingStatus = LoadingStatus.LOADED;
                state.allSuccessStories = action.payload?.data;
            })
            .addCase(allSuccessStoriesAction.rejected, (state, action) => {
                state.allSuccessStoriesLoadingStatus = LoadingStatus.FAILED;
                state.allSuccessStoriesError = action.payload || action.error.message;
            })
            // Success Story By ID action
            .addCase(successStoryByIDAction.pending, (state, action) => {
                state.successStoryByIDError = null;
                state.successStoryById = null;
                state.successStoryByIDLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(successStoryByIDAction.fulfilled, (state, action) => {
                state.successStoryByIDLoadingStatus = LoadingStatus.LOADED;
                state.successStoryById = action.payload?.data;
            })
            .addCase(successStoryByIDAction.rejected, (state, action) => {
                state.successStoryByIDLoadingStatus = LoadingStatus.FAILED;
                state.successStoryByIDError = action.payload || action.error.message;
            })
            // Add Success Story action
            .addCase(addSuccessStoryAction.pending, (state, action) => {
                state.addSuccessStoryError = null;
                state.addSuccessStoryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addSuccessStoryAction.fulfilled, (state, action) => {
                state.addSuccessStoryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Success Story added successfully.');
            })
            .addCase(addSuccessStoryAction.rejected, (state, action) => {
                state.addSuccessStoryLoadingStatus = LoadingStatus.FAILED;
                state.addSuccessStoryError = action.payload || action.error.message;
            })
            // Edit Success Story action
            .addCase(editSuccessStoryAction.pending, (state, action) => {
                state.editSuccessStoryError = null;
                state.editSuccessStoryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editSuccessStoryAction.fulfilled, (state, action) => {
                state.editSuccessStoryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Success Story updated successfully.');
            })
            .addCase(editSuccessStoryAction.rejected, (state, action) => {
                state.editSuccessStoryLoadingStatus = LoadingStatus.FAILED;
                state.editSuccessStoryError = action.payload || action.error.message;
            })
            // delete success story action
            .addCase(deleteSuccessStoryAction.pending, (state, action) => {
                state.deleteSuccessStoryError = null;
                state.deleteSuccessStoryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteSuccessStoryAction.fulfilled, (state, action) => {
                state.deleteSuccessStoryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Success Story deleted successfully.');
            })
            .addCase(deleteSuccessStoryAction.rejected, (state, action) => {
                state.deleteSuccessStoryLoadingStatus = LoadingStatus.FAILED;
                state.deleteSuccessStoryError = action.payload || action.error.message;
            })
            // change status success story action
            .addCase(changeStatusSuccessStoryAction.pending, (state, action) => {
                state.changeStatusSuccessStoryError = null;
                state.changeStatusSuccessStoryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusSuccessStoryAction.fulfilled, (state, action) => {
                state.changeStatusSuccessStoryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Success Story status updated successfully.');
            })
            .addCase(changeStatusSuccessStoryAction.rejected, (state, action) => {
                state.changeStatusSuccessStoryLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusSuccessStoryError = action.payload || action.error.message;
            })
            // All Meet team action
            .addCase(allMeetTeamAction.pending, (state, action) => {
                state.allMeetTeamError = null;
                state.allMeetTeamLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allMeetTeamAction.fulfilled, (state, action) => {
                state.allMeetTeamLoadingStatus = LoadingStatus.LOADED;
                state.allMeetTeam = action.payload.data;
            })
            .addCase(allMeetTeamAction.rejected, (state, action) => {
                state.allMeetTeamLoadingStatus = LoadingStatus.FAILED;
                state.allMeetTeamError = action.payload || action.error.message;
            })
            // Meet Team By ID action
            .addCase(meetTeamByIDAction.pending, (state, action) => {
                state.meetTeamByIDError = null;
                state.meetTeamByIDLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(meetTeamByIDAction.fulfilled, (state, action) => {
                state.meetTeamByIDLoadingStatus = LoadingStatus.LOADED;
                state.meetTeamById = action.payload?.data;
            })
            .addCase(meetTeamByIDAction.rejected, (state, action) => {
                state.meetTeamByIDLoadingStatus = LoadingStatus.FAILED;
                state.meetTeamByIDError = action.payload || action.error.message;
            })
            // Add meet team action
            .addCase(addMeetTeamAction.pending, (state, action) => {
                state.addMeetTeamError = null;
                state.addMeetTeamLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addMeetTeamAction.fulfilled, (state, action) => {
                state.addMeetTeamLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Meet team added successfully.');
            })
            .addCase(addMeetTeamAction.rejected, (state, action) => {
                state.addMeetTeamLoadingStatus = LoadingStatus.FAILED;
                state.addMeetTeamError = action.payload || action.error.message;
            })
            // Edit meet team action
            .addCase(editMeetTeamAction.pending, (state, action) => {
                state.editMeetTeamError = null;
                state.editMeetTeamLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editMeetTeamAction.fulfilled, (state, action) => {
                state.editMeetTeamLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Meet team updated successfully.');
            })
            .addCase(editMeetTeamAction.rejected, (state, action) => {
                state.editMeetTeamLoadingStatus = LoadingStatus.FAILED;
                state.editMeetTeamError = action.payload || action.error.message;
            })
            // delete meet team action
            .addCase(deleteMeetTeamAction.pending, (state, action) => {
                state.deleteMeetTeamError = null;
                state.deleteMeetTeamLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteMeetTeamAction.fulfilled, (state, action) => {
                state.deleteMeetTeamLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Meet team delete successfully.');
            })
            .addCase(deleteMeetTeamAction.rejected, (state, action) => {
                state.deleteMeetTeamLoadingStatus = LoadingStatus.FAILED;
                state.deleteMeetTeamError = action.payload || action.error.message;
            })
            // change status meet team  action
            .addCase(changeStatusMeetTeamAction.pending, (state, action) => {
                state.changeStatusMeetTeamError = null;
                state.changeStatusMeetTeamLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusMeetTeamAction.fulfilled, (state, action) => {
                state.changeStatusMeetTeamLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Meet Team status updated successfully.');
            })
            .addCase(changeStatusMeetTeamAction.rejected, (state, action) => {
                state.changeStatusMeetTeamLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusMeetTeamError = action.payload || action.error.message;
            })
            // All Walkthrough action
            .addCase(allWalkthroughAction.pending, (state, action) => {
                state.allWalkthroughsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allWalkthroughAction.fulfilled, (state, action) => {
                state.allWalkthroughsLoadingStatus = LoadingStatus.LOADED;
                state.allWalkthroughs = action.payload.data;
            })
            .addCase(allWalkthroughAction.rejected, (state, action) => {
                state.allWalkthroughsLoadingStatus = LoadingStatus.FAILED;
                state.allWalkthroughsError = action.payload || action.error.message;
            })
            // Walkthrough By ID action
            .addCase(walkthroughByIDAction.pending, (state, action) => {
                state.walkthroughByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(walkthroughByIDAction.fulfilled, (state, action) => {
                state.walkthroughByIdLoadingStatus = LoadingStatus.LOADED;
                state.walkthroughById = action.payload?.data[0];
            })
            .addCase(walkthroughByIDAction.rejected, (state, action) => {
                state.walkthroughByIdLoadingStatus = LoadingStatus.FAILED;
                state.walkthroughByIdError = action.payload || action.error.message;
            })
            // Add Walkthrough action
            .addCase(addWalkthroughAction.pending, (state, action) => {
                state.addWalkthroughLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addWalkthroughAction.fulfilled, (state, action) => {
                state.addWalkthroughLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Walkthrough added successfully.');
            })
            .addCase(addWalkthroughAction.rejected, (state, action) => {
                state.addWalkthroughLoadingStatus = LoadingStatus.FAILED;
                state.addWalkthroughError = action.payload || action.error.message;
            })
            // Edit Walkthrough action
            .addCase(editWalkthroughAction.pending, (state, action) => {
                state.editWalkthroughLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editWalkthroughAction.fulfilled, (state, action) => {
                state.editWalkthroughLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Walkthrough updated successfully.');
            })
            .addCase(editWalkthroughAction.rejected, (state, action) => {
                state.editWalkthroughLoadingStatus = LoadingStatus.FAILED;
                state.editWalkthroughError = action.payload || action.error.message;
            })
            // delete Walkthrough action
            .addCase(deleteWalkthroughAction.pending, (state, action) => {
                state.deleteWalkthroughLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteWalkthroughAction.fulfilled, (state, action) => {
                state.deleteWalkthroughLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Walkthrough delete successfully.');
            })
            .addCase(deleteWalkthroughAction.rejected, (state, action) => {
                state.deleteWalkthroughLoadingStatus = LoadingStatus.FAILED;
                state.deleteWalkthroughError = action.payload || action.error.message;
            })
            // change status Walkthrough  action
            .addCase(changeStatusWalkthroughAction.pending, (state, action) => {
                state.changeStatusWalkthroughLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusWalkthroughAction.fulfilled, (state, action) => {
                state.changeStatusWalkthroughLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Walkthrough status updated successfully.');
            })
            .addCase(changeStatusWalkthroughAction.rejected, (state, action) => {
                state.changeStatusWalkthroughLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusWalkthroughError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const generalSettingsReducer = generalSettingsSlice.reducer;

export const generalSettingsActions = generalSettingsSlice.actions;
