/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'fitnessCenter';

const initialState = entityAdapter.getInitialState({
    allFitnessCenterLoading: LoadingStatus.NOT_LOADED,
    fitnessCenterByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
    editFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
    allFitnessCenter: [],
    fitnessCenterById: null,
    allFitnessCenterError: null,
    fitnessCenterByIdError: null,
    addFitnessCenterError: null,
    editFitnessCenterError: null,
    deleteFitnessCenterError: null,
    changeStatusFitnessCenterError: null,
});

/**
 * All PopularCourse Action
 */

export const allFitnessCenterAction = createAsyncThunk(`${SLICE_NAME}/allFitnessCenterAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-fitness-centers',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add PopularCourse Action
 */

export const addFitnessCenterAction = createAsyncThunk(`${SLICE_NAME}/addFitnessCenterAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/fitness-center-store',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  PopularCourse by ID Action
 */

export const fitnessCenterByIdAction = createAsyncThunk(`${SLICE_NAME}/fitnessCenterByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/fitness-center-show/' + params.id,
            method: 'GET'
            // data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit PopularCourse Action
 */

export const editFitnessCenterAction = createAsyncThunk(
    `${SLICE_NAME}/editFitnessCenterAction`,
    async (formData, thunkAPI) => {
        try {
            const id = formData.get("id");
            if (!id) {
                throw new Error("ID is missing in FormData");
            }

            const result = await axiosRequest({
                url: `/fitness-center-update/${id}`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Delete PopularCourse Action
 */

export const deleteFitnessCenterAction = createAsyncThunk(`${SLICE_NAME}/deleteFitnessCenterAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/fitness-center-destroy/' + params.id,
            method: 'DELETE',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status PopularCourse Action
 */

export const changeStatusFitnessCenterAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusFitnessCenterAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/fitness-center-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);
/**
 *  Center Multiple Images by ID Action 
 */

export const getCenterImagesByIdAction = createAsyncThunk(`${SLICE_NAME}/getCenterImagesByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/get-fitness-center-images?center_id=${params.center_id}`,
            method: 'GET',
            params: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  Add Multiple Center Images by ID Action
 */

export const addMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/addMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-fitness-center-images',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete Product Multiple Images by ID Action
 */

export const deleteMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/deleteMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-fitness-center-images',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});



/**
 * Slice for all reducres
 */
const fitnessCenterSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetFitnessCenterState: (state, action) => {
            return {
                ...state,
                fitnessCenterByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
                editFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusFitnessCenterLoadingStatus: LoadingStatus.NOT_LOADED,
                fitnessCenterById: null,
                fitnessCenterByIdError: null,
                addFitnessCenterError: null,
                editFitnessCenterError: null,
                deleteFitnessCenterError: null,
                changeStatusFitnessCenterError: null,
                allCenterImagesListLoadingStatus: LoadingStatus.NOT_LOADED,
                allCenterImagesListError: null,
                allCenterImagesList: [],
                addMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                addMultipleImageError: null,
                deleteMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteMultipleImageError: null,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All PopularCourse action
            .addCase(allFitnessCenterAction.pending, (state, action) => {
                state.allFitnessCenterLoading = LoadingStatus.LOADING;
            })
            .addCase(allFitnessCenterAction.fulfilled, (state, action) => {
                state.allFitnessCenterLoading = LoadingStatus.LOADED;
                state.allFitnessCenter = action.payload?.data ?? [];
            })
            .addCase(allFitnessCenterAction.rejected, (state, action) => {
                state.allFitnessCenterLoading = LoadingStatus.FAILED;
                state.allFitnessCenterError = action.payload || action.error.message;
            })
            //  PopularCourse by id action
            .addCase(fitnessCenterByIdAction.pending, (state, action) => {
                state.fitnessCenterByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(fitnessCenterByIdAction.fulfilled, (state, action) => {
                state.fitnessCenterByIdLoadingStatus = LoadingStatus.LOADED;
                state.fitnessCenterById = action.payload?.data;
            })
            .addCase(fitnessCenterByIdAction.rejected, (state, action) => {
                state.fitnessCenterByIdLoadingStatus = LoadingStatus.FAILED;
                state.fitnessCenterByIdError = action.payload || action.error.message;
            })
            // Add PopularCourse action
            .addCase(addFitnessCenterAction.pending, (state, action) => {
                state.addFitnessCenterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addFitnessCenterAction.fulfilled, (state, action) => {
                state.addFitnessCenterLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data added successfully.');
            })
            .addCase(addFitnessCenterAction.rejected, (state, action) => {
                state.addFitnessCenterLoadingStatus = LoadingStatus.FAILED;
                state.addFitnessCenterError = action.payload || action.error.message;
            })
            // Edit PopularCourse action
            .addCase(editFitnessCenterAction.pending, (state, action) => {
                state.editFitnessCenterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editFitnessCenterAction.fulfilled, (state, action) => {
                state.editFitnessCenterLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data updated successfully.');
            })
            .addCase(editFitnessCenterAction.rejected, (state, action) => {
                state.editFitnessCenterLoadingStatus = LoadingStatus.FAILED;
                state.editFitnessCenterError = action.payload || action.error.message;
            })
            // Delete PopularCourse action
            .addCase(deleteFitnessCenterAction.pending, (state, action) => {
                state.deleteFitnessCenterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteFitnessCenterAction.fulfilled, (state, action) => {
                state.deleteFitnessCenterLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data deleted successfully.');
            })
            .addCase(deleteFitnessCenterAction.rejected, (state, action) => {
                state.deleteFitnessCenterLoadingStatus = LoadingStatus.FAILED;
                state.deleteFitnessCenterError = action.payload || action.error.message;
            })
            // Change Status PopularCourse action
            .addCase(changeStatusFitnessCenterAction.pending, (state, action) => {
                state.changeStatusFitnessCenterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusFitnessCenterAction.fulfilled, (state, action) => {
                state.changeStatusFitnessCenterLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data status updated successfully.');
            })
            .addCase(changeStatusFitnessCenterAction.rejected, (state, action) => {
                state.changeStatusFitnessCenterLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusFitnessCenterError = action.payload || action.error.message;
            })
            // all Center Multiple Images action
            .addCase(getCenterImagesByIdAction.pending, (state, action) => {
                state.allCenterImagesListLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getCenterImagesByIdAction.fulfilled, (state, action) => {
                state.allCenterImagesListLoadingStatus = LoadingStatus.LOADED;
                state.allCenterImagesList = action.payload?.data;
            })
            .addCase(getCenterImagesByIdAction.rejected, (state, action) => {
                state.allCenterImagesListLoadingStatus = LoadingStatus.FAILED;
                state.allCenterImagesListError = action.payload || action.error.message;
                state.allCenterImagesList = [];
            })
            // Add Multiple Images action
            .addCase(addMultipleImageAction.pending, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addMultipleImageAction.fulfilled, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Center image added successfully.');
            })
            .addCase(addMultipleImageAction.rejected, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.addMultipleImageError = action.payload || action.error.message;
            })
            // delete Center Multiple Image action 
            .addCase(deleteMultipleImageAction.pending, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteMultipleImageAction.fulfilled, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Center image deleted successfully.');
            })
            .addCase(deleteMultipleImageAction.rejected, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.deleteMultipleImageError = action.payload || action.error.message;
            })

    }
});

/*
 * Export reducer for store configuration.
 */
export const fitnessCenterReducer = fitnessCenterSlice.reducer;

export const fitnessCenterActions = fitnessCenterSlice.actions;
