/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'course';

const initialState = entityAdapter.getInitialState({
    allCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    courseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    editCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusCourseLoadingStatus: LoadingStatus.NOT_LOADED,
    allCourses: [],
    courseById: null,
    allCourseError: null,
    courseByIdError: null,
    addCourseError: null,
    editCourseError: null,
    deleteCourseError: null,
    changeStatusCourseError: null,
    allCourseDetailsLoadingStatus: LoadingStatus.NOT_LOADED,
    courseDetailByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
    editCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
    allCourseDetails: [],
    courseDetailById: null,
    allCourseDetailsError: null,
    courseDetailByIdError: null,
    addCourseDetailError: null,
    editCourseDetailError: null,
    deleteCourseDetailError: null,
    changeStatusCourseDetailError: null
});

/**
 * All course Action
 */

export const allCoursesAction = createAsyncThunk(`${SLICE_NAME}/allCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/listing-yoga-category',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All course Action
 */

 export const allLevelAction = createAsyncThunk(`${SLICE_NAME}/allLevelAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-level',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});


/**
 *  course by ID Action
 */

export const courseByIdAction = createAsyncThunk(`${SLICE_NAME}/courseByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-yoga-category',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add course Action
 */

export const addCourseAction = createAsyncThunk(`${SLICE_NAME}/addCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-yoga-category',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit course Action
 */

export const editCourseAction = createAsyncThunk(`${SLICE_NAME}/editCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-yoga-category',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete course Action
 */

export const deleteCourseAction = createAsyncThunk(`${SLICE_NAME}/deleteCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-yoga-category',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status Course Action
 */

export const changeStatusCourseAction = createAsyncThunk(`${SLICE_NAME}/changeStatusCourseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-yoga-category-status',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All course details Action
 */

export const allCourseDetailsAction = createAsyncThunk(`${SLICE_NAME}/allCourseDetailsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/listing-chapter-category?category_id=${params.course_id}`,
            // url: `/listing-chapter-category`,
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  course detail by ID Action
 */

export const courseDetailByIdAction = createAsyncThunk(`${SLICE_NAME}/courseDetailByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-chapter-category',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add course detail Action
 */

export const addCourseDetailAction = createAsyncThunk(`${SLICE_NAME}/addCourseDetailAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-chapter-category',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Edit course detail Action
 */

export const editCourseDetailAction = createAsyncThunk(`${SLICE_NAME}/editCourseDetailAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-chapter-category',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete course detail Action
 */

export const deleteCourseDetailAction = createAsyncThunk(`${SLICE_NAME}/deleteCourseDetailAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-chapter-category',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status Course detail Action
 */

export const changeStatusCourseDetailAction = createAsyncThunk(`${SLICE_NAME}/changeStatusCourseDetailAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/change-chapter-category-status',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const courseSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetCoursesState: (state, action) => {
            return {
                ...state,
                courseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                editCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusCourseLoadingStatus: LoadingStatus.NOT_LOADED,
                courseLevelLoadingStatus:LoadingStatus.NOT_LOADED,
                courseById: null,
                courseByIdError: null,
                addCourseError: null,
                editCourseError: null,
                courseLevelError:null,
                courseLevelList:[],
                deleteCourseError: null,
                changeStatusCourseError: null
            };
        },
        resetCoursesDetailState: (state, action) => {
            return {
                ...state,
                courseDetailByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
                editCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusCourseDetailLoadingStatus: LoadingStatus.NOT_LOADED,
                courseDetailById: null,
                courseDetailByIdError: null,
                addCourseDetailError: null,
                editCourseDetailError: null,
                deleteCourseDetailError: null,
                changeStatusCourseDetailError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All Courses action
            .addCase(allCoursesAction.pending, (state, action) => {
                state.allCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCoursesAction.fulfilled, (state, action) => {
                state.allCoursesLoadingStatus = LoadingStatus.LOADED;
                state.allCourses = action.payload?.data ?? [];
            })
            .addCase(allCoursesAction.rejected, (state, action) => {
                state.allCoursesLoadingStatus = LoadingStatus.FAILED;
                state.allCourseError = action.payload || action.error.message;
            })
            //  Course by id action
            .addCase(courseByIdAction.pending, (state, action) => {
                state.courseByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(courseByIdAction.fulfilled, (state, action) => {
                state.courseByIdLoadingStatus = LoadingStatus.LOADED;
                state.courseById = action.payload?.data;
            })
            .addCase(courseByIdAction.rejected, (state, action) => {
                state.courseByIdLoadingStatus = LoadingStatus.FAILED;
                state.courseByIdError = action.payload || action.error.message;
            })
            // Add Course action
            .addCase(addCourseAction.pending, (state, action) => {
                state.addCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCourseAction.fulfilled, (state, action) => {
                state.addCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course added successfully.');
            })
            .addCase(addCourseAction.rejected, (state, action) => {
                state.addCourseLoadingStatus = LoadingStatus.FAILED;
                state.addCourseError = action.payload || action.error.message;
            })
            // Edit Course action
            .addCase(editCourseAction.pending, (state, action) => {
                state.editCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editCourseAction.fulfilled, (state, action) => {
                state.editCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course updated successfully.');
            })
            .addCase(editCourseAction.rejected, (state, action) => {
                state.editCourseLoadingStatus = LoadingStatus.FAILED;
                state.editCourseError = action.payload || action.error.message;
            })
            // Delete Course action
            .addCase(deleteCourseAction.pending, (state, action) => {
                state.deleteCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCourseAction.fulfilled, (state, action) => {
                state.deleteCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course deleted successfully.');
            })
            .addCase(deleteCourseAction.rejected, (state, action) => {
                state.deleteCourseLoadingStatus = LoadingStatus.FAILED;
                state.deleteCourseError = action.payload || action.error.message;
            })
            // Change Status Course action
            .addCase(changeStatusCourseAction.pending, (state, action) => {
                state.changeStatusCourseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusCourseAction.fulfilled, (state, action) => {
                state.changeStatusCourseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course status updated successfully.');
            })
            .addCase(changeStatusCourseAction.rejected, (state, action) => {
                state.changeStatusCourseLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusCourseError = action.payload || action.error.message;
            })
            // All Courses detail action
            .addCase(allCourseDetailsAction.pending, (state, action) => {
                state.allCourseDetailsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCourseDetailsAction.fulfilled, (state, action) => {
                state.allCourseDetailsLoadingStatus = LoadingStatus.LOADED;
                state.allCourseDetails = action.payload?.data ?? [];
            })
            .addCase(allCourseDetailsAction.rejected, (state, action) => {
                state.allCourseDetailsLoadingStatus = LoadingStatus.FAILED;
                state.allCourseDetailsError = action.payload || action.error.message;
            })
            //  Course details by id action
            .addCase(courseDetailByIdAction.pending, (state, action) => {
                state.courseDetailByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(courseDetailByIdAction.fulfilled, (state, action) => {
                state.courseDetailByIdLoadingStatus = LoadingStatus.LOADED;
                state.courseDetailById = action.payload?.data;
            })
            .addCase(courseDetailByIdAction.rejected, (state, action) => {
                state.courseDetailByIdLoadingStatus = LoadingStatus.FAILED;
                state.courseDetailByIdError = action.payload || action.error.message;
            })
            // Add Course detail action
            .addCase(addCourseDetailAction.pending, (state, action) => {
                state.addCourseDetailLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCourseDetailAction.fulfilled, (state, action) => {
                state.addCourseDetailLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course detail added successfully.');
            })
            .addCase(addCourseDetailAction.rejected, (state, action) => {
                state.addCourseDetailLoadingStatus = LoadingStatus.FAILED;
                state.addCourseDetailError = action.payload || action.error.message;
            })
            // Edit Course detail action
            .addCase(editCourseDetailAction.pending, (state, action) => {
                state.editCourseDetailLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editCourseDetailAction.fulfilled, (state, action) => {
                state.editCourseDetailLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course detail updated successfully.');
            })
            .addCase(editCourseDetailAction.rejected, (state, action) => {
                state.editCourseDetailLoadingStatus = LoadingStatus.FAILED;
                state.editCourseDetailError = action.payload || action.error.message;
            })
            // Delete Course detail action
            .addCase(deleteCourseDetailAction.pending, (state, action) => {
                state.deleteCourseDetailLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCourseDetailAction.fulfilled, (state, action) => {
                state.deleteCourseDetailLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course detail deleted successfully.');
            })
            .addCase(deleteCourseDetailAction.rejected, (state, action) => {
                state.deleteCourseDetailLoadingStatus = LoadingStatus.FAILED;
                state.deleteCourseDetailError = action.payload || action.error.message;
            })
            // Change Status Course detail action
            .addCase(changeStatusCourseDetailAction.pending, (state, action) => {
                state.changeStatusCourseDetailLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusCourseDetailAction.fulfilled, (state, action) => {
                state.changeStatusCourseDetailLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Course detail status updated successfully.');
            })
            .addCase(changeStatusCourseDetailAction.rejected, (state, action) => {
                state.changeStatusCourseDetailLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusCourseDetailError = action.payload || action.error.message;
            })

             // All ContactUs action
            .addCase(allLevelAction.pending, (state, action) => {
                state.courseLevelError = null;
                state.courseLevelLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allLevelAction.fulfilled, (state, action) => {
                state.courseLevelLoadingStatus = LoadingStatus.LOADED;
                state.courseLevelList = action.payload?.data ?? [];
            })
            .addCase(allLevelAction.rejected, (state, action) => {
                state.courseLevelLoadingStatus = LoadingStatus.FAILED;
                state.courseLevelError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const courseReducer = courseSlice.reducer;

export const courseActions = courseSlice.actions;
