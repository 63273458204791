/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'aboutUs';

const initialState = entityAdapter.getInitialState({
    allAboutUsLoading: LoadingStatus.NOT_LOADED,
    aboutUsByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
    editAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
    allAboutUs: [],
    aboutUsById: null,
    allAboutUsError: null,
    aboutUsByIdError: null,
    addAboutUsError: null,
    editAboutUsError: null,
    deleteAboutUsError: null,
    changeStatusAboutUsError: null,
});

/**
 * All PopularCourse Action
 */

export const allAboutUsAction = createAsyncThunk(`${SLICE_NAME}/allAboutUsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/about-us',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add PopularCourse Action
 */

export const addAboutUsAction = createAsyncThunk(`${SLICE_NAME}/addAboutUsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/about-us',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  PopularCourse by ID Action
 */

export const aboutUsByIdAction = createAsyncThunk(`${SLICE_NAME}/aboutUsByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/about-us/' + params.id,
            method: 'GET'
            // data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit PopularCourse Action
 */

 export const editAboutUsAction = createAsyncThunk(
    `${SLICE_NAME}/editAboutUsAction`,
    async (formData, thunkAPI) => {
        try {
            const id = formData.get("id");
            if (!id) {
                throw new Error("ID is missing in FormData");
            }

            const result = await axiosRequest({
                url: `/about-us/${id}`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Delete PopularCourse Action
 */

export const deleteAboutUsAction = createAsyncThunk(`${SLICE_NAME}/deleteAboutUsAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/about-us/'+ params.id,
            method: 'DELETE',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status PopularCourse Action
 */

export const changeStatusAboutUsAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusAboutUsAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/about-us-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);



/**
 * Slice for all reducres
 */
const aboutUsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetAboutUsState: (state, action) => {
            return {
                ...state,
                aboutUsByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
                editAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusAboutUsLoadingStatus: LoadingStatus.NOT_LOADED,
                aboutUsById: null,
                aboutUsByIdError: null,
                addAboutUsError: null,
                editAboutUsError: null,
                deleteAboutUsError: null,
                changeStatusAboutUsError: null
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All PopularCourse action
            .addCase(allAboutUsAction.pending, (state, action) => {
                state.allAboutUsLoading = LoadingStatus.LOADING;
            })
            .addCase(allAboutUsAction.fulfilled, (state, action) => {
                state.allAboutUsLoading = LoadingStatus.LOADED;
                state.allAboutUs = action.payload?.data ?? [];
            })
            .addCase(allAboutUsAction.rejected, (state, action) => {
                state.allAboutUsLoading = LoadingStatus.FAILED;
                state.allAboutUsError = action.payload || action.error.message;
            })
            //  PopularCourse by id action
            .addCase(aboutUsByIdAction.pending, (state, action) => {
                state.aboutUsByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(aboutUsByIdAction.fulfilled, (state, action) => {
                state.aboutUsByIdLoadingStatus = LoadingStatus.LOADED;
                state.aboutUsById = action.payload?.data;
            })
            .addCase(aboutUsByIdAction.rejected, (state, action) => {
                state.aboutUsByIdLoadingStatus = LoadingStatus.FAILED;
                state.aboutUsByIdError = action.payload || action.error.message;
            })
            // Add PopularCourse action
            .addCase(addAboutUsAction.pending, (state, action) => {
                state.addAboutUsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addAboutUsAction.fulfilled, (state, action) => {
                state.addAboutUsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data added successfully.');
            })
            .addCase(addAboutUsAction.rejected, (state, action) => {
                state.addAboutUsLoadingStatus = LoadingStatus.FAILED;
                state.addAboutUsError = action.payload || action.error.message;
            })
            // Edit PopularCourse action
            .addCase(editAboutUsAction.pending, (state, action) => {
                state.editAboutUsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editAboutUsAction.fulfilled, (state, action) => {
                state.editAboutUsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data updated successfully.');
            })
            .addCase(editAboutUsAction.rejected, (state, action) => {
                state.editAboutUsLoadingStatus = LoadingStatus.FAILED;
                state.editAboutUsError = action.payload || action.error.message;
            })
            // Delete PopularCourse action
            .addCase(deleteAboutUsAction.pending, (state, action) => {
                state.deleteAboutUsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteAboutUsAction.fulfilled, (state, action) => {
                state.deleteAboutUsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data deleted successfully.');
            })
            .addCase(deleteAboutUsAction.rejected, (state, action) => {
                state.deleteAboutUsLoadingStatus = LoadingStatus.FAILED;
                state.deleteAboutUsError = action.payload || action.error.message;
            })
            // Change Status PopularCourse action
            .addCase(changeStatusAboutUsAction.pending, (state, action) => {
                state.changeStatusAboutUsLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusAboutUsAction.fulfilled, (state, action) => {
                state.changeStatusAboutUsLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data status updated successfully.');
            })
            .addCase(changeStatusAboutUsAction.rejected, (state, action) => {
                state.changeStatusAboutUsLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusAboutUsError = action.payload || action.error.message;
            });
            
    }
});

/*
 * Export reducer for store configuration.
 */
export const aboutUsReducer = aboutUsSlice.reducer;

export const aboutUsActions = aboutUsSlice.actions;
