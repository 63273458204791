/*eslint-disable no-unused-vars*/
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'users';

const initialState = entityAdapter.getInitialState({
    allUsersLoadingStatus: LoadingStatus.NOT_LOADED,
    allCountryLoadingStatus: LoadingStatus.NOT_LOADED,
    userByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addUserLoadingStatus: LoadingStatus.NOT_LOADED,
    editUserLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteUserLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusUserLoadingStatus: LoadingStatus.NOT_LOADED,
    allUsers: [],
    countryData: [],
    userById: null,
    allUsersError: null,
    countryDataError: null,
    userByIdError: null,
    editUserError: null,
    addUserError: null,
    deleteUserError: null,
    changeStatusUserError: null,
    allDeletedUserLoadingStatus: LoadingStatus.NOT_LOADED,
    allDeletedUsers: [],
    allDeletedUsersError: null,
    recoverDeletedUsersLoadingStatus: LoadingStatus.NOT_LOADED,
    recoverDeletedUsersError: null,
    allSubscribedUserLoadingStatus: LoadingStatus.NOT_LOADED,
    allInactiveSubscribedUserLoadingStatus: LoadingStatus.NOT_LOADED,
    allSubscribedUsers: [],
    allSubscribedUsersError: null,
    allInactiveSubscribedUsers: [],
    allInactiveSubscribedUsersError: null,
    allLiveClassUserLoadingStatus: LoadingStatus.NOT_LOADED,
    allLiveClassUsers: [],
    allLiveClassUsersError: null
});

/**
 *  All Country Code
 */

export const allCountryCodeAction = createAsyncThunk(`${SLICE_NAME}/allCountryCodeAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/country-code-admin',
            method: 'GET'
        });
        return result;
    } catch (error) {
        // console.log('error', error);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  All Users Action
 */

export const allUsersAction = createAsyncThunk(`${SLICE_NAME}/allUsersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/user-list',
            method: 'GET'
        });
        return result;
    } catch (error) {
        // console.log('error', error);
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  User by ID Action
 */

export const userByIdAction = createAsyncThunk(`${SLICE_NAME}/userByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-user',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Add User Action
 */

export const addUserAction = createAsyncThunk(`${SLICE_NAME}/addUserAction`, async (params, thunkAPI) => {
    const { name, email, contact_number, country_code, currentFile, password } = params;
    const dataReq = {
        name: name,
        email: email,
        contact: contact_number,
        country_code: country_code,
        password: password,
        profile_image: currentFile
    };
    try {
        const result = await axiosRequest({
            url: '/user-added',
            method: 'POST',
            data: dataReq,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Edit User Action
 */

export const editUserAction = createAsyncThunk(`${SLICE_NAME}/editUserAction`, async (params, thunkAPI) => {
    const { id } = params;
    try {
        const result = await axiosRequest({
            url: `/update-user/${id}`,
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete User Action
 */

export const deleteUserAction = createAsyncThunk(`${SLICE_NAME}/deleteUserAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/user-delete/${params}`,
            method: 'DELETE'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status User Action
 */

export const changeStatusUserAction = createAsyncThunk(`${SLICE_NAME}/changeStatusUserAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/status-change-user',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All Deleted User Action
 */

export const allDeletedUserAction = createAsyncThunk(`${SLICE_NAME}/allDeletedUserAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/deleted-user-list',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Recover deleted user Action
 */

export const recoverDeletedUserAction = createAsyncThunk(`${SLICE_NAME}/recoverDeletedUserAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/recover-user',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All Subscribed User Action
 */

export const allSubscribedusersAction = createAsyncThunk(`${SLICE_NAME}/allSubscribedusersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-history-user-subscription',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * All Subscribed User Action
 */

export const allInactiveSubscribedusersAction = createAsyncThunk(
    `${SLICE_NAME}/allInactiveSubscribedusersAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/get-inactive-user-subscription',
                method: 'GET'
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * All Live class User Action
 */

export const allLiveClassUsersAction = createAsyncThunk(`${SLICE_NAME}/allLiveClassUsersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-history-user-liveclass',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const usersSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetUsersState: (state, action) => {
            return {
                ...state,
                userByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addUserLoadingStatus: LoadingStatus.NOT_LOADED,
                editUserLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteUserLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusUserLoadingStatus: LoadingStatus.NOT_LOADED,
                userById: null,
                userByIdError: null,
                editUserError: null,
                addUserError: null,
                deleteUserError: null,
                changeStatusUserError: null
            };
        },
        resetDeletedUsersState: (state, action) => {
            return {
                ...state,
                recoverDeletedUsersLoadingStatus: LoadingStatus.NOT_LOADED,
                recoverDeletedUsersError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // all users action
            .addCase(allUsersAction.pending, (state, action) => {
                state.allUsersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allUsersAction.fulfilled, (state, action) => {
                state.allUsersLoadingStatus = LoadingStatus.LOADED;
                state.allUsers = action.payload?.data;
            })
            .addCase(allUsersAction.rejected, (state, action) => {
                state.allUsersLoadingStatus = LoadingStatus.FAILED;
                state.allUsersError = action.payload || action.error.message;
            })
            // all Country action
            .addCase(allCountryCodeAction.pending, (state, action) => {
                state.allUsersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCountryCodeAction.fulfilled, (state, action) => {
                state.allUsersLoadingStatus = LoadingStatus.LOADED;
                state.countryData = action.payload?.data;
            })
            .addCase(allCountryCodeAction.rejected, (state, action) => {
                state.allUsersLoadingStatus = LoadingStatus.FAILED;
                state.countryDataError = action.payload || action.error.message;
            })
            //  user by id action
            .addCase(userByIdAction.pending, (state, action) => {
                state.userByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(userByIdAction.fulfilled, (state, action) => {
                state.userByIdLoadingStatus = LoadingStatus.LOADED;
                state.userById = action.payload?.data;
            })
            .addCase(userByIdAction.rejected, (state, action) => {
                state.userByIdLoadingStatus = LoadingStatus.FAILED;
                state.userByIdError = action.payload || action.error.message;
            })
            // add user action
            .addCase(addUserAction.pending, (state, action) => {
                state.addUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addUserAction.fulfilled, (state, action) => {
                state.addUserLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('User Added successfully.');
            })
            .addCase(addUserAction.rejected, (state, action) => {
                state.addUserLoadingStatus = LoadingStatus.FAILED;
                state.addUserError = action.payload || action.error.message;
            })
            // edit user action
            .addCase(editUserAction.pending, (state, action) => {
                state.editUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editUserAction.fulfilled, (state, action) => {
                state.editUserLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('User Updated successfully.');
            })
            .addCase(editUserAction.rejected, (state, action) => {
                state.editUserLoadingStatus = LoadingStatus.FAILED;
                state.editUserAction = action.payload || action.error.message;
            })
            // delete users action
            .addCase(deleteUserAction.pending, (state, action) => {
                state.deleteUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteUserAction.fulfilled, (state, action) => {
                state.deleteUserLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('User Deleted successfully.');
            })
            .addCase(deleteUserAction.rejected, (state, action) => {
                state.deleteUserLoadingStatus = LoadingStatus.FAILED;
                state.deleteUserError = action.payload || action.error.message;
            })
            // change status users action
            .addCase(changeStatusUserAction.pending, (state, action) => {
                state.changeStatusUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusUserAction.fulfilled, (state, action) => {
                state.changeStatusUserLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('User status updated successfully.');
            })
            .addCase(changeStatusUserAction.rejected, (state, action) => {
                state.changeStatusUserLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusUserError = action.payload || action.error.message;
            })
            // all delete users list action
            .addCase(allDeletedUserAction.pending, (state, action) => {
                state.allDeletedUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allDeletedUserAction.fulfilled, (state, action) => {
                state.allDeletedUserLoadingStatus = LoadingStatus.LOADED;
                state.allDeletedUsers = action.payload?.data;
            })
            .addCase(allDeletedUserAction.rejected, (state, action) => {
                state.allDeletedUserLoadingStatus = LoadingStatus.FAILED;
                state.allDeletedUsersError = action.payload || action.error.message;
            })
            // change status users action
            .addCase(recoverDeletedUserAction.pending, (state, action) => {
                state.recoverDeletedUsersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(recoverDeletedUserAction.fulfilled, (state, action) => {
                state.recoverDeletedUsersLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('User restore successfully.');
            })
            .addCase(recoverDeletedUserAction.rejected, (state, action) => {
                state.recoverDeletedUsersLoadingStatus = LoadingStatus.FAILED;
                state.recoverDeletedUsersError = action.payload || action.error.message;
            })
            // all subscribed users list action
            .addCase(allSubscribedusersAction.pending, (state, action) => {
                state.allSubscribedUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allSubscribedusersAction.fulfilled, (state, action) => {
                state.allSubscribedUserLoadingStatus = LoadingStatus.LOADED;
                state.allSubscribedUsers = action.payload?.data;
            })
            .addCase(allSubscribedusersAction.rejected, (state, action) => {
                state.allSubscribedUserLoadingStatus = LoadingStatus.FAILED;
                state.allSubscribedUsersError = action.payload || action.error.message;
            })

            // all subscribed Inactive users list action
            .addCase(allInactiveSubscribedusersAction.pending, (state, action) => {
                state.allInactiveSubscribedUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allInactiveSubscribedusersAction.fulfilled, (state, action) => {
                state.allInactiveSubscribedUserLoadingStatus = LoadingStatus.LOADED;
                state.allInactiveSubscribedUsers = action.payload?.data;
            })
            .addCase(allInactiveSubscribedusersAction.rejected, (state, action) => {
                state.allInactiveSubscribedUserLoadingStatus = LoadingStatus.FAILED;
                state.allInactiveSubscribedUsersError = action.payload || action.error.message;
            })
            // all live class users list action
            .addCase(allLiveClassUsersAction.pending, (state, action) => {
                state.allLiveClassUserLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allLiveClassUsersAction.fulfilled, (state, action) => {
                state.allLiveClassUserLoadingStatus = LoadingStatus.LOADED;
                state.allLiveClassUsers = action.payload?.data;
            })
            .addCase(allLiveClassUsersAction.rejected, (state, action) => {
                state.allLiveClassUserLoadingStatus = LoadingStatus.FAILED;
                state.allLiveClassUsersError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const usersReducer = usersSlice.reducer;

export const usersActions = usersSlice.actions;
