import { isDev } from 'config';

const ApiPath = {
    BASE_URL: 'https://api.shivamyogastudio.in/api/',
    // BASE_URL: 'http://localhost/yogamobapp/api/',
    // BASE_URL: 'https://php8.singsys.net/yogamobapp/api/',
    // BASE_URL: 'http://localhost:8000/api/',
    LOGIN: '/login',
    FORGOT_PASS: '/forget-password-email'
};

const ValidationMessage = {};
const AlertMessage = {};

const LoadingStatus = {
    NOT_LOADED: 'NOT_LOADED',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FAILED: 'FAILED'
};

export const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/svg'];
export const IMAGE_SUPPORTED_FORMATS_MESSAGE = 'Only the following formats are accepted: jpg, jpeg, gif, png, svg';

export const VIDEO_SUPPORTED_FORMATS = ['video/mp4'];
export const VIDEO_SUPPORTED_FORMATS_MESSAGE = 'Only the following formats are accepted: mp4';

export const FILE_SIZE = 1280 * 1080;

export { ApiPath, ValidationMessage, LoadingStatus, AlertMessage };
