/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'staticContent';

const initialState = entityAdapter.getInitialState({
    allStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    staticContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    editStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    allStaticContents: [],
    staticContentById: null,
    allStaticContentError: null,
    addStaticContentError: null,
    editStaticContentError: null,
    deleteStaticContentError: null,
    changeStatusStaticContentError: null,
    staticContentByIdError: null
});

/**
 * All StaticContent Action
 */

export const allStaticContentAction = createAsyncThunk(`${SLICE_NAME}/allStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/static-content',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add StaticContent Action
 */

export const addStaticContentAction = createAsyncThunk(`${SLICE_NAME}/addStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/static-content-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  StaticContent by ID Action
 */

export const staticContentByIdAction = createAsyncThunk(`${SLICE_NAME}/staticContentByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params, 'Params');
        const result = await axiosRequest({
            url: '/static-content/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit StaticContent Action
 */

export const editStaticContentAction = createAsyncThunk(`${SLICE_NAME}/editStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/static-content-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete StaticContent Action
 */

export const deleteStaticContentAction = createAsyncThunk(`${SLICE_NAME}/deleteStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/static-content-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status StaticContent Action
 */

export const changeStatusStaticContentAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusStaticContentAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/live-course-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const staticContentSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetStaticContentState: (state, action) => {
            return {
                ...state,
                staticContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                editStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                staticContentById: null,
                staticContentByIdError: null,
                addStaticContentError: null,
                editStaticContentError: null,
                deleteStaticContentError: null,
                changeStatusStaticContentError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All StaticContent action
            .addCase(allStaticContentAction.pending, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allStaticContentAction.fulfilled, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.LOADED;
                state.allStaticContents = action.payload?.data ?? [];
            })
            .addCase(allStaticContentAction.rejected, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.allStaticContentError = action.payload || action.error.message;
            })
            .addCase(staticContentByIdAction.pending, (state, action) => {
                state.staticContentByIdError = null;
                state.staticContentByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(staticContentByIdAction.fulfilled, (state, action) => {
                state.staticContentByIdLoadingStatus = LoadingStatus.LOADED;
                state.staticContentById = action.payload?.data;
            })
            .addCase(staticContentByIdAction.rejected, (state, action) => {
                state.staticContentByIdLoadingStatus = LoadingStatus.FAILED;
                state.staticContentByIdError = action.payload || action.error.message;
            })

            // Add StaticContent action
            .addCase(addStaticContentAction.pending, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addStaticContentAction.fulfilled, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content added successfully.');
            })
            .addCase(addStaticContentAction.rejected, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.addStaticContentError = action.payload || action.error.message;
            })
            // Edit StaticContent action
            .addCase(editStaticContentAction.pending, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editStaticContentAction.fulfilled, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content updated successfully.');
            })
            .addCase(editStaticContentAction.rejected, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.editStaticContentError = action.payload || action.error.message;
            })
            // Delete StaticContent action
            .addCase(deleteStaticContentAction.pending, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteStaticContentAction.fulfilled, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content deleted successfully.');
            })
            .addCase(deleteStaticContentAction.rejected, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.deleteStaticContentError = action.payload || action.error.message;
            })
            // Change Status StaticContent action
            .addCase(changeStatusStaticContentAction.pending, (state, action) => {
                state.changeStatusStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusStaticContentAction.fulfilled, (state, action) => {
                state.changeStatusStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content status updated successfully.');
            })
            .addCase(changeStatusStaticContentAction.rejected, (state, action) => {
                state.changeStatusStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusStaticContentError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const staticContentReducer = staticContentSlice.reducer;

export const staticContentActions = staticContentSlice.actions;
