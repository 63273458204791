/*eslint-disable no-unused-vars*/

import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosRequest from 'utils/AxiosInterceptor';
import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'posters';

const initialState = entityAdapter.getInitialState({
    allPostersLoadingStatus: LoadingStatus.NOT_LOADED,
    posterByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addPosterLoadingStatus: LoadingStatus.NOT_LOADED,
    editPosterLoadingStatus: LoadingStatus.NOT_LOADED,
    deletePosterLoadingStatus: LoadingStatus.NOT_LOADED,
    allPosters: [],
    posterById: null,
    allPosterError: null,
    postersByIdError: null,
    addPosterError: null,
    editPosterError: null,
    deletePosterError: null
});

/**
 * All Posters Action
 */

export const allPostersAction = createAsyncThunk(`${SLICE_NAME}/allPostersAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/show-addvertisement',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Poster by ID Action
 */

export const posterByIdAction = createAsyncThunk(`${SLICE_NAME}/posterByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/views-addvertisement',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add Posters Action
 */

export const addPosterAction = createAsyncThunk(`${SLICE_NAME}/addPosterAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-addvertisement',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit Posters Action
 */

export const editPosterAction = createAsyncThunk(`${SLICE_NAME}/editPosterAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-addvertisement',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete Posters Action
 */

export const deletePosterAction = createAsyncThunk(`${SLICE_NAME}/deletePosterAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-addvertisement',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const postersSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetPostersState: (state, action) => {
            return {
                ...state,
                posterByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addPosterLoadingStatus: LoadingStatus.NOT_LOADED,
                editPosterLoadingStatus: LoadingStatus.NOT_LOADED,
                deletePosterLoadingStatus: LoadingStatus.NOT_LOADED,
                posterById: null,
                postersByIdError: null,
                addPosterError: null,
                editPosterError: null,
                deletePosterError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All Poster action
            .addCase(allPostersAction.pending, (state, action) => {
                state.allPosterError = null;
                state.allPostersLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allPostersAction.fulfilled, (state, action) => {
                state.allPostersLoadingStatus = LoadingStatus.LOADED;
                state.allPosters = action.payload?.data ?? [];
            })
            .addCase(allPostersAction.rejected, (state, action) => {
                state.allPostersLoadingStatus = LoadingStatus.FAILED;
                state.allPosterError = action.payload || action.error.message;
            })
            //  Posters by id action
            .addCase(posterByIdAction.pending, (state, action) => {
                state.posterByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(posterByIdAction.fulfilled, (state, action) => {
                state.posterByIdLoadingStatus = LoadingStatus.LOADED;
                state.posterById = action.payload?.data.advertisement_data ?? [];
            })
            .addCase(posterByIdAction.rejected, (state, action) => {
                state.posterByIdLoadingStatus = LoadingStatus.FAILED;
                state.postersByIdError = action.payload || action.error.message;
            })
            // Add Poster action
            .addCase(addPosterAction.pending, (state, action) => {
                state.addPosterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addPosterAction.fulfilled, (state, action) => {
                state.addPosterLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Poster added successfully.');
            })
            .addCase(addPosterAction.rejected, (state, action) => {
                state.addPosterLoadingStatus = LoadingStatus.FAILED;
                state.addPosterError = action.payload || action.error.message;
            })
            // Edit Poster action
            .addCase(editPosterAction.pending, (state, action) => {
                state.editPosterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editPosterAction.fulfilled, (state, action) => {
                state.editPosterLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Poster updated successfully.');
            })
            .addCase(editPosterAction.rejected, (state, action) => {
                state.editPosterLoadingStatus = LoadingStatus.FAILED;
                state.editPosterError = action.payload || action.error.message;
            })
            // Delete Poster action
            .addCase(deletePosterAction.pending, (state, action) => {
                state.deletePosterLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deletePosterAction.fulfilled, (state, action) => {
                state.deletePosterLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Poster deleted successfully.');
            })
            .addCase(deletePosterAction.rejected, (state, action) => {
                state.deletePosterLoadingStatus = LoadingStatus.FAILED;
                state.deletePosterError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const postersReducer = postersSlice.reducer;

export const postersActions = postersSlice.actions;
