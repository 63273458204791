/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'ourGallery';

const initialState = entityAdapter.getInitialState({
    allOurGalleryLoading: LoadingStatus.NOT_LOADED,
    ourGalleryByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    editOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
    allOurGallery: [],
    ourGalleryById: null,
    allOurGalleryError: null,
    ourGalleryByIdError: null,
    addOurGalleryError: null,
    editOurGalleryError: null,
    deleteOurGalleryError: null,
    changeStatusOurGalleryError: null,
});

/**
 * All PopularCourse Action
 */

export const allOurGalleryAction = createAsyncThunk(`${SLICE_NAME}/allOurGalleryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/our-gallery',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add PopularCourse Action
 */

export const addOurGalleryAction = createAsyncThunk(`${SLICE_NAME}/addOurGalleryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/our-gallery',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  PopularCourse by ID Action
 */

export const ourGalleryByIdAction = createAsyncThunk(`${SLICE_NAME}/ourGalleryByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/our-gallery/' + params.id,
            method: 'GET'
            // data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit PopularCourse Action
 */

 export const editOurGalleryAction = createAsyncThunk(
    `${SLICE_NAME}/editOurGalleryAction`,
    async (formData, thunkAPI) => {
        try {
            const id = formData.get("id");
            if (!id) {
                throw new Error("ID is missing in FormData");
            }

            const result = await axiosRequest({
                url: `/our-gallery/${id}`,
                method: "POST",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Delete PopularCourse Action
 */

export const deleteOurGalleryAction = createAsyncThunk(`${SLICE_NAME}/deleteOurGalleryAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/our-gallery/'+ params.id,
            method: 'DELETE',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status PopularCourse Action
 */

export const changeStatusOurGalleryAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusOurGalleryAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/our-gallery-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);



/**
 * Slice for all reducres
 */
const ourGallerySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetOurGalleryState: (state, action) => {
            return {
                ...state,
                ourGalleryByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
                editOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusOurGalleryLoadingStatus: LoadingStatus.NOT_LOADED,
                ourGalleryById: null,
                ourGalleryByIdError: null,
                addOurGalleryError: null,
                editOurGalleryError: null,
                deleteOurGalleryError: null,
                changeStatusOurGalleryError: null
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All PopularCourse action
            .addCase(allOurGalleryAction.pending, (state, action) => {
                state.allOurGalleryLoading = LoadingStatus.LOADING;
            })
            .addCase(allOurGalleryAction.fulfilled, (state, action) => {
                state.allOurGalleryLoading = LoadingStatus.LOADED;
                state.allOurGallery = action.payload?.data ?? [];
            })
            .addCase(allOurGalleryAction.rejected, (state, action) => {
                state.allOurGalleryLoading = LoadingStatus.FAILED;
                state.allOurGalleryError = action.payload || action.error.message;
            })
            //  PopularCourse by id action
            .addCase(ourGalleryByIdAction.pending, (state, action) => {
                state.ourGalleryByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(ourGalleryByIdAction.fulfilled, (state, action) => {
                state.ourGalleryByIdLoadingStatus = LoadingStatus.LOADED;
                state.ourGalleryById = action.payload?.data;
            })
            .addCase(ourGalleryByIdAction.rejected, (state, action) => {
                state.ourGalleryByIdLoadingStatus = LoadingStatus.FAILED;
                state.ourGalleryByIdError = action.payload || action.error.message;
            })
            // Add PopularCourse action
            .addCase(addOurGalleryAction.pending, (state, action) => {
                state.addOurGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addOurGalleryAction.fulfilled, (state, action) => {
                state.addOurGalleryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data added successfully.');
            })
            .addCase(addOurGalleryAction.rejected, (state, action) => {
                state.addOurGalleryLoadingStatus = LoadingStatus.FAILED;
                state.addOurGalleryError = action.payload || action.error.message;
            })
            // Edit PopularCourse action
            .addCase(editOurGalleryAction.pending, (state, action) => {
                state.editOurGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editOurGalleryAction.fulfilled, (state, action) => {
                state.editOurGalleryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data updated successfully.');
            })
            .addCase(editOurGalleryAction.rejected, (state, action) => {
                state.editOurGalleryLoadingStatus = LoadingStatus.FAILED;
                state.editOurGalleryError = action.payload || action.error.message;
            })
            // Delete PopularCourse action
            .addCase(deleteOurGalleryAction.pending, (state, action) => {
                state.deleteOurGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteOurGalleryAction.fulfilled, (state, action) => {
                state.deleteOurGalleryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data deleted successfully.');
            })
            .addCase(deleteOurGalleryAction.rejected, (state, action) => {
                state.deleteOurGalleryLoadingStatus = LoadingStatus.FAILED;
                state.deleteOurGalleryError = action.payload || action.error.message;
            })
            // Change Status PopularCourse action
            .addCase(changeStatusOurGalleryAction.pending, (state, action) => {
                state.changeStatusOurGalleryLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusOurGalleryAction.fulfilled, (state, action) => {
                state.changeStatusOurGalleryLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Data status updated successfully.');
            })
            .addCase(changeStatusOurGalleryAction.rejected, (state, action) => {
                state.changeStatusOurGalleryLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusOurGalleryError = action.payload || action.error.message;
            });
            
    }
});

/*
 * Export reducer for store configuration.
 */
export const ourGalleryReducer = ourGallerySlice.reducer;

export const ourGalleryActions = ourGallerySlice.actions;
