import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

// scroll bar
import 'simplebar/src/simplebar.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// project import
import App from './App';
import { render } from 'react-dom';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
render(
    <StrictMode>
        <App />
    </StrictMode>,
    container
);
