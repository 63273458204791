import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import ThemeRoutes from 'routes';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'redux/store';
import { ToastContainer } from 'react-toastify';
import './App.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
    //Navigate to base url
    if (!window.location.pathname.includes('admin')) {
        window.history.replaceState('', '', 'admin' + window.location.pathname);
    }
    return (
        <ThemeCustomization>
            <ReduxProvider store={store}>
                <BrowserRouter basename="/admin">
                    <ScrollTop>
                        <ThemeRoutes />
                    </ScrollTop>
                </BrowserRouter>
                <ToastContainer />
            </ReduxProvider>
        </ThemeCustomization>
    );
};

export default App;
