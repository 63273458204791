/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'uploadVideo';

const initialState = entityAdapter.getInitialState({
    allUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    uploadVideoByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    editUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    allUploadVideos: [],
    uploadVideoById: null,
    allUploadVideoError: null,
    addUploadVideoError: null,
    editUploadVideoError: null,
    deleteUploadVideoError: null,
    changeStatusUploadVideoError: null,
    uploadVideoByIdError: null
});

/**
 * All UploadVideo Action
 */

export const allUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/allUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-record-video/' + params.record_id,
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add UploadVideo Action
 */

export const addUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/addUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-video-upload',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  UploadVideo by ID Action
 */

export const uploadVideoByIdAction = createAsyncThunk(`${SLICE_NAME}/uploadVideoByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params, 'Params');
        const result = await axiosRequest({
            url: '/record-video/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit UploadVideo Action
 */

export const editUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/editUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-video-update',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete UploadVideo Action
 */

export const deleteUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/deleteUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/record-video-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status UploadVideo Action
 */

export const changeStatusUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/changeStatusUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-status',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const uploadVideoSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetUploadVideoState: (state, action) => {
            return {
                ...state,
                uploadVideoByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                editUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                uploadVideoById: null,
                uploadVideoByIdError: null,
                addUploadVideoError: null,
                editUploadVideoError: null,
                deleteUploadVideoError: null,
                changeStatusUploadVideoError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All UploadVideo action
            .addCase(allUploadVideoAction.pending, (state, action) => {
                state.allUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allUploadVideoAction.fulfilled, (state, action) => {
                state.allUploadVideoLoadingStatus = LoadingStatus.LOADED;
                state.allUploadVideos = action.payload?.data ?? [];
            })
            .addCase(allUploadVideoAction.rejected, (state, action) => {
                state.allUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.allUploadVideoError = action.payload || action.error.message;
            })
            .addCase(uploadVideoByIdAction.pending, (state, action) => {
                state.uploadVideoByIdError = null;
                state.uploadVideoByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(uploadVideoByIdAction.fulfilled, (state, action) => {
                state.uploadVideoByIdLoadingStatus = LoadingStatus.LOADED;
                state.uploadVideoById = action.payload?.data;
            })
            .addCase(uploadVideoByIdAction.rejected, (state, action) => {
                state.uploadVideoByIdLoadingStatus = LoadingStatus.FAILED;
                state.uploadVideoByIdError = action.payload || action.error.message;
            })

            // Add UploadVideo action
            .addCase(addUploadVideoAction.pending, (state, action) => {
                state.addUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addUploadVideoAction.fulfilled, (state, action) => {
                state.addUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video added successfully.');
            })
            .addCase(addUploadVideoAction.rejected, (state, action) => {
                state.addUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.addUploadVideoError = action.payload || action.error.message;
            })
            // Edit UploadVideo action
            .addCase(editUploadVideoAction.pending, (state, action) => {
                state.editUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editUploadVideoAction.fulfilled, (state, action) => {
                state.editUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video updated successfully.');
            })
            .addCase(editUploadVideoAction.rejected, (state, action) => {
                state.editUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.editUploadVideoError = action.payload || action.error.message;
            })
            // Delete UploadVideo action
            .addCase(deleteUploadVideoAction.pending, (state, action) => {
                state.deleteUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteUploadVideoAction.fulfilled, (state, action) => {
                state.deleteUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video deleted successfully.');
            })
            .addCase(deleteUploadVideoAction.rejected, (state, action) => {
                state.deleteUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.deleteUploadVideoError = action.payload || action.error.message;
            })
            // Change Status UploadVideo action
            .addCase(changeStatusUploadVideoAction.pending, (state, action) => {
                state.changeStatusUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusUploadVideoAction.fulfilled, (state, action) => {
                state.changeStatusUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video status updated successfully.');
            })
            .addCase(changeStatusUploadVideoAction.rejected, (state, action) => {
                state.changeStatusUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusUploadVideoError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const uploadVideoReducer = uploadVideoSlice.reducer;

export const uploadVideoActions = uploadVideoSlice.actions;
