/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'ratingAndReview';

const initialState = entityAdapter.getInitialState({
    allRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    ratingAndReviewByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    editLiveRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteLiveRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    changeLiveStatusRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
    allRatingAndReview: [],
    ratingAndReviewById: null,
    allRatingAndReviewError: null,
    ratingAndReviewByIdError: null,
    addRatingAndReviewError: null,
    editRatingAndReviewError: null,
    deleteRatingAndReviewError: null,
    changeStatusRatingAndReviewError: null
});

/**
 * All ratingAndReview Action
 */

export const allRatingAndReviewAction = createAsyncThunk(`${SLICE_NAME}/allRatingAndReviewAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-live-ratings',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  ratingAndReview By Id Action
 */

export const ratingAndReviewByIdAction = createAsyncThunk(`${SLICE_NAME}/ratingAndReviewByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/view-live-ratings',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit ratingAndReview Action
 */

export const editLiveRatingAndReviewAction = createAsyncThunk(`${SLICE_NAME}/editLiveRatingAndReviewAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/update-live-ratings',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete ratingAndReview Action
 */

export const deleteLiveRatingAndReviewAction = createAsyncThunk(
    `${SLICE_NAME}/deleteLiveRatingAndReviewAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/delete-live-ratings',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * change status ratingAndReview Action
 */

export const changeLiveStatusRatingAndReviewAction = createAsyncThunk(
    `${SLICE_NAME}/changeLiveStatusRatingAndReviewAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/status-live-ratings',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const ratingAndReviewSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetRatingAndReviewState: (state, action) => {
            return {
                ...state,
                ratingAndReviewByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                editLiveRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteLiveRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                changeLiveStatusRatingAndReviewLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusRatingAndReviewError: null,
                ratingAndReviewById: null,
                ratingAndReviewByIdError: null,
                addRatingAndReviewError: null,
                editRatingAndReviewError: null,
                deleteRatingAndReviewError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All ratingAndReview action
            .addCase(allRatingAndReviewAction.pending, (state, action) => {
                state.allRatingAndReviewError = null;
                state.allRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allRatingAndReviewAction.fulfilled, (state, action) => {
                state.allRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                state.allRatingAndReview = action.payload?.data;
            })
            .addCase(allRatingAndReviewAction.rejected, (state, action) => {
                state.allRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.allRatingAndReviewError = action.payload || action.error.message;
            })
            //  ratingAndReview by id action
            .addCase(ratingAndReviewByIdAction.pending, (state, action) => {
                state.ratingAndReviewByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(ratingAndReviewByIdAction.fulfilled, (state, action) => {
                state.ratingAndReviewByIdLoadingStatus = LoadingStatus.LOADED;
                state.ratingAndReviewById = action.payload?.data;
            })
            .addCase(ratingAndReviewByIdAction.rejected, (state, action) => {
                state.ratingAndReviewByIdLoadingStatus = LoadingStatus.FAILED;
                state.ratingAndReviewByIdError = action.payload || action.error.message;
            })

            // Edit ratingAndReview action
            .addCase(editLiveRatingAndReviewAction.pending, (state, action) => {
                state.editLiveRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editLiveRatingAndReviewAction.fulfilled, (state, action) => {
                state.editLiveRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Rating and review updated successfully.');
            })
            .addCase(editLiveRatingAndReviewAction.rejected, (state, action) => {
                state.editLiveRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.editRatingAndReviewError = action.payload || action.error.message;
            })
            // Delete ratingAndReview action
            .addCase(deleteLiveRatingAndReviewAction.pending, (state, action) => {
                state.deleteRatingAndReviewError = null;
                state.deleteLiveRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteLiveRatingAndReviewAction.fulfilled, (state, action) => {
                state.deleteLiveRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Rating and review deleted successfully.');
            })
            .addCase(deleteLiveRatingAndReviewAction.rejected, (state, action) => {
                state.deleteLiveRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.deleteRatingAndReviewError = action.payload || action.error.message;
            })
            // change status ratingAndReview action
            .addCase(changeLiveStatusRatingAndReviewAction.pending, (state, action) => {
                state.changeLiveStatusRatingAndReviewLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeLiveStatusRatingAndReviewAction.fulfilled, (state, action) => {
                state.changeLiveStatusRatingAndReviewLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Rating and review status updated successfully.');
            })
            .addCase(changeLiveStatusRatingAndReviewAction.rejected, (state, action) => {
                state.changeLiveStatusRatingAndReviewLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusRatingAndReviewError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const liveRatingAndReviewReducer = ratingAndReviewSlice.reducer;

export const ratingAndReviewActions = ratingAndReviewSlice.actions;
