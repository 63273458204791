/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'teacherSchedule';

const initialState = entityAdapter.getInitialState({
    allScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
    teachersByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
    editScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
    allPopularCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    allSchedules: [],
    allPopularCourses: [],
    teachersById: null,
    allScheduleError: null,
    allPopularCoursesError: null,
    addScheduleError: null,
    editScheduleError: null,
    deleteScheduleError: null,
    changeStatusScheduleError: null,
    teachersByIdError: null,
});

/**
 * All Schedule Action
 */

export const allScheduleAction = createAsyncThunk(`${SLICE_NAME}/allScheduleAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/get-teacher-schedule?teacher_id=${params.teacher_id}`,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add Schedule Action
 */

export const addScheduleAction = createAsyncThunk(`${SLICE_NAME}/addScheduleAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-schedule-store',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Schedule by ID Action
 */

export const teachersByIdAction = createAsyncThunk(`${SLICE_NAME}/teachersByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-view/'+ params.id,
            method: 'GET',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit Schedule Action
 */

export const editScheduleAction = createAsyncThunk(`${SLICE_NAME}/editScheduleAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete Schedule Action
 */

export const deleteScheduleAction = createAsyncThunk(`${SLICE_NAME}/deleteScheduleAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/teacher-schedule-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status Schedule Action
 */

export const changeStatusScheduleAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusScheduleAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/teacher-schedule-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * All Schedule Action
 */

 export const getPopularCourses = createAsyncThunk(`${SLICE_NAME}/getPopularCourses`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-popular-course',
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Teacher Multiple Images by ID Action 
 */

 export const getTeacherImagesByIdAction = createAsyncThunk(`${SLICE_NAME}/getTeacherImagesByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: `/get-teachers-images?teacher_id=${params.teacher_id}`,
            method: 'GET',
            params: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 *  Add Multiple Teacher Images by ID Action
 */

 export const addMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/addMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/save-teachers-images',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Delete Product Multiple Images by ID Action
 */

 export const deleteMultipleImageAction = createAsyncThunk(`${SLICE_NAME}/deleteMultipleImageAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/delete-teachers-images',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});


/**
 * Slice for all reducres
 */
const teacherScheduleSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetScheduleState: (state, action) => {
            return {
                ...state,
                teachersByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
                editScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusScheduleLoadingStatus: LoadingStatus.NOT_LOADED,
                teachersById: null,
                teachersByIdError: null,
                addScheduleError: null,
                editScheduleError: null,
                deleteScheduleError: null,
                changeStatusScheduleError: null,
                allTeacherImagesListLoadingStatus:LoadingStatus.NOT_LOADED,
                allTeacherImagesListError:null,
                allTeacherImagesList:[],
                addMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                addMultipleImageError:null,
                deleteMultipleImageLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteMultipleImageError:null,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All Schedule action
            .addCase(allScheduleAction.pending, (state, action) => {
                state.allScheduleLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allScheduleAction.fulfilled, (state, action) => {
                state.allScheduleLoadingStatus = LoadingStatus.LOADED;
                state.allSchedules = action.payload?.data ?? [];
            })
            .addCase(allScheduleAction.rejected, (state, action) => {
                state.allScheduleLoadingStatus = LoadingStatus.FAILED;
                state.allScheduleError = action.payload || action.error.message;
            })
            .addCase(teachersByIdAction.pending, (state, action) => {
                state.teachersByIdError = null;
                state.teachersByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(teachersByIdAction.fulfilled, (state, action) => {
                state.teachersByIdLoadingStatus = LoadingStatus.LOADED;
                state.teachersById = action.payload?.data;
            })
            .addCase(teachersByIdAction.rejected, (state, action) => {
                state.teachersByIdLoadingStatus = LoadingStatus.FAILED;
                state.teachersByIdError = action.payload || action.error.message;
            })

            // Add Schedule action
            .addCase(addScheduleAction.pending, (state, action) => {
                state.addScheduleLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addScheduleAction.fulfilled, (state, action) => {
                state.addScheduleLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Schedule added successfully.');
            })
            .addCase(addScheduleAction.rejected, (state, action) => {
                state.addScheduleLoadingStatus = LoadingStatus.FAILED;
                state.addScheduleError = action.payload || action.error.message;
            })
            // Edit Schedule action
            .addCase(editScheduleAction.pending, (state, action) => {
                state.editScheduleLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editScheduleAction.fulfilled, (state, action) => {
                state.editScheduleLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Schedule updated successfully.');
            })
            .addCase(editScheduleAction.rejected, (state, action) => {
                state.editScheduleLoadingStatus = LoadingStatus.FAILED;
                state.editScheduleError = action.payload || action.error.message;
            })
            // Delete Schedule action
            .addCase(deleteScheduleAction.pending, (state, action) => {
                state.deleteScheduleLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteScheduleAction.fulfilled, (state, action) => {
                state.deleteScheduleLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Schedule deleted successfully.');
            })
            .addCase(deleteScheduleAction.rejected, (state, action) => {
                state.deleteScheduleLoadingStatus = LoadingStatus.FAILED;
                state.deleteScheduleError = action.payload || action.error.message;
            })
            // Change Status Schedule action
            .addCase(changeStatusScheduleAction.pending, (state, action) => {
                state.changeStatusScheduleLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusScheduleAction.fulfilled, (state, action) => {
                state.changeStatusScheduleLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Schedule status updated successfully.');
            })
            .addCase(changeStatusScheduleAction.rejected, (state, action) => {
                state.changeStatusScheduleLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusScheduleError = action.payload || action.error.message;
            })
             // All courses action
             .addCase(getPopularCourses.pending, (state, action) => {
                state.allPopularCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getPopularCourses.fulfilled, (state, action) => {
                state.allPopularCoursesLoadingStatus = LoadingStatus.LOADED;
                state.allPopularCourses = action.payload?.data ?? [];
            })
            .addCase(getPopularCourses.rejected, (state, action) => {
                state.allPopularCoursesLoadingStatus = LoadingStatus.FAILED;
                state.allPopularCoursesError = action.payload || action.error.message;
            })

             // all Teacher Multiple Images action
             .addCase(getTeacherImagesByIdAction.pending, (state, action) => {
                state.allTeacherImagesListLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(getTeacherImagesByIdAction.fulfilled, (state, action) => {
                state.allTeacherImagesListLoadingStatus = LoadingStatus.LOADED;
                state.allTeacherImagesList = action.payload?.data;
            })
            .addCase(getTeacherImagesByIdAction.rejected, (state, action) => {
                state.allTeacherImagesListLoadingStatus = LoadingStatus.FAILED;
                state.allTeacherImagesListError = action.payload || action.error.message;
                state.allTeacherImagesList = [];
            })
            // Add Multiple Images action
            .addCase(addMultipleImageAction.pending, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addMultipleImageAction.fulfilled, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teacher image added successfully.');
            })
            .addCase(addMultipleImageAction.rejected, (state, action) => {
                state.addMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.addMultipleImageError = action.payload || action.error.message;
            })
            // delete Teacher Multiple Image action 
            .addCase(deleteMultipleImageAction.pending, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteMultipleImageAction.fulfilled, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Teacher image deleted successfully.');
            })
            .addCase(deleteMultipleImageAction.rejected, (state, action) => {
                state.deleteMultipleImageLoadingStatus = LoadingStatus.FAILED;
                state.deleteMultipleImageError = action.payload || action.error.message;
            })
    }
});

/*
 * Export reducer for store configuration.
 */
export const teacherScheduleReducer = teacherScheduleSlice.reducer;

export const teacherScheduleActions = teacherScheduleSlice.actions;
