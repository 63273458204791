// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { connect } from 'react-redux';
import { activeItemAction, staticactions } from 'redux/reducers/static.slice';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = (props) => {
    const navGroups = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} {...props} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

const mapStateToProps = (state) => {
    return { staticReducer: state.staticReducer };
};

const mapDispatchToProps = (dispatch) => ({
    activeItem: (params) => dispatch(staticactions.activeItemAction(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
