/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'liveCourse';

const initialState = entityAdapter.getInitialState({
    allLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    liveCoursesByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    editLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
    allLiveCoursess: [],
    liveCoursesById: null,
    allLiveCoursesError: null,
    addLiveCoursesError: null,
    editLiveCoursesError: null,
    deleteLiveCoursesError: null,
    changeStatusLiveCoursesError: null,
    liveCoursesByIdError: null,

    allStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    allStaticContent: [],
    allStaticContentError: null,

    addStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    addStaticContentError: null,

    staticContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    staticContentByIdError: null,
    staticContentById:null,
    editStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    editStaticContentError: null,
    deleteStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteStaticContentError:null,

    courseCompriseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    courseCompriseByIdError: null,
    courseCompriseById:null,

    eligibilityByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    eligibilityByIdError: null,
    eligibilityById:null,


});

/**
 * All LiveCourses Action
 */

export const allLiveCoursesAction = createAsyncThunk(`${SLICE_NAME}/allLiveCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-courses',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add LiveCourses Action
 */

export const addLiveCoursesAction = createAsyncThunk(`${SLICE_NAME}/addLiveCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  LiveCourses by ID Action
 */

export const liveCoursesByIdAction = createAsyncThunk(`${SLICE_NAME}/liveCoursesByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params,"Params");
        const result = await axiosRequest({
            url: '/live-course-view/'+ params.id,
            method: 'GET',
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit LiveCourses Action
 */

export const editLiveCoursesAction = createAsyncThunk(`${SLICE_NAME}/editLiveCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete LiveCourses Action
 */

export const deleteLiveCoursesAction = createAsyncThunk(`${SLICE_NAME}/deleteLiveCoursesAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status LiveCourses Action
 */

export const changeStatusLiveCoursesAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusLiveCoursesAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/live-course-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * All Recorded Static Content (What you will learn) Action
 */

 export const allStaticContentAction = createAsyncThunk(`${SLICE_NAME}/allStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-static-content/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add StaticContent Action
 */

 export const addStaticContentAction = createAsyncThunk(`${SLICE_NAME}/addStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-static-content-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  StaticContent by ID Action
 */

 export const staticContentByIdAction = createAsyncThunk(`${SLICE_NAME}/staticContentByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-static-content-view/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

export const editStaticContentAction = createAsyncThunk(`${SLICE_NAME}/editStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-static-content-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete StaticContent Action
 */

 export const deleteStaticContentAction = createAsyncThunk(`${SLICE_NAME}/deleteStaticContentAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-static-content-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});


/**
 * All Course Comprises Action
 */

 export const allCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/allCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-comprise/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add Course Comprise Action
 */

 export const addCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/addCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-comprise-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  CourseComprise by ID Action
 */

 export const courseCompriseByIdAction = createAsyncThunk(`${SLICE_NAME}/courseCompriseByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-comprise-view/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

export const editCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/editCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-comprise-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete CourseComprise Action
 */

 export const deleteCourseCompriseAction = createAsyncThunk(`${SLICE_NAME}/deleteCourseCompriseAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-comprise-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * All Course Eligibility Action
 */

 export const allEligibilityAction = createAsyncThunk(`${SLICE_NAME}/allEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-eligibility/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Add Course Comprise Action
 */

 export const addEligibilityAction = createAsyncThunk(`${SLICE_NAME}/addEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-eligibility-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Eligibility by ID Action
 */

 export const eligibilityByIdAction = createAsyncThunk(`${SLICE_NAME}/eligibilityByIdAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-eligibility-view/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

export const editEligibilityAction = createAsyncThunk(`${SLICE_NAME}/editEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-eligibility-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Delete Eligibility Action
 */

 export const deleteEligibilityAction = createAsyncThunk(`${SLICE_NAME}/deleteEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-eligibility-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Slice for all reducres
 */
const liveCoursesSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetLiveCoursesState: (state, action) => {
            return {
                ...state,
                liveCoursesByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                editLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusLiveCoursesLoadingStatus: LoadingStatus.NOT_LOADED,
                liveCoursesById: null,
                liveCoursesByIdError: null,
                addLiveCoursesError: null,
                editLiveCoursesError: null,
                deleteLiveCoursesError: null,
                changeStatusLiveCoursesError: null,

                staticContentByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                staticContentByIdError: null,
                staticContentById:null,
                editStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                editStaticContentError: null,
                deleteStaticContentLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteStaticContentError:null,

                courseCompriseByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                courseCompriseByIdError: null,
                courseCompriseById:null,
                editCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
                editCourseCompriseError: null,
                deleteCourseCompriseLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCourseCompriseError:null,

                eligibilityByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                eligibilityByIdError: null,
                eligibilityById:null,
                editEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
                editEligibilityError: null,
                deleteEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteEligibilityError:null,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // All LiveCourses action
            .addCase(allLiveCoursesAction.pending, (state, action) => {
                state.allLiveCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allLiveCoursesAction.fulfilled, (state, action) => {
                state.allLiveCoursesLoadingStatus = LoadingStatus.LOADED;
                state.allLiveCoursess = action.payload?.data ?? [];
            })
            .addCase(allLiveCoursesAction.rejected, (state, action) => {
                state.allLiveCoursesLoadingStatus = LoadingStatus.FAILED;
                state.allLiveCoursesError = action.payload || action.error.message;
            })
            .addCase(liveCoursesByIdAction.pending, (state, action) => {
                state.liveCoursesByIdError = null;
                state.liveCoursesByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(liveCoursesByIdAction.fulfilled, (state, action) => {
                state.liveCoursesByIdLoadingStatus = LoadingStatus.LOADED;
                state.liveCoursesById = action.payload?.data;
            })
            .addCase(liveCoursesByIdAction.rejected, (state, action) => {
                state.liveCoursesByIdLoadingStatus = LoadingStatus.FAILED;
                state.liveCoursesByIdError = action.payload || action.error.message;
            })

            // Add LiveCourses action
            .addCase(addLiveCoursesAction.pending, (state, action) => {
                state.addLiveCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addLiveCoursesAction.fulfilled, (state, action) => {
                state.addLiveCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('LiveCourses added successfully.');
            })
            .addCase(addLiveCoursesAction.rejected, (state, action) => {
                state.addLiveCoursesLoadingStatus = LoadingStatus.FAILED;
                state.addLiveCoursesError = action.payload || action.error.message;
            })
            // Edit LiveCourses action
            .addCase(editLiveCoursesAction.pending, (state, action) => {
                state.editLiveCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editLiveCoursesAction.fulfilled, (state, action) => {
                state.editLiveCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('LiveCourses updated successfully.');
            })
            .addCase(editLiveCoursesAction.rejected, (state, action) => {
                state.editLiveCoursesLoadingStatus = LoadingStatus.FAILED;
                state.editLiveCoursesError = action.payload || action.error.message;
            })
            // Delete LiveCourses action
            .addCase(deleteLiveCoursesAction.pending, (state, action) => {
                state.deleteLiveCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteLiveCoursesAction.fulfilled, (state, action) => {
                state.deleteLiveCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('LiveCourses deleted successfully.');
            })
            .addCase(deleteLiveCoursesAction.rejected, (state, action) => {
                state.deleteLiveCoursesLoadingStatus = LoadingStatus.FAILED;
                state.deleteLiveCoursesError = action.payload || action.error.message;
            })
            // Change Status LiveCourses action
            .addCase(changeStatusLiveCoursesAction.pending, (state, action) => {
                state.changeStatusLiveCoursesLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusLiveCoursesAction.fulfilled, (state, action) => {
                state.changeStatusLiveCoursesLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('LiveCourses status updated successfully.');
            })
            .addCase(changeStatusLiveCoursesAction.rejected, (state, action) => {
                state.changeStatusLiveCoursesLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusLiveCoursesError = action.payload || action.error.message;
            })
             // All Recorded Static Content action
             .addCase(allStaticContentAction.pending, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allStaticContentAction.fulfilled, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.LOADED;
                state.allStaticContent = action.payload?.data ?? [];
            })
            .addCase(allStaticContentAction.rejected, (state, action) => {
                state.allStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.allStaticContentError = action.payload || action.error.message;
            })
            // Add StaticContent action
            .addCase(addStaticContentAction.pending, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addStaticContentAction.fulfilled, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content added successfully.');
            })
            .addCase(addStaticContentAction.rejected, (state, action) => {
                state.addStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.addStaticContentError = action.payload || action.error.message;
            })
            .addCase(staticContentByIdAction.pending, (state, action) => {
                state.staticContentByIdError = null;
                state.staticContentByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(staticContentByIdAction.fulfilled, (state, action) => {
                state.staticContentByIdLoadingStatus = LoadingStatus.LOADED;
                state.staticContentById = action.payload?.data;
            })
            .addCase(staticContentByIdAction.rejected, (state, action) => {
                state.staticContentByIdLoadingStatus = LoadingStatus.FAILED;
                state.staticContentByIdError = action.payload || action.error.message;
            })
             // Edit StaticContent action
             .addCase(editStaticContentAction.pending, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editStaticContentAction.fulfilled, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content updated successfully.');
            })
            .addCase(editStaticContentAction.rejected, (state, action) => {
                state.editStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.editStaticContentError = action.payload || action.error.message;
            })
             // Delete StaticContent action
             .addCase(deleteStaticContentAction.pending, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteStaticContentAction.fulfilled, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content deleted successfully.');
            })
            .addCase(deleteStaticContentAction.rejected, (state, action) => {
                state.deleteStaticContentLoadingStatus = LoadingStatus.FAILED;
                state.deleteStaticContentError = action.payload || action.error.message;
            })
            // All Course Comprise Content action
            .addCase(allCourseCompriseAction.pending, (state, action) => {
                state.allCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCourseCompriseAction.fulfilled, (state, action) => {
                state.allCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                state.allCourseComprise = action.payload?.data ?? [];
            })
            .addCase(allCourseCompriseAction.rejected, (state, action) => {
                state.allCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.allCourseCompriseError = action.payload || action.error.message;
            })
            // Add CourseComprise action
            .addCase(addCourseCompriseAction.pending, (state, action) => {
                state.addCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCourseCompriseAction.fulfilled, (state, action) => {
                state.addCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content added successfully.');
            })
            .addCase(addCourseCompriseAction.rejected, (state, action) => {
                state.addCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.addCourseCompriseError = action.payload || action.error.message;
            })
            .addCase(courseCompriseByIdAction.pending, (state, action) => {
                state.courseCompriseByIdError = null;
                state.courseCompriseByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(courseCompriseByIdAction.fulfilled, (state, action) => {
                state.courseCompriseByIdLoadingStatus = LoadingStatus.LOADED;
                state.courseCompriseById = action.payload?.data;
            })
            .addCase(courseCompriseByIdAction.rejected, (state, action) => {
                state.courseCompriseByIdLoadingStatus = LoadingStatus.FAILED;
                state.courseCompriseByIdError = action.payload || action.error.message;
            })
             // Edit CourseComprise action
             .addCase(editCourseCompriseAction.pending, (state, action) => {
                state.editCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editCourseCompriseAction.fulfilled, (state, action) => {
                state.editCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content updated successfully.');
            })
            .addCase(editCourseCompriseAction.rejected, (state, action) => {
                state.editCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.editCourseCompriseError = action.payload || action.error.message;
            })
             // Delete CourseComprise action
             .addCase(deleteCourseCompriseAction.pending, (state, action) => {
                state.deleteCourseCompriseLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCourseCompriseAction.fulfilled, (state, action) => {
                state.deleteCourseCompriseLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content deleted successfully.');
            })
            .addCase(deleteCourseCompriseAction.rejected, (state, action) => {
                state.deleteCourseCompriseLoadingStatus = LoadingStatus.FAILED;
                state.deleteCourseCompriseError = action.payload || action.error.message;
            })

            // All Course Eligibility Content action
            .addCase(allEligibilityAction.pending, (state, action) => {
                state.allEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allEligibilityAction.fulfilled, (state, action) => {
                state.allEligibilityLoadingStatus = LoadingStatus.LOADED;
                state.allEligibility = action.payload?.data ?? [];
            })
            .addCase(allEligibilityAction.rejected, (state, action) => {
                state.allEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.allEligibilityError = action.payload || action.error.message;
            })
            // Add Eligibility action
            .addCase(addEligibilityAction.pending, (state, action) => {
                state.addEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addEligibilityAction.fulfilled, (state, action) => {
                state.addEligibilityLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content added successfully.');
            })
            .addCase(addEligibilityAction.rejected, (state, action) => {
                state.addEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.addEligibilityError = action.payload || action.error.message;
            })
            .addCase(eligibilityByIdAction.pending, (state, action) => {
                state.eligibilityByIdError = null;
                state.eligibilityByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(eligibilityByIdAction.fulfilled, (state, action) => {
                state.eligibilityByIdLoadingStatus = LoadingStatus.LOADED;
                state.eligibilityById = action.payload?.data;
            })
            .addCase(eligibilityByIdAction.rejected, (state, action) => {
                state.eligibilityByIdLoadingStatus = LoadingStatus.FAILED;
                state.eligibilityByIdError = action.payload || action.error.message;
            })
             // Edit Eligibility action
             .addCase(editEligibilityAction.pending, (state, action) => {
                state.editEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editEligibilityAction.fulfilled, (state, action) => {
                state.editEligibilityLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content updated successfully.');
            })
            .addCase(editEligibilityAction.rejected, (state, action) => {
                state.editEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.editEligibilityError = action.payload || action.error.message;
            })
             // Delete Eligibility action
             .addCase(deleteEligibilityAction.pending, (state, action) => {
                state.deleteEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteEligibilityAction.fulfilled, (state, action) => {
                state.deleteEligibilityLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content deleted successfully.');
            })
            .addCase(deleteEligibilityAction.rejected, (state, action) => {
                state.deleteEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.deleteEligibilityError = action.payload || action.error.message;
            })


    }
});

/*
 * Export reducer for store configuration.
 */
export const liveCoursesReducer = liveCoursesSlice.reducer;

export const liveCoursesActions = liveCoursesSlice.actions;
