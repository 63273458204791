/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'eligibility';

const initialState = entityAdapter.getInitialState({
    allEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
    eligibilityByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
    editEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
    allEligibilitys: [],
    eligibilityById: null,
    allEligibilityError: null,
    addEligibilityError: null,
    editEligibilityError: null,
    deleteEligibilityError: null,
    changeStatusEligibilityError: null,
    eligibilityByIdError: null
});

/**
 * All Eligibility Action
 */

export const allEligibilityAction = createAsyncThunk(`${SLICE_NAME}/allEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/eligibility',
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add Eligibility Action
 */

export const addEligibilityAction = createAsyncThunk(`${SLICE_NAME}/addEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/eligibility-create',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  Eligibility by ID Action
 */

export const eligibilityByIdAction = createAsyncThunk(`${SLICE_NAME}/eligibilityByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params, 'Params');
        const result = await axiosRequest({
            url: '/eligibility/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit Eligibility Action
 */

export const editEligibilityAction = createAsyncThunk(`${SLICE_NAME}/editEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/eligibility-update',
            method: 'POST',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete Eligibility Action
 */

export const deleteEligibilityAction = createAsyncThunk(`${SLICE_NAME}/deleteEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/eligibility-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status Eligibility Action
 */

export const changeStatusEligibilityAction = createAsyncThunk(`${SLICE_NAME}/changeStatusEligibilityAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/live-course-status',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Slice for all reducres
 */
const eligibilitySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetEligibilityState: (state, action) => {
            return {
                ...state,
                eligibilityByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
                editEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusEligibilityLoadingStatus: LoadingStatus.NOT_LOADED,
                eligibilityById: null,
                eligibilityByIdError: null,
                addEligibilityError: null,
                editEligibilityError: null,
                deleteEligibilityError: null,
                changeStatusEligibilityError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All Eligibility action
            .addCase(allEligibilityAction.pending, (state, action) => {
                state.allEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allEligibilityAction.fulfilled, (state, action) => {
                state.allEligibilityLoadingStatus = LoadingStatus.LOADED;
                state.allEligibilitys = action.payload?.data ?? [];
            })
            .addCase(allEligibilityAction.rejected, (state, action) => {
                state.allEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.allEligibilityError = action.payload || action.error.message;
            })
            .addCase(eligibilityByIdAction.pending, (state, action) => {
                state.eligibilityByIdError = null;
                state.eligibilityByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(eligibilityByIdAction.fulfilled, (state, action) => {
                state.eligibilityByIdLoadingStatus = LoadingStatus.LOADED;
                state.eligibilityById = action.payload?.data;
            })
            .addCase(eligibilityByIdAction.rejected, (state, action) => {
                state.eligibilityByIdLoadingStatus = LoadingStatus.FAILED;
                state.eligibilityByIdError = action.payload || action.error.message;
            })

            // Add Eligibility action
            .addCase(addEligibilityAction.pending, (state, action) => {
                state.addEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addEligibilityAction.fulfilled, (state, action) => {
                state.addEligibilityLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content added successfully.');
            })
            .addCase(addEligibilityAction.rejected, (state, action) => {
                state.addEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.addEligibilityError = action.payload || action.error.message;
            })
            // Edit Eligibility action
            .addCase(editEligibilityAction.pending, (state, action) => {
                state.editEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editEligibilityAction.fulfilled, (state, action) => {
                state.editEligibilityLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content updated successfully.');
            })
            .addCase(editEligibilityAction.rejected, (state, action) => {
                state.editEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.editEligibilityError = action.payload || action.error.message;
            })
            // Delete Eligibility action
            .addCase(deleteEligibilityAction.pending, (state, action) => {
                state.deleteEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteEligibilityAction.fulfilled, (state, action) => {
                state.deleteEligibilityLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content deleted successfully.');
            })
            .addCase(deleteEligibilityAction.rejected, (state, action) => {
                state.deleteEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.deleteEligibilityError = action.payload || action.error.message;
            })
            // Change Status Eligibility action
            .addCase(changeStatusEligibilityAction.pending, (state, action) => {
                state.changeStatusEligibilityLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusEligibilityAction.fulfilled, (state, action) => {
                state.changeStatusEligibilityLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Static Content status updated successfully.');
            })
            .addCase(changeStatusEligibilityAction.rejected, (state, action) => {
                state.changeStatusEligibilityLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusEligibilityError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const eligibilityReducer = eligibilitySlice.reducer;

export const eligibilityActions = eligibilitySlice.actions;
