// ==============================|| THEME CONFIG  ||============================== //

const config = {
    appVersion: '0.0.1',
    defaultPath: '/dashboard',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};

export default config;

console.log('process.env.NODE_ENV', process.env.NODE_ENV);

export const isDev = process.env.NODE_ENV === 'development' ? true : false;
export const drawerWidth = 300;
export const drawerColor = '#FF9F1C';
export const drawerTextColor = '#FFFFFF';
