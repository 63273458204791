/*eslint-disable no-unused-vars*/

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'utils/constant';
import ToastMessage from 'utils/ToastMessage';
import axiosRequest from 'utils/AxiosInterceptor';

const entityAdapter = createEntityAdapter();

const SLICE_NAME = 'courseUploadVideo';

const initialState = entityAdapter.getInitialState({
    allCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    courseUploadVideoByIdLoadingStatus: LoadingStatus.NOT_LOADED,
    addCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    editCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    deleteCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    changeStatusCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
    allCourseUploadVideos: [],
    courseUploadVideoById: null,
    allCourseUploadVideoError: null,
    addCourseUploadVideoError: null,
    editCourseUploadVideoError: null,
    deleteCourseUploadVideoError: null,
    changeStatusCourseUploadVideoError: null,
    courseUploadVideoByIdError: null
});

/**
 * All CourseUploadVideo Action
 */

export const allCourseUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/allCourseUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/get-course-video/' + params.course_id,
            method: 'GET'
            // data: reqBody
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Add CourseUploadVideo Action
 */

export const addCourseUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/addCourseUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-video-upload',
            method: 'post',
            data: params,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 *  CourseUploadVideo by ID Action
 */

export const courseUploadVideoByIdAction = createAsyncThunk(`${SLICE_NAME}/courseUploadVideoByIdAction`, async (params, thunkAPI) => {
    try {
        console.log(params, 'Params');
        const result = await axiosRequest({
            url: '/course-video/' + params.id,
            method: 'GET'
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Edit CourseUploadVideo Action
 */

export const editCourseUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/editCourseUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-video-update',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});
/**
 * Delete CourseUploadVideo Action
 */

export const deleteCourseUploadVideoAction = createAsyncThunk(`${SLICE_NAME}/deleteCourseUploadVideoAction`, async (params, thunkAPI) => {
    try {
        const result = await axiosRequest({
            url: '/course-video-delete',
            method: 'POST',
            data: params
        });
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
    }
});

/**
 * Change Status CourseUploadVideo Action
 */

export const changeStatusCourseUploadVideoAction = createAsyncThunk(
    `${SLICE_NAME}/changeStatusCourseUploadVideoAction`,
    async (params, thunkAPI) => {
        try {
            const result = await axiosRequest({
                url: '/live-course-status',
                method: 'POST',
                data: params
            });
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response ? error.response?.data : error.data);
        }
    }
);

/**
 * Slice for all reducres
 */
const courseUploadVideoSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetCourseUploadVideoState: (state, action) => {
            return {
                ...state,
                courseUploadVideoByIdLoadingStatus: LoadingStatus.NOT_LOADED,
                addCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                editCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                deleteCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                changeStatusCourseUploadVideoLoadingStatus: LoadingStatus.NOT_LOADED,
                courseUploadVideoById: null,
                courseUploadVideoByIdError: null,
                addCourseUploadVideoError: null,
                editCourseUploadVideoError: null,
                deleteCourseUploadVideoError: null,
                changeStatusCourseUploadVideoError: null
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // All CourseUploadVideo action
            .addCase(allCourseUploadVideoAction.pending, (state, action) => {
                state.allCourseUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(allCourseUploadVideoAction.fulfilled, (state, action) => {
                state.allCourseUploadVideoLoadingStatus = LoadingStatus.LOADED;
                state.allCourseUploadVideos = action.payload?.data ?? [];
            })
            .addCase(allCourseUploadVideoAction.rejected, (state, action) => {
                state.allCourseUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.allCourseUploadVideoError = action.payload || action.error.message;
            })
            .addCase(courseUploadVideoByIdAction.pending, (state, action) => {
                state.courseUploadVideoByIdError = null;
                state.courseUploadVideoByIdLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(courseUploadVideoByIdAction.fulfilled, (state, action) => {
                state.courseUploadVideoByIdLoadingStatus = LoadingStatus.LOADED;
                state.courseUploadVideoById = action.payload?.data;
            })
            .addCase(courseUploadVideoByIdAction.rejected, (state, action) => {
                state.courseUploadVideoByIdLoadingStatus = LoadingStatus.FAILED;
                state.courseUploadVideoByIdError = action.payload || action.error.message;
            })

            // Add CourseUploadVideo action
            .addCase(addCourseUploadVideoAction.pending, (state, action) => {
                state.addCourseUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(addCourseUploadVideoAction.fulfilled, (state, action) => {
                state.addCourseUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video added successfully.');
            })
            .addCase(addCourseUploadVideoAction.rejected, (state, action) => {
                state.addCourseUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.addCourseUploadVideoError = action.payload || action.error.message;
            })
            // Edit CourseUploadVideo action
            .addCase(editCourseUploadVideoAction.pending, (state, action) => {
                state.editCourseUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(editCourseUploadVideoAction.fulfilled, (state, action) => {
                state.editCourseUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video updated successfully.');
            })
            .addCase(editCourseUploadVideoAction.rejected, (state, action) => {
                state.editCourseUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.editCourseUploadVideoError = action.payload || action.error.message;
            })
            // Delete CourseUploadVideo action
            .addCase(deleteCourseUploadVideoAction.pending, (state, action) => {
                state.deleteCourseUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(deleteCourseUploadVideoAction.fulfilled, (state, action) => {
                state.deleteCourseUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video deleted successfully.');
            })
            .addCase(deleteCourseUploadVideoAction.rejected, (state, action) => {
                state.deleteCourseUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.deleteCourseUploadVideoError = action.payload || action.error.message;
            })
            // Change Status CourseUploadVideo action
            .addCase(changeStatusCourseUploadVideoAction.pending, (state, action) => {
                state.changeStatusCourseUploadVideoLoadingStatus = LoadingStatus.LOADING;
            })
            .addCase(changeStatusCourseUploadVideoAction.fulfilled, (state, action) => {
                state.changeStatusCourseUploadVideoLoadingStatus = LoadingStatus.LOADED;
                ToastMessage.success('Upload Video status updated successfully.');
            })
            .addCase(changeStatusCourseUploadVideoAction.rejected, (state, action) => {
                state.changeStatusCourseUploadVideoLoadingStatus = LoadingStatus.FAILED;
                state.changeStatusCourseUploadVideoError = action.payload || action.error.message;
            });
    }
});

/*
 * Export reducer for store configuration.
 */
export const courseUploadVideoReducer = courseUploadVideoSlice.reducer;

export const courseUploadVideoActions = courseUploadVideoSlice.actions;
