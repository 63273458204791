import { Box, Button, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ecommerceActions, showProductImageByIdAction } from 'redux/reducers/ecommerce.slice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AnimateButton from 'components/@extended/AnimateButton';
import { InsertPhotoOutlined } from '@mui/icons-material';
import { LoadingStatus } from 'utils/constant';
import MainCard from 'components/MainCard';
import Paper from '@mui/material/Paper';
// third party
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';

// Initial Values for login form

export const ViewImage = (props) => {
    const { ecommerceReducer, getProductImageByID } = props;

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getProductImageByID({ id: params?.id });
    }, []);

    if (ecommerceReducer.productImageByIdLoadingStatus === LoadingStatus.LOADING) {
        return <LinearProgress />;
    }
    return (
        <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between" pr={5}>
                <Grid item>
                    <Typography variant="h5">View Image Size Details</Typography>
                </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
                <Box sx={{ py: 5, px: 2 }}>
                    <Grid container>
                        <Grid container mb={2} spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Stack mb={2} spacing={1}>
                                    <Box
                                        component="div"
                                        sx={{
                                            position: 'relative', // Ensures that the image stays within the context of its parent
                                            display: 'flex', // Uses Flexbox to manage layout
                                            justifyContent: 'center', // Centers the image horizontally
                                            alignItems: 'center', // Centers the image vertically if needed
                                            overflow: 'hidden', // Ensures that the image doesn't overflow its container
                                            width: '100%', // Makes the container responsive
                                            maxWidth: '350px', // Limits the max width
                                            height: 'auto', // Maintains the aspect ratio
                                            border: '1px solid grey'
                                        }}
                                    >
                                        {ecommerceReducer.productImageById?.image ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    width: '100%', // Makes the image responsive
                                                    height: 'auto', // Maintains the aspect ratio
                                                    maxWidth: { xs: '100%', md: '250px' }, // Ensures responsiveness on different breakpoints
                                                    maxHeight: { xs: '100%', md: '167px' }, // Ensures responsiveness on different breakpoints
                                                    objectFit: 'contain' // Ensures the image scales within its container
                                                }}
                                                alt="ImagePreview"
                                                src={ecommerceReducer.productImageById?.image}
                                            />
                                        ) : (
                                            <InsertPhotoOutlined
                                                sx={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    maxWidth: { xs: '100%', md: '250px' }, // Ensures responsiveness on different breakpoints
                                                    maxHeight: { xs: '100%', md: '167px' }, // Ensures responsiveness on different breakpoints
                                                    objectFit: 'contain', // Centers the placeholder icon
                                                    border: '1px solid grey'
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Stack mb={2} spacing={1}>
                                    <Box
                                        sx={{
                                            fontWeight: 'bold',
                                            backgroundColor: '#FF9F1C70',
                                            textAlign: 'center',
                                            textTransform: 'uppercase',
                                            fontSize: 16
                                        }}
                                    >
                                        Image Details
                                    </Box>
                                    <Typography variant="h5" color="#000">
                                        Color :{' '}
                                        <Typography
                                            variant="span"
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 'normal',
                                                color: ecommerceReducer.productImageById?.color ?? '#000'
                                            }}
                                        >
                                            {ecommerceReducer.productImageById?.color ?? '-'}
                                        </Typography>
                                    </Typography>
                                    <Typography variant="h5" color="#000">
                                        Color Name :{' '}
                                        <Typography variant="span" sx={{ fontSize: 16, fontWeight: 'normal' }} color="#000">
                                            {ecommerceReducer.productImageById?.color_name ?? '-'}
                                        </Typography>
                                    </Typography>
                                    <Typography variant="h5" color="#000">
                                        Payable Amount :{' '}
                                        <Typography variant="span" sx={{ fontSize: 16, fontWeight: 'normal' }} color="#000">
                                            {ecommerceReducer.productImageById?.discounted_price ?? '-'}
                                        </Typography>
                                    </Typography>
                                    <Typography variant="h5" color="#000">
                                        Available Quantity :{' '}
                                        <Typography variant="span" sx={{ fontSize: 16, fontWeight: 'normal' }} color="#000">
                                            {ecommerceReducer.productImageById?.available_quantity ?? '-'}
                                        </Typography>
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack mb={2} spacing={1}>
                                    <Box
                                        sx={{
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            textTransform: 'uppercase',
                                            fontSize: 16,
                                            backgroundColor: '#FF9F1C70'
                                        }}
                                    >
                                        Size Information
                                    </Box>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 200 }} aria-label="simple table" size="small">
                                            <TableHead>
                                                <TableRow
                                                    sx={{
                                                        backgroundColor: '#00000040',
                                                        color: '#fff',
                                                        height: 13
                                                    }}
                                                >
                                                    <TableCell>Size</TableCell>
                                                    <TableCell align="right">Quantity</TableCell>
                                                    <TableCell align="right">Price</TableCell>
                                                    <TableCell align="right">Discount</TableCell>
                                                    <TableCell align="right">Discounted Price</TableCell>
                                                    <TableCell align="right">Item Weight</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(ecommerceReducer.productImageById?.product_image_size ?? []).map((row) => (
                                                    <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {row?.size ?? '-'}
                                                        </TableCell>
                                                        <TableCell align="right">{row?.quantity ?? '-'}</TableCell>
                                                        <TableCell align="right">{row?.price ?? '-'}</TableCell>
                                                        <TableCell align="right">{row?.discount ?? '-'}%</TableCell>
                                                        <TableCell align="right">{row?.discounted_price ?? '-'}</TableCell>
                                                        <TableCell align="right">{row?.item_weight ?? '-'}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} ml={-2} mt={1}>
                            <Grid item>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </MainCard>
        </Grid>
    );
};

ViewImage.propTypes = {
    ecommerceReducer: PropTypes.object,
    getProductImageByID: PropTypes.func
};

const mapStateToProps = (state) => {
    return { ecommerceReducer: state.ecommerceReducer };
};

const mapDispatchToProps = (dispatch) => ({
    getProductImageByID: (val) => dispatch(showProductImageByIdAction(val)),
    resetUploadImageState: () => dispatch(ecommerceActions.resetUploadImageState())
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewImage);
