import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { htmlToText } from 'html-to-text';

export const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        // minWidth: '70px',
        position: 'absolute',
        zIndex: 1,
        top: -50,
        left: 30,
        backgroundColor: '#deb887',
        color: 'white'
    }
});

export const CustomWidthTooltip1 = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: '700px',
        // position: 'absolute',
        // zIndex: 1000,
        backgroundColor: '#f3f0f0',
        color: 'black'
    }
});

export const RenderDescription = ({ description }) => {
    const tooltip = htmlToText(description);
    return description ? (
        <CustomWidthTooltip1 title={tooltip}>
            <Box
                sx={{ maxHeight: 200, overflow: 'auto' }}
                dangerouslySetInnerHTML={{
                    __html: description.length >= 150 ? description.slice(0, 150) + '...' : description ?? 'N/A'
                }}
            />
        </CustomWidthTooltip1>
    ) : (
        'N.A.'
    );
};
