import React, { useEffect } from 'react';

function useTitle({ siteTitle = 'Shivam Yoga App', title = 'Home' }) {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${title} - ${siteTitle}`;
    }, [title]);
}

export { useTitle };
